const {CREATURE_BADGER} = require ('./../../../creatureIdList')
const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_DEX} = require ('./../../../paramList')
const {PC_RACE_GNOME} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_GNOME_FOREST} = require('./../../../pcSubraceIdList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require ('./../../../sourceList')
const {SPELL_MINOR_ILLUSION} = require ('./../../../spellIdList')

module.exports = {
  id: PC_SUBRACE_GNOME_FOREST,
  parentRaceId: PC_RACE_GNOME,
  name: {
    singular: {
      [GENDER_MALE]: 'лесной гном',
      [GENDER_FEMALE]: 'лесная гномка',
    },
    dual: {
      [GENDER_MALE]: 'лесных гнома',
      [GENDER_FEMALE]: 'лесные гномки',
    },
    plural: 'лесные гномы',
  },
  nameShort: {
    singular: {
      [GENDER_MALE]: 'лесной',
      [GENDER_FEMALE]: 'лесная',
    },
    dual: {
      [GENDER_MALE]: 'лесных',
      [GENDER_FEMALE]: 'лесные',
    },
    plural: 'лесные',
  },
  description: [
    {
      header: `Лесные гномы`,
      text: `Лесные гномы обладают природными способностями к иллюзии, и унаследовали проворство и скрытность. В мирах D&D лесные гномы встречаются редко, и являются скрытным народом. Они собираются в спрятанные в глубинах лесов общины, и используют иллюзию и обман, чтобы укрыться от опасности или скрыть свой побег в случае обнаружения. Лесные гномы обычно дружелюбны с другими добрыми лесными народами, и считают эльфов и добрых фей своими главными союзниками. Эти гномы также дружат с мелкими лесными зверушками, которые предупреждают их об опасности.`,
      source: {
        id: SOURCE_PHB,
        page: 38,
      },
    },
    {
      header: `Лесные гномы`,
      text: `Лесные гномы ведут простую уединённую жизнь в своих поселениях на склонах холмов в глубине леса. Их сосед может всю свою жизнь прожить буквально в нескольких милях от поселения гномов и так никогда и не узнать об этом. Анонимность и скрытность помогают их общинам обеспечить защиту, мир и выживание.

Если их обнаружили и хорошо к ним относятся, лесные гномы становятся отличными соседями, но, как правило, они стараются избегать контактов даже с теми цивилизациями, которые кажутся дружественными.

Лесные гномы используют свою близость с мелкими животными и сноровку в создании иллюзий, чтобы оставаться незаметными. При необходимости, община лесных гномов защищает себя, используя все доступные ей ресурсы. Однако многие населенные пункты, если их обнаружили, просто исчезают, чтобы заново возникнуть в каком-нибудь неизведанном уголке леса.

Те редкие лесные гномы, которые оставляют свой народ, чтобы стать авантюристами, часто используют свою близость к природе и магические способности, чтобы стать проводниками, разведчиками или мистиками. Жизнь вблизи природы также позволяет лесным гномам становиться друидами, что служат различным лесным духам и божествам.

В Книге Игрока лесные гномы Фаэруна имеют расовые черты лесных гномов.`,
      source: {
        id: SOURCE_SCAG,
        page: 115,
      },
    },
    {
      header: `Особенности лесных гномов`,
      text: `
# Увеличение характеристик

Ловкость увеличивается на 1.

# Природная иллюзия

Вы знаете заговор [Малая иллюзия](SPELL:${SPELL_MINOR_ILLUSION}). Интеллект — его заклинательная характеристика.

# Общение с маленькими зверями

С помощью звуков и жестов Вы можете передавать простые понятия Маленьким или ещё меньшим зверям. Лесные гномы любят животных и часто держат белок, [барсуков](CREATURE:${CREATURE_BADGER}), кроликов, кротов, дятлов и других животных в качестве питомцев.`,
      source: {
        id: SOURCE_PHB,
        page: 38,
      },
    },
  ],
  paramBonuses: {
    [PARAM_DEX]: 1,
  },
  source: {
    id: SOURCE_PHB,
    page: 38,
  },
}

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_TURAMI} = require('./../../../pcSubraceIdList')
const {SOURCE_PHB} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_TURAMI,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: 'тёрами',
      [GENDER_FEMALE]: 'тёрами',
    },
    dual: {
      [GENDER_MALE]: 'тёрами',
      [GENDER_FEMALE]: 'тёрами',
    },
    plural: 'тёрами',
  },
  nameEn: 'Turami',
  description: `Рожденные на южных берегах Внутреннего моря, тёрами, как правило, высокие и мускулистые люди с тёмно-красной кожей, чёрными волнистыми волосами и чёрными глазами.

# Имена тёрами

## Мужские

Антон, Диеро, Маркон, Пьерон, Римардо, Ромеро, Салазар, Умберо;

## Женские

Балама, Вонда, Джалана, Дона, Куара, Луиза, Марта, Селизе, Фаила

## Фамилии

Агосто, Асторио, Домине, Калабра, Маривальди, Писакар, Рамондо, Фалоне`,
  source: {
    id: SOURCE_PHB,
    page: 32,
  },
}

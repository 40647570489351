const {
  SOURCE_ERRATA_VGTM,
  SOURCE_VGTM,
} = require ('./../../sourceList')
const {SIZE_SMALL} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_KOBOLD} = require ('./../../pcRaceIdList')
const {SETTING_FORGOTTEN_REALMS} = require ('./../../settingList')

const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require ('./../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
} = require ('./../../paramList')
const {monsterAdventurers} = require ('./../../textCommonParts')

module.exports = {
  id: PC_RACE_KOBOLD,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_KOBOLD].name,
  nameEn: creatureTypeCollection[PC_RACE_KOBOLD].nameEn,
  note: creatureTypeCollection[PC_RACE_KOBOLD].note,
  description: [
    ...creatureTypeCollection[PC_RACE_KOBOLD].description,
    ...monsterAdventurers,
    {
      header: 'Особенности кобольдов',
      text: `Ваш персонаж-кобольд обладает следующими особенностями.

# Увеличение характеристик

Ловкость увеличивается на 2.

# Возраст

Кобольды достигают зрелости к 6 годам, и могут дожить до 120 лет, но редко это делают.

# Мировоззрение

Кобольды по своему существу эгоистичны, что делает их склонными ко злу, но их опора на силу их группы делает их склонными к принципиальному мировоззрению.

# Размер

Рост кобольдов между 2 и 3 футами, а вес от 25 до 35 фунтов. Ваш размер — Маленький.

# Скорость

Ваша базовая скорость ходьбы составляет 30 футов.

# Тёмное зрение

На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Склонись, съёжься и умоляй

Действием в свой ход, Вы можете жалобно съёжиться и взмолить о пощаде, чтобы отвлечь соседних противников.

До конца Вашего следующего хода, Ваши союзники получают преимущество на броски атаки против врагов, находящихся в пределах 10 футов от Вас, которые могут Вас видеть. Использовав это свойство, Вы не можете использовать его снова, пока не завершите короткий или длинный отдых.

# Тактика стаи

Вы имеете преимущество при бросках атаки против существа, если по крайней мере один из Ваших союзников в пределах 5 футов от этого существа, и союзник не недееспособен.

# Чувствительность к солнечному свету

Вы совершаете с помехой броски атаки и проверки Мудрости (Восприятие), основанные на зрении, если Вы, цель Вашей атаки или изучаемый предмет расположены на прямом солнечном свете.

# Языки

Вы можете говорить, читать и писать на [Всеобщем](LANG:${LANG_COMMON}) и [Драконьем](LANG:${LANG_DRACONIC}) языках.`,
      source: [
        {
          id: SOURCE_VGTM,
          page: 121,
        },
        {
          id: SOURCE_ERRATA_VGTM,
          title: 'Убран штраф к Силе',
          page: 1,
        },
      ],
    },
  ],
  sizeType: SIZE_SMALL,
  size: {
    tall: {
      base: 20,
      coefDice: {
        diceCount: 4,
        diceType: 4,
      },
    },
    weight: {
      base: 20,
      coefDice: {
        diceCount: 0,
        diceType: 0,
        diceBonus: 1,
      },
    },
  },
  paramBonuses: {
    [PARAM_DEX]: 2,
    [PARAM_STR]: -2,
  },
  age: {
    adult: 6,
    max: 120,
  },
  noHairs: true,
  setting: SETTING_FORGOTTEN_REALMS,
  source: {
    id: SOURCE_VGTM,
    page: 121,
  },
}

import genderFieldList from "@/constants/nameGeneration/genderFieldList"
import {
  PC_RACE_AARAKOCRA,
  PC_RACE_TABAXI,
  PC_RACE_HUMAN,
  PC_RACE_ELF,
  PC_RACE_TORTLE,
  PC_RACE_WARFORGED,
} from '@/constants/pcRaceIdList'
import {
  PC_SUBRACE_HUMAN_TUIGAN,
  PC_SUBRACE_HUMAN_ULUTIUN,
} from '@/constants/pcSubraceIdList'

import useSurnameList from "../races/human/useSurnameList"
import schemaList from "../races/tabaxi/constants/schemaList"
import raceList from "./raceList"
import subRaceList from "./subRaceList"
import ageList from "./ageList"

const typeList = [
  {
    type: 'raceId',
    title: 'Раса',
    list: raceList,
  },
  {
    type: 'subraceId',
    title: 'Подраса',
    list: subRaceList,
    show: {
      raceId: PC_RACE_HUMAN,
    },
  },
  {
    type: 'age',
    title: 'Возраст',
    list: ageList,
    show: {
      raceId: PC_RACE_ELF,
    },
  },
  {
    type: 'genderId',
    title: 'Пол',
    list: genderFieldList,
    hide: {
      age: 'child',
      raceId: [
        PC_RACE_AARAKOCRA,
        PC_RACE_WARFORGED,
        PC_RACE_TORTLE,
      ],
    },
  },
  {
    type: 'schema',
    title: 'Схема построения имени',
    list: schemaList,
    show: {
      raceId: PC_RACE_TABAXI,
    },
  },
  {
    type: 'useSurname',
    title: 'Использование фамилии',
    list: useSurnameList,
    show: {
      raceId: PC_RACE_HUMAN,
    },
    hide: {
      subraceId: [
        PC_SUBRACE_HUMAN_TUIGAN,
        PC_SUBRACE_HUMAN_ULUTIUN,
      ],
    },
  },
]

export default typeList

const {SOURCE_ERFTLW} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_KALASHTAR} = require ('./../../pcRaceIdList')
const {SETTING_EBERRON} = require ('./../../settingList')

const {
  PARAM_WIT,
  PARAM_CHA,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_KALASHTAR,
  isReady: false,
  name: creatureTypeCollection[PC_RACE_KALASHTAR].name,
  nameEn: creatureTypeCollection[PC_RACE_KALASHTAR].nameEn,
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 5 * 12 + 4,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
    weight: {
      base: 110,
      coefDice: {
        diceCount: 1,
        diceType: 6,
      },
    },
    source: {
      id: SOURCE_ERFTLW,
      page: 31,
    },
  },
  paramBonuses: {
    [PARAM_WIT]: 2,
    [PARAM_CHA]: 1,
  },
  setting: SETTING_EBERRON,
  source: {
    id: SOURCE_ERFTLW,
    page: 31,
  },
}

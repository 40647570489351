const {PC_RACE_GOBLIN} = require ('./../../pcRaceIdList')
const {SIZE_SMALL} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {
  SETTING_EBERRON,
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
  SETTING_GREYHAWK,
  SETTING_RAVNICA,
} = require ('./../../settingList')
const {
  SOURCE_ERFTLW,
  SOURCE_VGTM,
} = require ('./../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require ('./../../genderList')
const {
  PARAM_DEX,
  PARAM_CON,
} = require ('./../../paramList')
const {monsterAdventurers} = require ('./../../textCommonParts')

module.exports = {
  id: PC_RACE_GOBLIN,
  isReady: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'гоблин',
        genitive: 'гоблина',
        dative: 'гоблину',
        accusative: 'гоблина',
        instrumental: 'гоблином',
        prepositional: 'гоблине',
      },
      [GENDER_FEMALE]: {
        nominative: 'гоблинша',
        genitive: 'гоблинши',
        dative: 'гоблинше',
        accusative: 'гоблиншу',
        instrumental: 'гоблиншой',
        prepositional: 'гоблинше',
      },
    },
    plural: {
      nominative: 'гоблины',
      genitive: 'гоблинов',
      dative: 'гоблинам',
      accusative: 'гоблинов',
      instrumental: 'гоблинами',
      prepositional: 'гоблинах',
    },
  },
  nameEn: 'Goblin',
  description: [
    ...creatureTypeCollection[PC_RACE_GOBLIN].description,
    ...monsterAdventurers,
    {
      header: 'Особенности гоблинов',
      text: `Ваш персонаж-гоблин обладает следующими особенностями.

# Увеличение характеристик

Ловкость увеличивается на 2.

Телосложение увеличивается на 1.

# Возраст

Гоблины достигают зрелости к 8 годам, и живут до 60 лет.

# Мировоззрение

Гоблины обычно нейтрально злые, поскольку они заботятся только о собственных потребностях.

Некоторые гоблины могут склоняться к добру или нейтральности, но это редкость.

# Размер

Рост гоблинов между 3 и 4 футами, а вес от 40 до 80 фунтов. Ваш размер — Маленький.

# Скорость

Ваша базовая скорость ходьбы составляет 30 футов.

# Тёмное зрение

На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение.

В темноте Вы не можете различать цвета, только оттенки серого.

# Разъярённая мелкота

Когда Вы наносите существу урон атакой или заклинанием, и размер существа больше чем Ваш, Вы можете этой атакой или заклинанием нанести существу дополнительный урон, равный Вашему уровню.

Использовав эту способность, Вы не можете использовать её повторно, пока не завершите короткий или длинный отдых.

# Шустрый побег

Вы можете использовать бонусным действием Отход или Засаду на каждом своём ходу.

# Языки

Вы можете говорить, читать и писать на Всеобщем и Гоблинском языках.`,
      columnCount: 2,
      source: {
        id: SOURCE_VGTM,
        page: 121,
      },
    },
  ],
  sizeType: SIZE_SMALL,
  size: {
    tall: {
      base: 41,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
    weight: {
      base: 35,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_DEX]: 2,
    [PARAM_CON]: 1,
  },
  age: {
    adult: 8,
    max: 60,
  },
  setting: [
    SETTING_EBERRON,
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
    SETTING_GREYHAWK,
    SETTING_RAVNICA,
  ],
  source: [
    {
      id: SOURCE_VGTM,
      page: 121,
    },
    {
      id: SOURCE_ERFTLW,
      page: 27,
    },
  ],
}

const {SOURCE_VGTM} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_LIZARDFOLK} = require ('./../../pcRaceIdList')
const {SETTING_FORGOTTEN_REALMS} = require ('./../../settingList')
const {
  GEAR_BLOWGUN_NEEDLES,
  GEAR_CLUB,
  GEAR_DAGGER,
  GEAR_DART,
  GEAR_JAVELIN,
  GEAR_LEATHERWORKER_S_TOOLS,
  GEAR_SHIELD,
} = require ('./../../gearIdList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require('./../../languageIdList')
const {
  PARAM_CON,
  PARAM_WIT,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_LIZARDFOLK,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_LIZARDFOLK].name,
  nameEn: creatureTypeCollection[PC_RACE_LIZARDFOLK].nameEn,
  note: creatureTypeCollection[PC_RACE_LIZARDFOLK].note,
  description: [
    ...creatureTypeCollection[PC_RACE_LIZARDFOLK].description,
    {
      header: 'Особенности людоящеров',
      text: `Ваш персонаж людоящер обладает следующими особенностями.

# Увеличение характеристик

Телосложение увеличивается на 2.

Мудрость увеличивается на 1.

# Возраст

Людоящеры достигают зрелости к 14 годам, и редко живут больше 60 лет.

# Мировоззрение

Большинство людоящеров нейтральны. Они рассматривают мир как место обитания хищников и добычи, где жизнь и смерть — естественные процессы. Они хотят только выжить, и предпочитают не связываться с делами других существ.

# Размер

Людоящеры немного более крупные и более высокие чем люди, и их красочные гребешки заставляют их казаться еще больше. Ваш размер — Средний.

# Скорость

Вы обладаете базовой скоростью ходьбы 30 футов, и скоростью плавания 30 футов

# Укус

Ваша зубастая пасть — естественное оружие, которое Вы можете использовать, чтобы совершить безоружный удар. Если Вы наносите такой удар, Вы причиняете колющий урон, равный 1к6 + Ваш модификатор Силы, вместо дробящего урона, обычного для безоружного удара.

# Умелый ремесленник

Как часть короткого отдыха, Вы можете собрать кости и шкуру убитого зверя, конструкта, дракона, монстра или растения размером от Маленького и больше, чтобы создать один из следующих предметов: [щит](GEAR:${GEAR_SHIELD}), [дубинка](GEAR:${GEAR_CLUB}), [метательное копьё](GEAR:${GEAR_JAVELIN}), или 1к4 [дротиков](GEAR:${GEAR_DART}) или [игл](GEAR:${GEAR_BLOWGUN_NEEDLES}) для духовой трубки. Чтобы использовать эту способность, Вам нужно лезвие, такое как [кинжал](GEAR:${GEAR_DAGGER}), или подходящие инструменты ремесленника, такие как [инструменты кожевника](GEAR:${GEAR_LEATHERWORKER_S_TOOLS}).

# Задерживание дыхания

Вы можете задержать свое дыхание на 15 минут за раз.

# Знания охотника

Вы владеете двумя из следующих навыков на Ваш выбор:

* Восприятие,
* Выживание,
* Природа,
* Скрытность,
* Уход за животными.

# Природная броня

Ваша кожа жёсткая и чешуйчатая. Когда Вы не носите доспех, Ваш КД 13 + Ваш модификатор Ловкости. Вы можете использовать КД своей природной брони, если носимый Вами доспех даёт более низкий КД. При использовании природного доспеха эффект от [щита](GEAR:${GEAR_SHIELD}) применяется как обычно.

# Голодная пасть

В сражении Вы можете впасть в неистовство голодного хищника. Бонусным действием Вы можете совершить специальную атаку укусом. Если атака нанесла урон, то урон наносится как обычно для укуса, а Вы получаете временные хиты (минимум 1) равные Вашему модификатору Телосложения, и Вы не можете использовать эту способность снова, пока не завершите короткий или длинный отдых.

# Языки

Вы можете говорить, читать и писать на [Всеобщем](LANG:${LANG_COMMON}) и [Драконьем](LANG:${LANG_DRACONIC}) языке.`,
      source: {
        id: SOURCE_VGTM,
        page: 113,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 9,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 120,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
  },
  paramBonuses: {
    [PARAM_CON]: 2,
    [PARAM_WIT]: 1,
  },
  age: {
    adult: 14,
    max: 60,
  },
  noHairs: true,
  setting: SETTING_FORGOTTEN_REALMS,
  source: {
    id: SOURCE_VGTM,
    page: 115,
  },
}

import generateRealGender from '@/utils/nameGeneration/generateRealGender'
import Name from '@/constructors/Name'
import { PC_RACE_HUMAN } from '@/constants/pcRaceIdList'

import generateSurnameHumanCommon from './generateSurnameHumanCommon'
import generateFirstNameHumanCommon from './generateFirstNameHumanCommon'

const fullNameDefaultGenerator = ({ firstName, surName: lastName, genderId }) => new Name({
  firstName,
  lastName,
  genderId,
  raceId: PC_RACE_HUMAN,
})

export default (
  {
    nameListFamily,
    nameListFemale,
    nameListMale,
    fullNameGenerator = fullNameDefaultGenerator,
  },
) => ({ useSurname, genderId: genderIdInput }) => {
  const genderId = generateRealGender(genderIdInput)
  const firstName = generateFirstNameHumanCommon(nameListFemale, nameListMale)(genderId)
  const surName = generateSurnameHumanCommon(nameListFamily)(useSurname)

  return fullNameGenerator({ firstName, surName, genderId })
}

import _ from 'lodash'

import upLetter from "@/utils/upLetter"

import namePartList from "./../dict/names_parts"

const COEF_ONE_SYLLABLE = 0.5

export default () => {
  const namePart_1 = _.sample(namePartList)

  if (Math.random() <= COEF_ONE_SYLLABLE) {
    return upLetter(namePart_1)
  }

  const namePart_2 = _.sample(namePartList)

  return upLetter(`${namePart_1}${namePart_2}`)
}

import _ from "lodash"

import nounList, {nounLocationList} from "@/constants/nameGeneration/wordDicts/nounList"

import {SINGULAR_NUMBER_TYPE} from "@/constants/nameGeneration/numberTypeList"

const getRandomNoun = (
  {
    genderId,
    genderIdToUse,
    pickOnlyLocation = false,
    nounToFilter = null,
    numberType = SINGULAR_NUMBER_TYPE,
    isPlural = false,
  }
) => {
  const list = pickOnlyLocation
    ? nounLocationList
    : nounList

  return _.sample(
    list.filter(
      noun => (
        noun !== nounToFilter
        && isPlural
          ? noun.plural
          : (
            noun.genderId === genderId
            || noun.genderId === genderIdToUse
          )
      )
    )
  )[numberType]
}

export default getRandomNoun

const {SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {CREATURE_TYPE_HADOZEE} = require ('./../../creatureTypeIdList')
const {PC_RACE_HADOZEE} = require ('./../../pcRaceIdList')
const {
  SIZE_MEDIUM,
  SIZE_SMALL,
} = require ('./../../sizeList')
const {
  SETTING_DRAGON_LANCE,
  SETTING_GREYHAWK,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')
const customLanguages = require ('./../constants/customLanguages')
const customParamBonuses = require ('./../constants/customParamBonuses')

module.exports = {
  id: PC_RACE_HADOZEE,
  isReady: true,
  name: creatureTypeCollection[CREATURE_TYPE_HADOZEE].name,
  nameEn: creatureTypeCollection[CREATURE_TYPE_HADOZEE].nameEn,
  sizeType: [
    SIZE_MEDIUM,
    SIZE_SMALL,
  ],
  setting: [
    SETTING_DRAGON_LANCE,
    SETTING_GREYHAWK,
    SETTING_FORGOTTEN_REALMS,
  ],
  note: [
    `Существо выглядит как какая-то обезьяна, покрытая коричневой шерстью, с мордой, полной мелких острых зубов. Его запястья и щиколотки соединяют широкие крылоподобные полосы кожи, а на бедре надета простая кожаная обвязка с абордажной саблей.`,
    {
      text: `Пока что мы нанесли на карту новый остров Архипелага Специй, выловили драконьих черепах, посетили Жемчужную Цитадель морских эльфов и не менее семи раз спасались от пиратов. И это мы ещё не закончили. Классно!`,
      author: 'Банш Полуночник, гадози-матрос',
    },
  ],
  description: [
    {
      header: 'Гадози',
      text: `Гадози — люди с обезьяньей внешностью, давно приспособившиеся к жизни на высоких деревьях своего родного мира. Они прирождённые верхолазы, а их ноги так же ловки, как и руки, настолько, что на ногах у них даже есть противопоставленные большие пальцы. С рук и ног гадози свободно свисают кожные складки. Туго натянув эти складки, гадози способны планировать по воздуху, плавно опускаясь.
 
На первых гадози охотились крупные хищники. Выживая в этой враждебной среде, гадози развили у себя инстинктивную потребность в обществе. Сегодня этот же инстинкт заставляет многих гадози заводить дружбу, зная, что безопасность — в числе.`,
      source: {
        id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
        page: 3,
      },
    },
    {
      header: 'Особенности гадози',
      text: `Вы получаете следующие расовые особенности 

# Тип существа

Вы — гуманоид.

# Размер

Ваш размер — Маленький или Средний (выберите, когда выбираете эту расу).

${customParamBonuses}

# Скорость

Ваша скорость ходьбы 30 футов. Вы получаете скорость лазания, равную скорости ходьбы.

# Ловкие ноги

Вы можете бонусным действием совершать действие Использование предмета.

# Слёт

Вы можете растягивать кожные складки и на них планировать вниз, если Вы дееспособны и не носите тяжёлых доспехов. При этом Вы можете совершать следующие воздушные манёвры

* Падая, Вы можете перемещаться на 5 футов по горизонтали за каждый 1 фут спуска.
* Если Вы должны получить урон от падения, то можете реакцией уменьшить этот урон до 0.

${customLanguages}`,
      columnCount: 2,
      source: {
        id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
        page: 3,
      },
    },
  ],
  source: {
    id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
    page: 3,
  },
}

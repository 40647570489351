const {SIZE_MEDIUM} = require ('./../../sizeList')
const {PC_RACE_GENASI} = require ('./../../pcRaceIdList')
const {PARAM_CON} = require ('./../../paramList')
const {
  SOURCE_EE,
  SOURCE_EGTW,
} = require ('./../../sourceList')
const {
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')
const {
  CREATURE_DJINNI,
  CREATURE_EFREETI,
} = require ('./../../creatureIdList')
const {
  LANG_COMMON,
  LANG_PRIMORDIAL,
} = require ('./../../languageIdList')

module.exports = {
  id: PC_RACE_GENASI,
  isReady: true,
  name: 'генази',
  nameAlt: 'Дженази',
  nameEn: 'Genasi',
  sizeType: SIZE_MEDIUM,
  description: [
    {
      header: 'Генази',
      text: `Большинство людей, размышляя о других планах, считают их очень далёкими мирами, но планарное влияние может проявиться в любом месте мира. Иногда оно проявляется в существах, рождённых в результате определённых обстоятельств, и несущих силу других миров в своей крови. Генази — один таких народов, это потомки гениев и смертных.

Стихийные Планы часто неприветливы к уроженцам Материального плана: сокрушительная земля, иссушающее пламя, безграничные небеса и бескрайние моря делают посещение этих мест опасным даже на короткое время. Однако, сильные гении не стоят перед такими проблемами, проникая в мир смертных. Они приспособлены к смешанным стихиям Материального плана и иногда посещают их по собственной воле или будучи призванными магией. Некоторые гении могут принимать смертный облик и путешествовать инкогнито.

Во время этих визитов смертный может привлечь внимание гения, что может перерасти в дружбу и романтические отношения, а иногда и к появлению детей. Этих детей и называют генази: люди, имеющие связь с двумя мирами, и в тоже время не принадлежащие ни к одному из них. Некоторые генази рождены от союза смертного и гения, у других оба родителя могут быть генази, и очень редко те, у кого в родословной были гении, могут унаследовать его стихийную природу, которая дремала в течение нескольких поколений.

Иногда генази могут появиться от воздействий всплеска стихийной силы, через такие явления, как прорыв Внутренних Планов или схождение планов. При таких явлениях стихийная энергия проникает в тела существ, и её может оказаться достаточно, чтобы в качестве потомства от двух смертных появился генази.`,
      source: {
        id: SOURCE_EE,
        page: 7,
      },
    },
    {
      header: 'Наследники силы стихий',
      text: `Генази наследуют что-то от обеих сторон своей двойственной природы. Они похожи на людей, но помимо необычного цвета кожи (красный, зелёный, синий или серый цвет) в них есть нечто необычное. Стихийная кровь, текущая в их венах, может проявлять себя по-разному в каждом генази, и чаще всего как магическая сила.

Силуэтом генази может сойти за человека. Те, у кого земляное или водное происхождение, как правило, тяжелее, в то время как воздушные и огненные, как правило, легче. Внешне генази могут иметь черты своих смертных родителей (заострённые уши от эльфа, коренастое телосложение и густые волосы от дварфа, маленькие руки и ноги от полурослика, чрезвычайно большие глаза от гнома и так далее).

Генази почти никогда не общаются со своими стихийными родителями. Гении редко проявляют интерес к своим смертным потомкам и рассматривают их как нелепую случайность. Многие вообще не испытывают никаких чувств к своим детям-генази.

Некоторые генази живут как изгои, отправленные в ссылку из-за своей необычной внешности и странной магии, другие же могут стать лидерами диких гуманоидов и странных культов в диких землях. Третьи могут занимать влиятельные посты, особенно там, где почитают стихийных существ. Некоторые генази оставляют Материальный план, чтобы найти пристанище на домашнем плане своего родителя-гения.`,
      source: {
        id: SOURCE_EE,
        page: 7,
      },
    },
    {
      header: 'Дикие и уверенные',
      text: `Генази редко испытывают недостаток в уверенности, и считают себя способными справиться с любой проблемой. Эта уверенность может проявляться как изящная уверенность в своих силах у одного генази, и как высокомерие у другого. Такая слепая уверенность в себе может иногда заставить генази пойти на неоправданный риск, а их большие планы часто приводят к большим неприятностям, как для самих генази, так и их спутников.

Слишком частые неудачи могут уменьшить самомнение генази, поэтому они всегда стремятся к идеалу, оттачивая свои таланты и совершенствуясь в своём мастерстве.`,
      source: {
        id: SOURCE_EE,
        page: 8,
      },
    },
    {
      header: 'Земли генази',
      text: `Генази так редки, что даже за всю жизнь они могут не встретить никого из своего вида. У них нет больших городов или империй. Генази редко создают собственные общины, и обычно принимают культуру и общество, в которых они родились. Чем более странен их внешний вид, тем труднее им приходится в жизни. Многие генази растворяются в многолюдных городах, где их внешность не привлекает внимания людей, привыкших к разнообразию народов.

Живущим на границе приходится гораздо труднее. Люди там меньше принимают различия. Холодный приём и подозрительные взгляды это лучшее, на что могут надеяться генази; в более отсталых местах они сталкиваются с гонениями и даже насилием со стороны людей, которые ошибочно принимают их за исчадий. Не желая такой жизни, генази ищут изоляции от мира, обустраивая свои дома в горах или лесах, возле озёр или даже под землёй.

Большая часть воздушных и огненных генази в Королевствах — потомки [джиннов](CREATURE:${CREATURE_DJINNI}) и [ифритов](CREATURE:${CREATURE_EFREETI}), которые когда-то управляли Калимшаном. Когда этих правителей свергли, их детей, затронутых планами, изгнали и рассеяли по миру. На протяжении нескольких тысяч лет родословные этих генази распространялись на другие земли. Воздушные и огненные генази чаще всего встречаются в западных областях Фаэруна, вдоль побережья Калимшана к северу от Побережья Меча, а также в Западном Сердцеземье. Некоторые же остаются на своей древней родине.

Водные и земляные генази, напротив, не имеют никакой общей истории. Они испытывают затруднение, прослеживая свою родословную, и иногда пропускают одно-два поколения. Большая часть генази земли пришла с Севера. Водные генази пришли из прибрежных районов, и большинство из них родом из регионов, окружающих море Павших Звёзд.

Далёкая земля Захара известна большинству жителей Фаэруна только по легендам. Там гении и заклинатели заключали союзы, и некоторые генази могли появиться вследствие таких пактов. Эти генази были источниками великих благ и невзгод той земли.`,
      source: {
        id: SOURCE_EE,
        page: 8,
      },
    },
    {
      header: 'Генази в Атхасе',
      text: `Несмотря на то, что генази могут появиться в любом мире, который содержит один или несколько стихийных планов, Атхас (мир сеттинга Тёмное Солнце) — это мир, где стихийные силы имеют большее влияние, чем на других мирах. Так как генази это существа, тронутые силой стихий, их почитают как провидцев, пророков и избранных. Рождение генази, будь то раб, благородный или член пустынного племени, считается благостным событием. Большинство атхасцев верит, что генази предназначены для величия (или великого позора).`,
      source: {
        id: SOURCE_EE,
        page: 8,
      },
    },
    {
      header: 'Имена генази',
      text: `Генази могут использовать имена народа, среди которого росли. Позднее они могут взять себе имя, делающее акцент на их наследии, к примеру, Пламя, Зола, Волна или Оникс.`,
      source: {
        id: SOURCE_EE,
        page: 8,
      },
    },
    {
      header: 'Предыстория генази',
      text: `У каждой подрасы генази свой собственный темперамент, который мог бы сделать некоторые предыстории более подходящими, чем другие.

# Генази воздуха

Генази воздуха гордятся своим наследием, иногда вплоть до высокомерия. Они могут быть более красноречивы, и стремятся иметь аудиторию. Они редко остаются в одном месте на долгое время, и всегда находятся в поиске чего-то нового, торопятся жить. Воздушные генази, не живущие в городах, предпочитают открытые земли, такие как равнины, пустыни и высокие горы. Наиболее подходящая предыстория для них: артист, благородный и шарлатан.

# Генази земли

Генази земли более замкнуты; их связь с землёй препятствует им чувствовать себя комфортно в городах. Их необычный размер и сила делают их хорошими солдатами, хотя благодаря стойким качествам они могут впечатлить других и стать великими лидерами. Многие генази земли живут в подземельях, где они чувствуют себя комфортно. Когда они выходят из своих пещер, они могут бродить по холмам и горам или могут предъявить права на старые руины. Наиболее подходящая предыстория для них: отшельник, солдат и чужеземец.

# Генази огня

Генази огня часто попадают в неприятности из-за своего вспыльчивого характера. Как и их воздушные собратья, они иногда хвастают своим превосходством над простыми людьми. Но они также стремятся, чтобы другие разделяли их высокое самомнение, поэтому постоянно борются за свою репутацию. Наиболее подходящая предыстория для них: благородный, народный герой и преступник.

# Генази воды

Почти все генази воды имеют опыт работы на борту или около морских судов. Они становятся отличными моряками и рыбаками. Как и генази земли, генази воды предпочитают тихие, уединённые места; широкие берега естественных водоёмов. Они идут туда, куда хотят, и делают то, что хотят, редко чувствуя привязанность к чему-либо. Наиболее подходящая предыстория для них: моряк и отшельник.`,
      source: {
        id: SOURCE_EE,
        page: 9,
      },
    },
    {
      header: 'Особенности генази',
      text: `Ваш персонаж генази обладает общими особенностями с другими генази.

# Увеличение характеристик

Телосложение увеличивается на 2.

# Возраст

Генази взрослеют приблизительно с той же скоростью, как и люди, и достигают зрелости в позднем подростковом возрасте. Они живут несколько дольше людей — 120 лет.

# Мировоззрение

Независимые и самостоятельные генази имеют склонность к нейтральному мировоззрению.

# Размер

Генази столь же разнообразны, как и их смертные родители, но, как правило, имеют людское строение: рост от 5 до 6 футов (от 152 до 182 сантиметров) в высоту. Ваш размер — Средний.

# Скорость

Ваша базовая скорость перемещения — 30 футов.

# Языки

Вы разговариваете, читаете и пишете на [Всеобщем](LANG:${LANG_COMMON}) и [Первичном](LANG:${LANG_PRIMORDIAL}). Первичный — гортанный язык, заполненный резкими слогами и твёрдыми согласными.

# Подраса

В мирах D&D встречается четыре основные подрасы генази: генази воды, генази воздуха, генази земли и генази огня. Выберете одну из этих подрас.`,
      columnCount: 2,
      source: {
        id: SOURCE_EE,
        page: 9,
      },
    },
  ],
  size: {
    tall: {
      base: 4 * 12 + 8,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 110,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
    source: [
      {
        id: SOURCE_EE,
        page: 9,
      },
      {
        id: SOURCE_EGTW,
        page: 172,
      },
    ],
  },
  paramBonuses: {
    [PARAM_CON]: 2,
  },
  age: {
    adult: 20,
    max: 120,
  },
  setting: [
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
  ],
  source: [
    {
      id: SOURCE_EE,
      page: 9,
    },
    {
      id: SOURCE_EGTW,
      page: 171,
    },
  ],
}

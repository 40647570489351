import _ from 'lodash'

import {
    PC_SUBRACE_HUMAN_ARKAIUN,
    PC_SUBRACE_HUMAN_BEDINE,
    PC_SUBRACE_HUMAN_FFOLK,
    PC_SUBRACE_HUMAN_GUR,
    PC_SUBRACE_HUMAN_HALRUAAN,
    PC_SUBRACE_HUMAN_IMASKARI,
    PC_SUBRACE_HUMAN_NAR,
    PC_SUBRACE_HUMAN_SHAARAN,
    PC_SUBRACE_HUMAN_TUIGAN,
    PC_SUBRACE_HUMAN_ULUTIUN,
    PC_SUBRACE_HUMAN_CHONDATAN,
    PC_SUBRACE_HUMAN_CHULT,
    PC_SUBRACE_HUMAN_DAMARAN,
    PC_SUBRACE_HUMAN_ILLUSKAN,
    PC_SUBRACE_HUMAN_CALISHITE,
    PC_SUBRACE_HUMAN_MULAN,
    PC_SUBRACE_HUMAN_RASHEMI,
    PC_SUBRACE_HUMAN_SHOU,
    PC_SUBRACE_HUMAN_TETHYRIAN,
    PC_SUBRACE_HUMAN_TURAMI,
} from '@/constants/pcSubraceIdList'

import generateArkaiun from './subraces/arkaiun/generateArkaiun'
import generateBedine from './subraces/bedine/generateBedine'
import generateFfolk from './subraces/ffolk/generateFfolk'
import generateGur from './subraces/gur/generateGur'
import generateHalruaan from './subraces/halruaan/generateHalruaan'
import generateImaskari from './subraces/imaskari/generateImaskari'
import generateNar from './subraces/nar/generateNar'
import generateShaaran from './subraces/shaaran/generateShaaran'
import generateTuigan from './subraces/tuigan/generateTuigan'
import generateUlutiun from './subraces/ulutiun/generateUlutiun'
import generateChult from './subraces/chult/generateChult'
import generateDamar from './subraces/damar/generateDamar'
import generateIlluskan from './subraces/illuskan/generateIlluskan'
import generateKalishit from './subraces/kalishit/generateKalishit'
import generateMulan from './subraces/mulan/generateMulan'
import generateRashemi from './subraces/rashemi/generateRashemi'
import generateTyorami from './subraces/tyorami/generateTyorami'
import generateChondatan from './subraces/chondatan/generateChondatan'
import generateShu from './subraces/shu/generateShu'

const humanNameGeneratorListWithSurnames = [
    generateArkaiun,
    generateBedine,
    generateChondatan,
    generateChult,
    generateDamar,
    generateFfolk,
    generateGur,
    generateHalruaan,
    generateIlluskan,
    generateImaskari,
    generateKalishit,
    generateMulan,
    generateMulan,
    generateNar,
    generateRashemi,
    generateShaaran,
    generateShu,
    generateTyorami,
]

const humanNameGeneratorListWithoutSurnames = [
    generateTuigan,
    generateUlutiun,
]

const humanNameGeneratorList = [
    ...humanNameGeneratorListWithSurnames,
    ...humanNameGeneratorListWithoutSurnames,
]

const generateNameHuman = (props) => {
    const {useSurname, subraceId} = props

    switch (subraceId) {
        case PC_SUBRACE_HUMAN_ARKAIUN:
            return generateArkaiun(props)

        case PC_SUBRACE_HUMAN_BEDINE:
            return generateBedine(props)

        case PC_SUBRACE_HUMAN_CHULT:
            return generateChult(props)

        case PC_SUBRACE_HUMAN_DAMARAN:
            return generateDamar(props)

        case PC_SUBRACE_HUMAN_FFOLK:
            return generateFfolk(props)

        case PC_SUBRACE_HUMAN_GUR:
            return generateGur(props)

        case PC_SUBRACE_HUMAN_HALRUAAN:
            return generateHalruaan(props)

        case PC_SUBRACE_HUMAN_ILLUSKAN:
            return generateIlluskan(props)

        case PC_SUBRACE_HUMAN_IMASKARI:
            return generateImaskari(props)

        case PC_SUBRACE_HUMAN_CALISHITE:
            return generateKalishit(props)

        case PC_SUBRACE_HUMAN_MULAN:
            return generateMulan(props)

        case PC_SUBRACE_HUMAN_NAR:
            return generateNar(props)

        case PC_SUBRACE_HUMAN_RASHEMI:
            return generateRashemi(props)

        case PC_SUBRACE_HUMAN_SHAARAN:
            return generateShaaran(props)

        case PC_SUBRACE_HUMAN_TUIGAN:
            return generateTuigan(props)

        case PC_SUBRACE_HUMAN_TURAMI:
            return generateTyorami(props)

        case PC_SUBRACE_HUMAN_CHONDATAN:
        case PC_SUBRACE_HUMAN_TETHYRIAN:
            return generateChondatan(props)

        case PC_SUBRACE_HUMAN_SHOU:
            return generateShu(props)

        case PC_SUBRACE_HUMAN_ULUTIUN:
            return generateUlutiun(props)

        default:
        case 'any': {
            return _.sample(
              useSurname
                ? humanNameGeneratorListWithSurnames
                : humanNameGeneratorList
            )(props)
        }
    }
}

export default generateNameHuman

const {SOURCE_ERFTLW} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_SHIFTER} = require ('./../../pcRaceIdList')
const {SETTING_EBERRON} = require ('./../../settingList')

module.exports = {
  id: PC_RACE_SHIFTER,
  isReady: false,
  name: creatureTypeCollection[PC_RACE_SHIFTER].name,
  nameEn: creatureTypeCollection[PC_RACE_SHIFTER].nameEn,
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 6,
      coefDice: {
        diceCount: 2,
        diceType: 8,
      },
    },
    weight: {
      base: 90,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
    source: {
      id: SOURCE_ERFTLW,
      page: 34,
    },
  },
  age: {
    adult: 10,
    max: 70,
  },
  setting: SETTING_EBERRON,
  source: {
    id: SOURCE_ERFTLW,
    page: 34,
  },
}

const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_HOBGOBLIN} = require ('./../../pcRaceIdList')
const {
  SETTING_EBERRON,
  SETTING_FORGOTTEN_REALMS,
  SETTING_GREYHAWK,
} = require ('./../../settingList')
const {
  SOURCE_ERFTLW,
  SOURCE_VGTM,
} = require ('./../../sourceList')
const {
  LANG_COMMON,
  LANG_GOBLIN,
} = require ('./../../languageIdList')

const {
  PARAM_CON,
  PARAM_INT,
} = require ('./../../paramList')
const {monsterAdventurers} = require ('./../../textCommonParts')

module.exports = {
  id: PC_RACE_HOBGOBLIN,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_HOBGOBLIN].name,
  nameEn: creatureTypeCollection[PC_RACE_HOBGOBLIN].nameEn,
  note: creatureTypeCollection[PC_RACE_HOBGOBLIN].note,
  description: [
    ...creatureTypeCollection[PC_RACE_HOBGOBLIN].description,
    ...monsterAdventurers,
    {
      header: 'Особенности хобгоблинов',
      text: `Ваш персонаж-хобгоблин обладает следующими особенностями.

# Увеличение характеристик

Телосложение увеличивается на 2.

Интеллект увеличивается на 1.

# Возраст

Хобгоблины взрослеют с той же скоростью, что и люди, и имеют примерно такую же продолжительность жизни.

# Мировоззрение

Общество хобгоблинов основано на соблюдении жёсткого, сурового кодекса. Как таковые, они склоняются к принципиально-злому мировоззрению.

# Размер

Хобгоблины ростом от 5 до 6 футов и весят от 150 до 200 фунтов. Ваш размер — Средний.

# Скорость

Ваша базовая скорость ходьбы составляет 30 футов.

# Тёмное зрение

На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Боевая подготовка

Вы владеете навыком обращения с двумя воинскими оружиями на Ваш выбор и лёгкими доспехами.

# Сохранить лицо

Хобгоблины стараются не показывать слабость перед своими союзниками, из страха потерять свой статус.

Если Вы провалили бросок атаки, проверку способности или испытание, Вы можете получить бонус к броску, равный числу союзников, видимых Вами в пределах 30 футов (максимум +5).

Воспользовавшись этой способностью, Вы не можете использовать её снова, пока не завершите короткий или длинный отдых.

# Языки

Вы можете говорить, читать и писать на [Всеобщем](LANG:${LANG_COMMON}) и [Гоблинском](LANG:${LANG_GOBLIN}) языках.`,
      columnCount: 2,
      source: {
        id: SOURCE_VGTM,
        page: 122,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 56,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 110,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_CON]: 2,
    [PARAM_INT]: 1,
  },
  setting: [
    SETTING_EBERRON,
    SETTING_FORGOTTEN_REALMS,
    SETTING_GREYHAWK,
  ],
  source: [
    {
      id: SOURCE_VGTM,
      page: 122,
    },
    {
      id: SOURCE_ERFTLW,
      page: 27,
    },
  ],
}

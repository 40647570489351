const customParamBonuses = `
# Увеличение характеристик

Выберите одно

1. Увеличьте одну любую характеристику на +2 и любую другую характеристику на +1.
2. Увеличьте одну любую характеристику на +1, любую другую характеристику на +1 и любую третью характеристику на +1.
`

module.exports = customParamBonuses

const listToCollectionById = require('./../utils/listToCollectionById')

const SETTING_DRAGON_LANCE = 'DRAGON_LANCE'
const SETTING_EBERRON = 'eberron'
const SETTING_EXANDRIA = 'exandria'
const SETTING_FORGOTTEN_REALMS = 'forgotten_realms'
const SETTING_GREYHAWK = 'greyhawk'
const SETTING_RAVNICA = 'ravnica'
const SETTING_STRIXHAVEN = 'strixhaven'
const SETTING_THEROS = 'theros'

const settingList = [
  {
    id: SETTING_DRAGON_LANCE,
    name: 'Сага о Копье',
    nameEn: 'DragonLance',
  },
  {
    id: SETTING_EBERRON,
    name: 'Эберрон',
    nameEn: 'Eberron',
  },
  {
    id: SETTING_FORGOTTEN_REALMS,
    name: 'Забытые Королевства',
    nameEn: 'Forgotten Realms',
  },
  {
    id: SETTING_GREYHAWK,
    name: 'Серый Ястреб',
    nameEn: 'Greyhawk',
  },
  {
    id: SETTING_RAVNICA,
    name: 'Равника',
    nameEn: 'Ravnica',
  },
  {
    id: SETTING_EXANDRIA,
    name: 'Эксандрия',
    nameEn: 'Exandria',
  },
  {
    id: SETTING_THEROS,
    name: 'Терос',
    nameEn: 'Theros',
  },
  {
    id: SETTING_STRIXHAVEN,
    name: 'Стриксхейвен',
    nameEn: 'Strixhaven',
  },
]

module.exports = settingList

module.exports.settingCollection = listToCollectionById(settingList)

module.exports.settingIdList = settingList.map(({id}) => id)

module.exports.SETTING_DRAGON_LANCE = SETTING_DRAGON_LANCE
module.exports.SETTING_EBERRON = SETTING_EBERRON
module.exports.SETTING_EXANDRIA = SETTING_EXANDRIA
module.exports.SETTING_FORGOTTEN_REALMS = SETTING_FORGOTTEN_REALMS
module.exports.SETTING_GREYHAWK = SETTING_GREYHAWK
module.exports.SETTING_RAVNICA = SETTING_RAVNICA
module.exports.SETTING_STRIXHAVEN = SETTING_STRIXHAVEN
module.exports.SETTING_THEROS = SETTING_THEROS

const {SOURCE_EE} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_AARAKOCRA} = require ('./../../pcRaceIdList')
const {
  LANG_AARAKOCRA,
  LANG_AURAN,
  LANG_COMMON,
} = require ('./../../languageIdList')
const {
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')

const {
  PARAM_DEX,
  PARAM_WIT,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_AARAKOCRA,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_AARAKOCRA].name,
  nameEn: creatureTypeCollection[PC_RACE_AARAKOCRA].nameEn,
  nameAlt: creatureTypeCollection[PC_RACE_AARAKOCRA].nameAlt,
  nameEnAlt: creatureTypeCollection[PC_RACE_AARAKOCRA].nameEnAlt,
  description: [
    ...creatureTypeCollection[PC_RACE_AARAKOCRA].description,
    {
      header: 'Предыстории ааракокр',
      text: `Предыстории, которые наиболее подходят ааракокрам, это мудрец, отшельник или чужеземец.

Небольшие колонии ааракокр замкнуты и отдалены, и мало какие ааракокры живут вдали от своих насестов. В Звёздных горах Высокого леса в Забытых Королевствах не более нескольких десятков ааракокр живёт вдали от своих семей. В большинстве своём это следопыты и воины — те, кто постоянно патрулирует окрестности, не допуская внешних угроз.`,
      source: {
        id: SOURCE_EE,
        page: 4,
      },
    },
    {
      header: 'Особенности ааракокр',
      text: `У Вас, как у ааракокры, есть некоторые общие особенности с вашим народом. Вы можете летать на высокой скорости, что исключительно эффективно при одних обстоятельствах и чрезвычайно опасно при других. Игра за ааракокру требует специального разрешения от Мастера.

# Увеличение характеристик

Ловкость увеличивается на 2.

Мудрость увеличивается на 1.

# Возраст

Ааракокры достигают зрелости к 3 годам. Обычно ааракокры не живут дольше 30 лет.

# Мировоззрение

Большая часть ааракокр добрая, но они редко выбирают между Принципами и Хаосом. Вожди племени и воители могут быть принципиальными, а исследователи и искатели приключений, скорее всего, будут хаотичными.

# Размер

Рост ааракокр около 5 футов (1,5 метра). Их тела стройные и лёгкие, а вес может быть в диапазоне от 80 до 100 фунтов (от 36 до 45 килограмм). Ваш размер — Средний.

# Скорость

Ваша базовая скорость перемещения — 25 футов.

# Полёт

Вы можете летать со скоростью 50 футов. Для этого Вы не должны носить ни средний, ни тяжёлый доспех.

# Когти

Вы владеете безоружной рукопашной атакой, которая наносит 1к4 рубящего урона.

# Язык

Вы разговариваете, читаете и пишете на [Всеобщем](LANG:${LANG_COMMON}), [Ауране](LANG:${LANG_AURAN}) и [языке Ааракокр](LANG:${LANG_AARAKOCRA}).`,
      columnCount: 2,
      source: {
        id: SOURCE_EE,
        page: 4,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 10,
      coefDice: {
        diceCount: 1,
        diceType: 4,
      },
    },
    weight: {
      base: 77,
      coefDice: {
        diceCount: 1,
        diceType: 6,
      },
    },
  },
  paramBonuses: {
    [PARAM_DEX]: 2,
    [PARAM_WIT]: 1,
  },
  age: {
    adult: 3,
    max: 30,
  },
  noHairs: true,
  setting: [
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
  ],
  source: {
    id: SOURCE_EE,
    page: 1,
  },
}

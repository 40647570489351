const phbSizeSource = require('./phbSizeSource')

const humanSize = {
  source: phbSizeSource,
  tall: {
    base: 4 * 12 + 8,
    coefDice: {
      diceCount: 2,
      diceType: 10,
    },
  },
  weight: {
    base: 110,
    coefDice: {
      diceCount: 2,
      diceType: 4,
    },
  },
}

module.exports = humanSize

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_DAMARAN} = require('./../../../pcSubraceIdList')
const {SOURCE_PHB} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_DAMARAN,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: 'дамарец',
      [GENDER_FEMALE]: 'дамарка',
    },
    dual: {
      [GENDER_MALE]: 'дамарца',
      [GENDER_FEMALE]: 'дамарки',
    },
    plural: 'дамарцы',
  },
  nameEn: 'Damaran',
  description: `Начавшие свою историю на северо-западе Фаэруна, дамарцы имеют средний рост и сложение, оттенок их кожи варьируется от смуглого до светлого. Их волосы обычно коричневые или чёрные, а цвет глаз сильно отличается, хотя карие — самые распространённые.

# Имена дамарцев

## Мужские

Айвор, Бор, Глэр, Григор, Иган, Козеф, Миваль, Орел, Павел, Сергор, Фодель

## Женские

Алетра, Зора, Кара, Катернин, Мара, Натали, Ольма, Тана

## Фамилии

Берск, Дотск, Куленов, Марск, Немецк, Стараг, Чернин, Шемов`,
  source: {
    id: SOURCE_PHB,
    page: 31,
  },
}

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_WIT} = require ('./../../../paramList')
const {PC_RACE_GITH} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_GITH_GITHZERAI} = require('./../../../pcSubraceIdList')
const {CREATURE_TYPE_GITHZERAI} = require('./../../../creatureTypeIdList')
const {creatureTypeCollection} = require('./../../../creatureTypeList')
const {SOURCE_MTOF} = require ('./../../../sourceList')
const {
  SPELL_DETECT_THOUGHTS,
  SPELL_MAGE_HAND,
  SPELL_SHIELD,
} = require ('./../../../spellIdList')

module.exports = {
  id: PC_SUBRACE_GITH_GITHZERAI,
  parentRaceId: PC_RACE_GITH,
  name: {
    singular: {
      [GENDER_MALE]: 'гитцерай',
      [GENDER_FEMALE]: 'гитцерай',
    },
    dual: {
      [GENDER_MALE]: 'гитцерая',
      [GENDER_FEMALE]: 'гитцераи',
    },
    plural: 'гитцераи',
  },
  nameEn: creatureTypeCollection[CREATURE_TYPE_GITHZERAI].nameEn,
  note: creatureTypeCollection[CREATURE_TYPE_GITHZERAI].note,
  description: [
    ...creatureTypeCollection[CREATURE_TYPE_GITHZERAI].description,
    {
      header: `Особенности гитцераев`,
      text: `В крепостях на плане Лимбо гитцераи оттачивают свой разум до остроты бритвы.

# Увеличение характеристик

Мудрость увеличивается на 2.

# Мировоззрение

Гитцераи склоняются к принципиально-нейтральному мировоззрению. Их строгое обучение психическим навыкам требует безжалостной дисциплины.

# Ментальная дисциплина

Вы получаете преимущество к испытаниям от Очарования и Страха. Под руководством наставников гитцераи учатся управлять собственным разумом.

# Псионика гитцераев

Вы знаете заговор [Волшебная рука](SPELL:${SPELL_MAGE_HAND}), при этом рука невидима.

По достижении 3 уровня Вы сможете раз в день сотворить заклинание [Щит](SPELL:${SPELL_SHIELD}).

По достижении 5 уровня, Вы также сможете раз в день сотворить заклинание [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS}).

_«Раз в день»_ означает, что Вы должны окончить длинный отдых, прежде чем сможете сотворить этой способностью это заклинание снова. Мудрость — заклинательная характеристика для этих заклинаний.

Заклинания, сотворяемые с помощью этой способности, не требуют материальных компонентов.`,
      source: {
        id: SOURCE_MTOF,
        page: 98,
      },
    },
  ],
  size: {
    tall: {
      base: 4 * 12 + 11,
      coefDice: {
        diceCount: 2,
        diceType: 12,
      },
    },
    weight: {
      base: 90,
      coefDice: {
        diceCount: 1,
        diceType: 4,
      },
    },
    source: {
      id: SOURCE_MTOF,
      page: 97,
    },
  },
  paramBonuses: {
    [PARAM_WIT]: 2,
  },
  source: {
    id: SOURCE_MTOF,
    page: 98,
  },
}

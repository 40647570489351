const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_ILLUSKAN} = require('./../../../pcSubraceIdList')
const {SOURCE_PHB} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_ILLUSKAN,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: 'иллюсканец',
      [GENDER_FEMALE]: 'иллюсканка',
    },
    dual: {
      [GENDER_MALE]: 'иллюсканца',
      [GENDER_FEMALE]: 'иллюсканки',
    },
    plural: 'иллюсканцы',
  },
  nameEn: 'Illuskan',
  description: `Иллюсканцы — высокий, светлокожий народ с голубыми или серыми со стальным оттенком глазами. У большинства чёрные волосы цвета вороньего крыла, но у тех, кто населяет край северо-запада — светлые, рыжие или светло-русые волосы.

# Имена иллусканцев

## Мужские

Блас, Бран, Гет, Ландер, Лют, Малсер, Стор, Таман, Урт, Фрат, Эндер

## Женские

Амафрея, Бетха, Вестра, Кетра, Мара, Ольга, Силифрей, Цефрея

## Фамилии

Брайтвуд, Виндривер, Лакмэн, Хелдер, Хорнрейвен, Штормвинд`,
  source: {
    id: SOURCE_PHB,
    page: 31,
  },
}

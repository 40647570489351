const {SOURCE_VRGtR} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_DHAMPIR} = require ('./../../pcRaceIdList')
const {SETTING_FORGOTTEN_REALMS} = require ('./../../settingList')
const {lineagesDescriptionList} = require('./../../textCommonParts')
const {
  SIZE_MEDIUM,
  SIZE_SMALL,
} = require ('./../../sizeList')

module.exports = {
  id: PC_RACE_DHAMPIR,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_DHAMPIR].name,
  nameEn: creatureTypeCollection[PC_RACE_DHAMPIR].nameEn,
  sizeType: [
    SIZE_MEDIUM,
    SIZE_SMALL,
  ],
  description: [
    ...creatureTypeCollection[PC_RACE_DHAMPIR].description,
    ...lineagesDescriptionList,
    {
      header: `Особенности дампиров`,
      text: `Вы имеете следующие расовые особенности

# Тип существа

Вы — гуманоид.

# Размер

Вы — Маленького или Среднего размера. Вы выбираете размер, когда получаете это наследие.

# Скорость

Ваша скорость ходьбы 35 футов.

# Наследие предков

Если Вы заменяете свою расу на это наследие, то можете сохранить следующие особенности той расы:

* Владения навыками;
* Скорость лазания, полёта, плавания.

Если Вы не сохраняете никакие из этих особенностей или если Вы выбрали это наследие при создании персонажа, то Вы получаете владение двумя навыками на Ваш выбор.

# Тёмное зрение

На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Бессмертная природа

Вам не нужно дышать.

# Паучье лазание

Вы получаете скорость лазания, равную Вашей скорости ходьбы.

Кроме того, начиная с 3 уровня, Вы можете передвигаться вверх, вниз и вдоль вертикальных поверхностей, а также вверх ногами по потолку, оставляя руки свободными.

# Вампирский укус

Ваш _Укус клыками_ — природное оружие, считающееся простым рукопашным оружием, которым Вы владеете. Вы добавляете к атакам и урону _Укуса_ модификатор Телосложения вместо модификатора Силы. _Клыки_ наносят 1к4 колющего урона при попадании.

Когда у Вас половина хитов или меньше, Вы получаете преимущество на атаки _Клыками_.

Попадая атакой _Клыками_ по существу, не являющемуся конструктом или нежитью, Вы усиливаете себя одним из следующим способов на Ваш выбор.

* Вы восстанавливаете количество хитов, равное колющему урону, нанесённому _Укусом клыками_.
* Вы получаете бонус на следующую проверку способности или бросок атаки. Бонус равен колющему урону, нанесённому _Укусом клыками_.

Вы можете усиливать себя _Укусом_ количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, заканчивая длинный отдых.`,
      source: {
        id: SOURCE_VRGtR,
        page: 17,
      },
    },
  ],
  setting: SETTING_FORGOTTEN_REALMS,
  source: {
    id: SOURCE_VRGtR,
    page: 18,
  },
}

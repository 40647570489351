const {CREATURE_DEVA} = require ('./../../creatureIdList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PARAM_CHA} = require ('./../../paramList')
const {PC_RACE_AASIMAR} = require ('./../../pcRaceIdList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {SOURCE_VGTM} = require ('./../../sourceList')
const {SPELL_LIGHT} = require ('./../../spellIdList')
const {
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')

module.exports = {
  id: PC_RACE_AASIMAR,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_AASIMAR].name,
  nameEn: creatureTypeCollection[PC_RACE_AASIMAR].nameEn,
  note: creatureTypeCollection[PC_RACE_AASIMAR].note,
  description: [
    ...creatureTypeCollection[PC_RACE_AASIMAR].description,
    {
      header: `Поборники небес`,
      text: `Аасимары пришли в мир, чтобы служить защитниками добра и закона. Их покровители ожидают, что они будут бороться со злом, служить примером окружающим, и вершить правосудие.

С раннего возраста, аасимар получает видения и указания от небожителей через сны. Эти сны помогают аасимару сформироваться, давая ему ощущение предназначения и жажду праведности.

Каждый аасимар может считать определенного небожителя — посланника богов своим наставником. Эти небожители как правило — [дэвы](CREATURE:${CREATURE_DEVA}), ангелы, которые выступают в роли божественных посланников в смертный мир.`,
      source: {
        id: SOURCE_VGTM,
        page: 106,
      },
    },
    {
      header: `Скрытные странники`,
      text: `Несмотря на то, что аасимары — резкие противники зла, они как правило предпочитают вести себя сдержанно. Иначе аасимары неизбежно привлекают внимание злых культистов, злодеев, и других противников добра, каждый из которых жаждет убить поборника небес, если выпадет шанс.

Путешествуя, аасимары предпочитают капюшоны, закрытые шлемы, и другие приспособления, которые позволяют им скрывать свою сущность. Они тем не менее не сожалеют, если им приходится нанести открытый удар злу. Аасимары никогда не подвергнут опасности невинных ради сохранения своей тайны.`,
      source: {
        id: SOURCE_VGTM,
        page: 106,
      },
    },
    {
      header: `Наставники аасимаров`,
      text: `У аасимаров, за исключением тех, кто перешел на сторону зла, есть связь с ангельской сущностью. Эта сущность, обычно [дэв](CREATURE:${CREATURE_DEVA}), наставляет аасимара, хотя эта связь функционирует только во снах. Это не прямой приказ или простая беседа — аасимар получает видения, пророчества, и предчувствия.

Ангельская сущность не всеведуща. Её наставления основаны на собственном понимании принципов добра и порядка, и она может понимать, как противостоять особенно сильному злу, о котором она знает.

Для дополнения описания характера аасимара, рассмотрите характер ангельского наставника этого персонажа. Таблицы «Ангельский наставник» предлагают имя и характер, которые Вы можете использовать, чтобы описать ангельского наставника Вашего персонажа.

## Ангельский наставник

| к6 | Имя       |
|----|-----------|
| 1  | Тадриэль  |
| 2  | Миландра  |
| 3  | Серафина  |
| 4  | Галадия   |
| 5  | Микель    |
| 6  | Валандрас |

| к6 | Характер                        |
|----|---------------------------------|
| 1  | Поучающий идеалист              |
| 2  | Сострадательный и полный надежд |
| 3  | Практичный и беззаботный        |
| 4  | Жестокий и мстительный          |
| 5  | Строгий и критичный             |
| 6  | По-отечески добрый              |

# Примечание для мастера: отыгрыш ангельского наставника

Как Мастер, Вы берете на себя роль ангельского наставника аасимара и решаете какой совет или предзнаменования послать во сне.

[Дэв](CREATURE:${CREATURE_DEVA}), или другой небожитель — это возможность для Вас добавить в игру особый элемент отыгрыша. Помните, что [дэвы](CREATURE:${CREATURE_DEVA}) живут в мире добра и порядка, возведенных в абсолют. [Дэвы](CREATURE:${CREATURE_DEVA}) не понимают компромиссов и трудностей выбора, с которыми сталкиваются смертные. Для [дэва](CREATURE:${CREATURE_DEVA}) аасимар это ученик, который должен соответствовать высоким, а порой даже жёстким стандартам.`,
      source: {
        id: SOURCE_VGTM,
        page: 106,
      },
    },
    {
      header: `Противоречивые души`,
      text: `Несмотря на свое небесное происхождение, аасимар смертен и обладает свободой воли. Большинство аасимаров принимают свое предназначение, но некоторые воспринимают свои способности как проклятье. Эти недовольные аасимары обычно просто отстраняются от всего мирского, но некоторые из них становятся служителями зла. По их мнению, приверженность небесным силам является не более, чем промыванием мозгов.

Злые аасимары — смертельные противники. Сила света, которой они когда-то обладали, преобразуется в отвратительную, иссушающую магию, а их ангельские наставники покидают их.

Даже аасимары, полностью преданные добру, иногда чувствуют разрыв между двумя мирами. Ангелы, которые направляют их, видят мир издали. Аасимар, который хочет остаться и помочь городу оправиться от засухи, вынужден по указанию ангельского наставника двигаться дальше на более важное задание. Отдаленному ангелу спасение нескольких простых человек кажется незначительным по сравнению с нанесением поражения культу Оркуса. Наставник аасимара мудрый, но он тоже склонен заблуждаться.`,
      source: {
        id: SOURCE_VGTM,
        page: 106,
      },
    },
    {
      header: `Имена аасимаров`,
      text: `Большинство аасимаров рождается среди людей и использует имена, традиционные для своей родной культуры.`,
      source: {
        id: SOURCE_VGTM,
        page: 106,
      },
    },
    {
      header: `Особенности аасимаров`,
      text: `Ваш персонаж-аасимар обладает следующими расовыми особенностями. 

# Увеличение характеристик

Харизма увеличивается на 2.

# Возраст

Аасимары взрослеют как и люди, но они могут прожить до 160 лет.

# Мировоззрение

Наполненные небесной силой, аасимары в большинстве своём добрые. Но изгои чаще всего нейтральные или даже злые.

# Размер

У аасимаров такие же рост и вес, как и у людей.

# Скорость

Ваша базовая скорость ходьбы 30 футов.

# Тёмное зрение

Благословленное светлым духом, Ваше зрение может легко проникать сквозь темноту. На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Небесное сопротивление

У Вас есть сопротивление некротическому урону и урону излучением.

# Исцеляющие руки

Вы можете действием коснуться существо и восстановить ему количество хитов, равное Вашему уровню. Вы не сможете вновь воспользоваться этой способностью пока не окончите длинный отдых.

# Несущий свет

Вам известен заговор [Свет](SPELL:${SPELL_LIGHT}). Заклинательная характеристика для него — Харизма.

# Языки

Вы можете разговаривать, читать и писать на Общем и Небесном языках.

# Разновидности

Существует три разновидности аасимаров: аасимар-защитник, аасимар-каратель и падший аасимар. Выберите одну из них для своего персонажа.`,
      columnCount: 2,
      source: {
        id: SOURCE_VGTM,
        page: 106,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 8,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 110,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_CHA]: 2,
  },
  age: {
    adult: 20,
    max: 160,
  },
  setting: [
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
  ],
  source: {
    id: SOURCE_VGTM,
    page: 107,
  },
}

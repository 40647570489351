const {SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {CREATURE_TYPE_AUTOGNOME} = require ('./../../creatureTypeIdList')
const {PC_RACE_AUTOGNOME} = require ('./../../pcRaceIdList')
const {
  SETTING_DRAGON_LANCE,
  SETTING_GREYHAWK,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')
const {SIZE_SMALL} = require ('./../../sizeList')
const {
  SPELL_CURE_WOUNDS,
  SPELL_HEALING_WORD,
  SPELL_MENDING,
  SPELL_SPARE_THE_DYING,
} = require ('./../../spellIdList')
const customLanguages = require ('./../constants/customLanguages')
const customParamBonuses = require ('./../constants/customParamBonuses')

module.exports = {
  id: PC_RACE_AUTOGNOME,
  isReady: true,
  name: creatureTypeCollection[CREATURE_TYPE_AUTOGNOME].name,
  nameEn: creatureTypeCollection[CREATURE_TYPE_AUTOGNOME].nameEn,
  sizeType: SIZE_SMALL,
  setting: [
    SETTING_DRAGON_LANCE,
    SETTING_GREYHAWK,
    SETTING_FORGOTTEN_REALMS,
  ],
  description: [
    ...creatureTypeCollection[CREATURE_TYPE_AUTOGNOME].description,
    {
      header: 'История автогнома',
      text: `Определите случайно или выберите самостоятельно одну из историй в таблице, направившую Вашего автогнома на путь приключений. Если ничего в таблице Вам не подходит, то вместе с Мастером создайте историю именно для Вашего автогнома.

| к8 | История |
|-|-|
| 1 | Ваш создатель дал Вам свободу и научил следовать мечтам. | 
| 2 | Ваш создатель умер, оставив Вас заботиться о себе самостоятельно. | 
| 3 | Из-за временного сбоя Вы навредили своему создателю и сбежали от возможных последствий. | 
| 4 | Сбой стёр Ваши настройки. Вы не помните ни кто Вас сделал, ни откуда Вы. | 
| 5 | Вас украли у создателя и хотели бы к нему вернуться. | 
| 6 | Вам не нравилось то, как создатель с Вами обращался, поэтому Вы сбежали. | 
| 7 | Создатель построил Вас для выполнения особого поручения. | 
| 8 | Вы чувствовали себя запертым в роли, отведённой Вам создателем. Вы покинули его, стараясь найти лучшее предназначение. | 
`,
      source: {
        id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
        page: 2,
      },
    },
    {
      header: 'Особенности автогномов',
      text: `Вы получаете следующие расовые особенности 

# Тип существа

Вы — конструкт.

${customParamBonuses}

# Размер

Ваш размер — Маленький.

# Скорость

Ваша скорость ходьбы 30 футов. 

# Укреплённый корпус

Вы защищены тонкими листами металла или иным прочным материалом. Пока Вы не носите броню, Ваш КД равен 13 + модификатор Ловкости.

# Построен для успеха

Вы можете добавлять к4 к одному своему броску атаки, проверке характеристик или испытанию. Вы можете использовать эту способность до или после броска, но до того, как эффекты броска вступят в силу.

Вы можете использовать эту способность число раз, равное Вашему бонусу мастерства. Вы восстанавливаете все израсходованные использования, когда завершаете длинный отдых.

# Механическая природа

Вы получаете сопротивление урону ядом, иммунитет к болезням и преимущество на испытания от парализации и отравления. Вам не нужно есть, пить и дышать.

# Охранный отдых

Когда Вы совершаете длинный отдых, то вместо сна Вы проводите не менее 6 часов в неактивном, неподвижном состоянии. В этом состоянии Вы кажетесь неактивным, но находитесь в сознании. 

# Специализированный дизайн

Вы получаете владение двумя инструментами по своему выбору. 

# Настоящая жизнь

Если на Вас сотворить заговор [Починка](SPELL:${SPELL_MENDING}), то Вы можете потратить кость хитов и восстановить число хитов, равное результату броска кости хитов + модификатор Телосложения (минимум 1 хит). 

Кроме того, Ваш создатель спроектировал Вас так, чтобы Вы могли получать все выгоды от простых заклинаний, которые сохраняют жизнь, но обычно не действуют на конструктов: [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}), [Лечащее слово](SPELL:${SPELL_HEALING_WORD}) и [Уход за умирающим](SPELL:${SPELL_SPARE_THE_DYING}).

${customLanguages}`,
      source: {
        id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
        page: 2,
      },
    },
  ],
  source: {
    id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
    page: 2,
  },
}

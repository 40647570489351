const {SOURCE_OGA} = require ('./../../sourceList')
const {SIZE_SMALL} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_GRUNG} = require ('./../../pcRaceIdList')
const {SETTING_FORGOTTEN_REALMS} = require ('./../../settingList')

const {
  PARAM_DEX,
  PARAM_CON,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_GRUNG,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_GRUNG].name,
  nameEn: creatureTypeCollection[PC_RACE_GRUNG].nameEn,
  note: creatureTypeCollection[PC_RACE_GRUNG].note,
  description: [
    ...creatureTypeCollection[PC_RACE_GRUNG].description,
    {
      header: `Особенности грунгов`,
      text: `Ваш персонаж-грунг обладает набором врождённых расовых способностей, как неотъемлемой частью его природы.

# Увеличение характеристик

Ловкость увеличивается на 2.

Телосложение увеличивается на 1.

# Возраст

Грунги достигают зрелости в один год, и обычно живут около 50 лет.

# Мировоззрение

Большинство грунгов принципиальны, будучи выращенными в строгой кастовой системы. Кроме того они имеют тягу ко злу, живя в культуре, где социальное продвижение происходит редко и обычно только потому что другой член выводка умирает и в его касте нет никого, кто мог бы занять его место.

# Древесная бдительность

Вы владеете навыком Внимательность.

# Размер

Грунги ростом от 2 ½ до 3 ½ футов и весят около 30 фунтов. Ваш размер — Маленький.

# Скорость

Ваша базовая скорость ходьбы 25 футов, и у Вас есть скорость лазания 25 футов.

# Амфибия

Вы можете дышать как воздухом, так и водой.

# Иммунитет к яду

Вы имунны к урону ядом и Отравленному состоянию.

# Ядовитая кожа

Любое существо, схватившее Вас или как-либо иначе вошедшее в прямой контакт с Вашей кожей должно пройти испытание Телосложения СЛ 12 или стать Отравленным на 1 минуту. Отравленное существо, которое больше не касается Вас напрямую, может повторять испытание в конце каждого своего хода, оканчивая эффект на себе при успехе.

Вы также может нанести этот яд на любое колющее оружие как часть атаки этим оружием, хотя при попадании яд действует иначе. Цель должна пройти испытание Телосложения СЛ 12 или получить 2к4 урона ядом.

# Пряжок с места

Вы можете прыгать с места на 25 футов в длину и на 15 футов в высоту, с разбега или без.

# Водная зависимость

Если Вы не погружались в воду хотя бы на 1 час в течение дня, то Вы получаете один уровень истощения в конце этого дня. Вы можете снять это истощение только магией или погрузившись в воду хотя бы на 1 час.

# Языки

Вы можете говорить, читать и писать на Грунгском языке.`,
      source: {
        id: SOURCE_OGA,
        page: 4,
      },
    },
  ],
  sizeType: SIZE_SMALL,
  size: {
    tall: {
      base: 2 * 12 + 2,
      coefDice: {
        diceCount: 4,
        diceType: 4,
      },
    },
    weight: {
      base: 22,
      coefDice: {
        diceBonus: 1,
      },
    },
    source: {
      id: SOURCE_OGA,
      page: 4,
    },
  },
  paramBonuses: {
    [PARAM_DEX]: 2,
    [PARAM_CON]: 1,
  },
  age: {
    adult: 1,
    max: 50,
  },
  noHairs: true,
  setting: SETTING_FORGOTTEN_REALMS,
  source: {
    id: SOURCE_OGA,
    page: 4,
  },
}

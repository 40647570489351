import mapGender from '@/utils/nameGeneration/mapGender'
import {GENDER_MIDDLE, GENDER_MALE, GENDER_FEMALE} from '@/constants/genderList'

import nounFemaleList from './nounFemaleList'
import nounMaleList from './nounMaleList'
import nounMiddleList from './nounMiddleList'

const nounList = [
  ...nounFemaleList.map(mapGender(GENDER_FEMALE)),
  ...nounMaleList.map(mapGender(GENDER_MALE)),
  ...nounMiddleList.map(mapGender(GENDER_MIDDLE)),
].map(
  ({ singular, plural, isLocation, ...rest }) => ({
    ...rest,
    singular,
    dual: {
      nominative: singular.genitive,
      genitive: plural.genitive,
      prepositional: plural.prepositional,
    },
    plural,
    isLocation: isLocation || false,
  }),
)

export const nounLocationList = nounList.filter(
  ({isLocation}) => isLocation
)

export default nounList

const {GEAR_YKLWA} = require ('./../../../gearIdList')
const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_CHULT} = require('./../../../pcSubraceIdList')
const {SOURCE_TOA} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_CHULT,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: 'чульт',
      [GENDER_FEMALE]: 'чультка',
    },
    dual: {
      [GENDER_MALE]: 'чульта',
      [GENDER_FEMALE]: 'чультки',
    },
    plural: 'чульты',
  },
  description: `Чульты — это люди, живущие в Чульте. Они обладают темной кожей и волосами, а также богатой культурой. Выгнанные из джунглей монстрами и нежитью, они принимают беженцев за каменными стенами Порта Нянзару, отказавшись от своих династий и объединившись в единую торговую силу на южном океане, стремящуюся вести дела со всеми, кто посетит их опасные земли. Некоторые чульты мигрировали на север и основали свои поселения в таких дальних городах, как Азкатла, Врата Балдура, Калимпорт, Мемнон и Засесспур.

Род королей и королев, некогда имевших политическое влияние над Чультом, в большинстве своём был позабыт с течением времени, войн, бедствий и несчастий. Они были заменены на чультских торговых принцев, которые научились искусству торговли у дельцов из Тетира и Амна. Сейчас они используют своё богатство для заключения выгодных союзов и управляют судьбой своего царства.

Чульты одеваются в светлые разноцветные одежды, соответствующие тропическому климату. Они торгуют золотом, драгоценными камнями, ювелирными украшениями, текстилем, специями, редкими травами, слоновой костью, деревом, необработанной железной рудой и другими товарами, которые можно собрать или награбить в джунглях, долинах и горах Чульта. Среди своих чульты разговаривают на племенных языках (смеси вдыхаемых и выдыхаемых согласных, гласных и щёлканья языка). Однако те, кто часто общаются с приезжими, свободно разговаривают на Общем, но с сильным и характерным акцентом.

Чульты в Порту Нянзару редко носят броню из-за жаркого климата, но большие красочные щиты, сделанные из шкуры динозавров, довольно распространены. Эти щиты называются «ланг» и являются обычными щитами. В ближнем бою чульты используют [иклву](GEAR:${GEAR_YKLWA}), короткое одноручное оружие, напоминающее копьё и обладающее широким лезвием.`,
  source: {
    id: SOURCE_TOA,
    page: 13,
  },
}

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_CALISHITE} = require('./../../../pcSubraceIdList')
const {SOURCE_PHB} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_CALISHITE,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: 'калишит',
      [GENDER_FEMALE]: 'калишитка',
    },
    dual: {
      [GENDER_MALE]: 'калишита',
      [GENDER_FEMALE]: 'калишитки',
    },
    plural: 'калишиты',
  },
  nameEn: 'Calishite',
  description: `Более низкие и стройные, чем большинство других людей, калишиты имеют смугло-коричневую кожу, волосы и глаза. Они обитают в основном на юго-западе Фаэруна.

# Имена калишитов

## Мужские

Асеир, Бардеид, Зашеир, Кхемед, Мехмен, Судейман, Хасеид

## Женские

Атала, Джасмаль, Зашеида, Мейлиль, Сейдиль, Сейпора, Хама, Яшеира

## Фамилии

Баша, Джассан, Думеин, Кхалид, Мостана, Пашар, Рейн`,
  source: {
    id: SOURCE_PHB,
    page: 32,
  },
}

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {GOD_LOLTH} = require ('./../../../godIdList')
const {PARAM_CHA} = require ('./../../../paramList')
const {PC_RACE_ELF} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_ELF_DROW} = require('./../../../pcSubraceIdList')
const {SOURCE_PHB, SOURCE_SCAG} = require ('./../../../sourceList')
const {
  drowDescription,
  drowNote,
} = require ('./../../../textCommonParts')
const {
  GEAR_CROSSBOW_HAND,
  GEAR_RAPIER,
  GEAR_SHORTSWORD,
} = require ('./../../../gearIdList')
const {
  SPELL_DANCING_LIGHTS,
  SPELL_DARKNESS,
  SPELL_FAERIE_FIRE,
} = require ('./../../../spellIdList')

const phbSizeSource = require ('./../../constants/phbSizeSource')

module.exports = {
  id: PC_SUBRACE_ELF_DROW,
  parentRaceId: PC_RACE_ELF,
  name: {
    singular: {
      [GENDER_MALE]: 'дроу',
      [GENDER_FEMALE]: 'дроу',
    },
    dual: {
      [GENDER_MALE]: 'дроу',
      [GENDER_FEMALE]: 'дроу',
    },
    plural: 'дроу',
  },
  nameEn: `Drow`,
  nameEnAlt: `Dark Elves`,
  nameAlt: `Тёмные эльфы`,
  size: {
    tall: {
      base: 4 * 12 + 5,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
    weight: {
      base: 75,
      coefDice: {
        diceCount: 1,
        diceType: 6,
      },
    },
    source: phbSizeSource,
  },
  note: [
    drowNote,
    {
      text: `Я слышал рассказы о дроу, которые отказались от злых путей собственного рода. Я совершенно не верю этим историям, хотя даже сам Эльминстер клянётся, что в них есть правда. Никогда не доверяйте дроу или словам этого архимага`,
      author: `Архимаг Морденкайнен`,
    },
  ],
  description: [
    {
      header: `Тёмные эльфы (Дроу)`,
      text: `Дроу — потомки тёмных эльфов, спустившихся в Подземье после Войн Короны.

Они известны своей жестокостью, злобой и тягой к доминированию.

Большую часть истории мира, многие полагали, что все дроу являются существами врождённого и неискоренимого зла. На деле действительно, большинство дроу стремятся ко злу, принимая участия в пытках, порабощении, убийствах и прочих скверных делах во имя своей демонической богини. Почти всегда тёмные эльфы, отвергнувшие путь большинства, становятся изгоями или погибают, казнённые за мятеж, ересь и бунт против устоев культуры дроу и воли [Ллос](GOD:${GOD_LOLTH}). Но существование благородных и самоотверженных дроу, таких как Лириэль Бэнр и Дзиррт До’Урден позволяет предположить, что зло дроу не является врождённой чертой и его можно превзойти. Деяния этих героических дроу умерили мнение некоторых относительно расы дроу, впрочем, появление тёмного эльфа на поверхности остаётся редкостью и вызывает тревогу.

Многие дроу происходят из Мензоберранзана, небезызвестного Города Пауков, одного из городов-государств дроу расположенных в Подземье, таких как Йачалхин или Чед Насад. Тёмных эльфов обычно можно встретить на поверхности близ входа в Подземье, потому как солнечный свет им вредит, ослабляя их и их магию. Дроу становятся искателями приключений чаще всего сбежав из своих городов-государств от жестокости и притеснений теократического строя. Большинство таких живёт жизнью изгнанников и скитальцев, хотя изредка некоторые находят себе пристанище среди другой расы или культуры.

Дроу присущи черты тёмных эльфов, приведенные в Книге Игрока. Персонажи дроу могут иметь любые предыстории, но большинство из них имеет связь с одним из городов-государств дроу Подземья.

Врождённые магические способности и предпочтение, отдаваемое тёмным местам, делают дроу естественными мастерами убийства, воровства и шпионажа. Традиционно, мужчины дроу становятся воинами или чародеями, а женщины дроу занимают ведущие роли их общества в качестве воительниц и жриц Ллос. Изгнанники дроу предпочитают следовать своему собственному пути независимо от пола.`,
      source: {
        id: SOURCE_SCAG,
        page: 107,
      },
    },
    ...drowDescription,
    {
      header: `Тьма дроу`,
      text: `Если бы не одно знаменитое исключение, раса дроу могла бы быть повсеместно презираема. Для большинства это раса демонопоклонников, обитающих в глубинах Подземья, появляющихся только в самые тёмные ночи, чтобы грабить и убивать обитателей поверхности, которых они ненавидят. Их общество развращено и обращено к Ллос, их богине-паучихе, которая поощряет убийство и истребление целых семей борющихся за власть.

Тем не менее, как минимум один дроу смог сломать стереотип. В мире Забытых Королевств Дриззт До’Урден, следопыт Севера, проявил себя как добродетельный защитник слабых и невинных. Отвергая своё наследие, и существуя в мире, взирающем на него с ужасом и отвращением, Дриззт стал образцом для тех немногих дроу, кто следует по его стопам, пытаясь найти жизнь вне злого общества их дома — Подземья.

Дроу растут в уверенности, что живущие на поверхности расы хуже их, и ни на что не годятся, кроме как стать рабами. Дроу, у которых пробуждается совесть, или тем, кто считает необходимым сотрудничать с представителями других рас, бывает трудно преодолеть предрассудки, особенно, когда они сами так часто являются объектом ненависти.`,
      source: {
        id: SOURCE_PHB,
        page: 25,
      },
    },
    {
      header: `Особенности дроу`,
      text: `Произошедшие от более древней подрасы темнокожих эльфов, дроу были изгнаны с земной поверхности мира, и обречены поклоняться богине [Ллос](GOD:${GOD_LOLTH}) и следовать пути зла и упадка. Теперь они построили свою цивилизацию в глубинах Подземья, устроенную согласно Пути Ллос. Также называемые тёмными эльфами, дроу имеют чёрную кожу, которая напоминает полированный обсидиан и совершенно белые или очень светлые волосы. У них обычно бледные глаза (настолько бледные, что могут показаться белыми) с сиреневым, серебряным, розовым, красным или синим оттенком. Они, как правило, меньше и стройнее, чем большинство эльфов.

Искатели приключений дроу редки, и их раса существует не во всех мирах. Спросите вашего Мастера, можете ли Вы играть персонажем дроу.

# Увеличение характеристик

Харизма увеличивается на 1.

# Превосходное тёмное зрение

Ваше тёмное зрение имеет радиус 120 футов.

# Чувствительность к солнцу

Вы совершаете с помехой броски атаки и проверки Мудрости (Внимательность), основанные на зрении, если Вы, цель вашей атаки или изучаемый предмет расположены на прямом солнечном свете.

# Магия дроу

Вы знаете заклинание [Пляшущие огоньки](SPELL:${SPELL_DANCING_LIGHTS}).

Когда Вы достигаете 3 уровня, Вы можете сотворять заклинание [Огонь фей](SPELL:${SPELL_FAERIE_FIRE}).

При достижении 5 уровня Вы также сможете сотворять заклинание [Тьма](SPELL:${SPELL_DARKNESS}).

Вы должны окончить длинный отдых, прежде чем сможете сотворять эти заклинания ещё раз этой способностью. Харизма — заклинательная характеристика для этих заклинаний.

# Владение оружием дроу

Вы владеете [рапирой](GEAR:${GEAR_RAPIER}), [коротким мечом](GEAR:${GEAR_SHORTSWORD}) и [ручным арбалетом](GEAR:${GEAR_CROSSBOW_HAND}).`,
      source: {
        id: SOURCE_PHB,
        page: 25,
      },
    },
  ],
  paramBonuses: {
    [PARAM_CHA]: 1,
  },
  source: {
    id: SOURCE_PHB,
    page: 25,
  },
}

const {SOURCE_SaCoC} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_OWLIN} = require ('./../../pcRaceIdList')
const {SETTING_STRIXHAVEN} = require ('./../../settingList')
const {SIZE_MEDIUM} = require ('./../../sizeList')

module.exports = {
  id: PC_RACE_OWLIN,
  isReady: false,
  name: creatureTypeCollection[PC_RACE_OWLIN].name,
  nameEn: creatureTypeCollection[PC_RACE_OWLIN].nameEn,
  sizeType: SIZE_MEDIUM,
  setting: SETTING_STRIXHAVEN,
  source: {
    id: SOURCE_SaCoC,
  },
}

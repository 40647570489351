const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_CHA} = require ('./../../../paramList')
const {PC_RACE_HALFLING} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HALFLING_LIGHTFOOT} = require('./../../../pcSubraceIdList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HALFLING_LIGHTFOOT,
  parentRaceId: PC_RACE_HALFLING,
  name: {
    singular: {
      [GENDER_MALE]: 'легконогий полурослик',
      [GENDER_FEMALE]: 'легконогая полурослица',
    },
    dual: {
      [GENDER_MALE]: 'легконогих полурослика',
      [GENDER_FEMALE]: 'легконогих полурослицы',
    },
    plural: 'легконогие полурослики',
  },
  nameAlt: [
    `мохноногие`,
    `великанчики`,
  ],
  nameShort: {
    singular: {
      [GENDER_MALE]: 'легконогий',
      [GENDER_FEMALE]: 'легконогая',
    },
    dual: {
      [GENDER_MALE]: 'легконогих',
      [GENDER_FEMALE]: 'легконогих',
    },
    plural: 'легконогие',
  },
  nameEn: `Lightfoot Halflings`,
  nameEnAlt: [
    `hairfeet`,
    `tallfellows`,
  ],
  description: [
    {
      header: 'Легконогие полурослики',
      text: `Для легконогих полуросликов, ни цель, ни само по себе путешествие не важно. Главное — продолжать двигаться. Жизнь легконогих — это одна длительная экспедиция с новыми горизонтами, новыми городами и новыми лицами — шанс найти что-то восхитительное. Легконогие полурослики обычно путешествуют небольшими группами, используя любые средства, которые подходят для продолжения пути, в том числе и отправляясь в путь на своих двоих. Такие путники в таких группах слабо связанны меж собой и при встрече с другими группами, они часто меняют свою принадлежность и курс, отправляясь уже с другой группой по новой дороге. Легконогие полурослики предпочитают обычно задачи, связанные с путешествиями — такие как логистика, перегон скота, фуражировка, морское дело и возничество. Обучаются они этим навыкам самостоятельно или учатся у других легконогих, встреченных по пути в их путешествиях.

Легконогие полурослики очень социально активны и часто их любопытство относительно других народов сравнимо с их жаждой узнать, что ждёт их за следующим изгибом дороги. Для них характерны добродушие, открытость и заинтересованность в окружающих. Они также не прочь поделиться, и это даёт им возможность легко заводить друзей. Их поверхностная дружба и легкость, с которой они расстаются с кем-то или чем-то, может представлять их лицемерными в глазах окружающих. Стереотипы о легконогих полуросликах включают в себя ветреность, переменчивость, невнимательность и ненадёжность. Но их дружба и ухаживания, какими бы короткими они ни были, как правило искренни. Оседлая и стабильная жизнь, которой желают большинство людей попросту совсем им не свойственна.

Легконогие полурослики имеют расовые черты легконогих полуросликов из Книги Игрока. Цвет их кожи, глаз и волос такой же, как и у людей, но наиболее распространёнными являются карие и тёмно-карие глаза и каштановые волосы. Обычно, легконогие полурослики не отпускают бород и усов, за исключением того, что как мужчины, так и женщины обычно отращивают небольшие бакенбарды.`,
      source: {
        id: SOURCE_SCAG,
        page: 110,
      },
    },
    {
      header: 'Легконогие полурослики',
      text: `Легконогие полурослики умеют отлично скрываться, в том числе используя других существ как укрытие. Они приветливы и хорошо ладят с другими. В мире Забытых Королевств легконогие являются самой распространённой ветвью полуросликов.

Легконогие более других склонны к перемене мест, и часто селятся по соседству с другими народами, или ведут кочевую жизнь. В мире Серого Ястреба таких полуросликов называют мохноногими или великанчиками.`,
      source: {
        id: SOURCE_PHB,
        page: 29,
      },
    },
    {
      header: 'Особенности легконогих полуросликов',
      text: `
# Увеличение характеристик

Харизма увеличивается на 1.

# Естественная скрытность

Вы можете предпринять попытку скрыться даже если заслонены только существом, превосходящими Вас в размере как минимум на одну категорию.`,
      source: {
        id: SOURCE_PHB,
        page: 29,
      },
    },
  ],
  paramBonuses: {
    [PARAM_CHA]: 1,
  },
  source: {
    id: SOURCE_PHB,
    page: 29,
  },
}

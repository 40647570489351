const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_CON} = require ('./../../../paramList')
const {PC_RACE_GNOME} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_GNOME_ROCK} = require('./../../../pcSubraceIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require ('./../../../sourceList')
const {GEAR_TINKERS_TOOLS} = require ('./../../../gearIdList')

module.exports = {
  id: PC_SUBRACE_GNOME_ROCK,
  parentRaceId: PC_RACE_GNOME,
  name: {
    singular: {
      [GENDER_MALE]: 'скальный гном',
      [GENDER_FEMALE]: 'скальная гномка',
    },
    dual: {
      [GENDER_MALE]: 'скальных гнома',
      [GENDER_FEMALE]: 'скальные гномки',
    },
    plural: 'скальные гномы',
  },
  nameShort: {
    singular: {
      [GENDER_MALE]: 'скальный',
      [GENDER_FEMALE]: 'скальная',
    },
    dual: {
      [GENDER_MALE]: 'скальных',
      [GENDER_FEMALE]: 'скальные',
    },
    plural: 'скальные',
  },
  description: [
    {
      header: `Скальные гномы`,
      text: `Когда люд Побережья Мечей и Севера говорит о гномах, они подразумевают скальных гномов. В отличие от их застенчивых лесных кузенов, любопытные и неугомонные скальные гномы часто общаются с представителями прочих рас, особенно если у них есть чему поучиться. Скальные гномы предпочитают жить на границах поселений гномьими кварталами, хотя изредка авантюрный гном может поселиться в человеческом или дварфийском городе.

Общины скальных гномов наиболее распространены в Западном Сердцеземье и вдоль побережья Сияющего Моря; но также гномьи странники путешествуют между поселениями по всему Фаэруну, торгуют и учатся у чужаков, включая представителей других рас.

Скальные гномы, покинувшие их поселения, часто находят себе работу используя их расовые склонности как собственное преимущество. Их наследие и интерес к драгоценным камням подталкивает их становиться умелыми огранщиками и ювелирами. Скальные гномы также, учитывая их склонность к механизмам, часто становятся слесарями, алхимиками и инженерами. В человеческих сообществах, гномьи учителя и мудрецы популярны, так как их сравнительно длительная продолжительность жизни позволяет им приобретать и передавать знания целым поколениям.

Скальные гномы Фаэруна имеют расовые черты скальных гномов из Книги Игрока`,
      source: {
        id: SOURCE_SCAG,
        page: 115,
      },
    },
    {
      header: 'Скальные гномы',
      text: 'Скальных гномов чаще других можно встретить на поверхности Фаэруна. Они получили своё прозвище за крепкое телосложение и любовь к металлам.',
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Особенности скальных гномов`,
      text: `Скальные гномы выделяются своей изобретательностью и стойкостью. Большинство гномов в мирах D&D являются скальными, включая гномов-ремесленников из мира Саги о Копье.

# Увеличение характеристик

Телосложение увеличивается на 1.

# Ремесленные знания

При совершении проверки Интеллекта (История) применительно к магическому, алхимическому или технологическому
предмету, Вы можете добавить к проверке удвоенный бонус мастерства вместо обычного.

# Жестянщик

Вы владеете ремесленными инструментами ([инструменты ремонтника](GEAR:${GEAR_TINKERS_TOOLS})). С их помощью Вы можете, потратив 1 час времени и материалы на сумму в 10 зм, создать Крошечное механическое устройство (КД 5, 1 хит). Это устройство перестаёт работать через 24 часа (если Вы не потратите 1 час на поддержание его работы). Вы можете действием разобрать его; в этом случае Вы можете получить обратно использованные материалы. Одновременно Вы можете иметь не более трёх таких устройств.

При создании устройства выберите один из следующих вариантов

## Заводная игрушка

Эта заводная игрушка изображает животное, чудовище или существо, вроде лягушки, мыши, птицы, дракона или солдатика. Поставленная на землю, она проходит 5 футов в случайном направлении за каждый Ваш ход, издавая звуки, соответствующие изображаемому существу.

## Зажигалка

Это устройство производит миниатюрный огонёк, с помощью которого можно зажечь свечу, факел или костёр. Использование этого устройства требует действия.

## Музыкальная шкатулка

При открытии эта шкатулка проигрывает мелодию средней громкости. Шкатулка перестаёт играть если мелодия закончилась или если шкатулку закрыли.`,
      source: {
        id: SOURCE_PHB,
        page: 38,
      },
    },
  ],
  paramBonuses: {
    [PARAM_CON]: 1,
  },
  source: {
    id: SOURCE_PHB,
    page: 38,
  },
}

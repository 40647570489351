const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_SHAARAN} = require('./../../../pcSubraceIdList')
const {SOURCE_SCAG} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_SHAARAN,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: `шаарец`,
      [GENDER_FEMALE]: `шаарка`,
    },
    dual: {
      [GENDER_MALE]: `шаарца`,
      [GENDER_FEMALE]: `шаарки`,
    },
    plural: `шаарцы`,
  },
  nameEn: `Shaaran`,
  description: `Темноволосые и смуглые кочевники с юга Фаэруна, шаарцы тренированные охотники, лучники и наездники, поклоняющиеся различным божествам природы. Их общество образовано из кланов, управляемых вождями или старейшинами.

# Шаарские имена

## Мужские

Авар, Гевар, Дамота, Коис, Ласкав, Сенесав, Тохис, Хапах

## Женские

Анет, Бес, Идим, Ленет, Моким, Негет, Сийвет

## Фамилии

Кор Марак, Лоуми Харр, Мок Куо Харр, Уорав Тарак`,
  source: {
    id: SOURCE_SCAG,
    page: 112,
  },
}

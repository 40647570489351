const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_FFOLK} = require('./../../../pcSubraceIdList')
const {SOURCE_SCAG} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_FFOLK,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: `ффолк`,
      [GENDER_FEMALE]: `ффолка`,
    },
    dual: {
      [GENDER_MALE]: `ффолка`,
      [GENDER_FEMALE]: `ффолки`,
    },
    plural: `ффолки`,
  },
  nameEn: `Ffolk`,
  description: `Ффолки с Островов Муншае происходят от тетирских поселенцев, прибывших на острова тысячу лет назад. Ффолки глубоко уважают природу и по большей части являются фермерами, поклоняющимися богине, которую они называют Мать-Земля, придерживаясь старых друидических обрядов. Ффолкские корабелы высоко ценятся, доказав свою способность строить крепкие корабли, способные переносить все тяготы хождения по бурным окрестным морям.

# Ффолкские имена

## Мужские

Артур, Берн, Колин, Манфред, Тристан

## Женские

Алисия, Йенифер, Меридит, Оливия, Элейна

## Фамилии

Арчер, Гарет, Лид, Кендрик, Морган, Уотерс.`,
  source: {
    id: SOURCE_SCAG,
    page: 112,
  },
}

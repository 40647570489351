import generateRealGender from '@/utils/nameGeneration/generateRealGender'
import Name from '@/constructors/Name'
import { PC_RACE_HUMAN } from '@/constants/pcRaceIdList'

import nameListFemale from "./dict/names_female"
import nameListMale from "./dict/names_male"

import generateFirstNameHumanCommon from "../../utils/generateFirstNameHumanCommon"

const generateUlutiun = generateFirstNameHumanCommon(nameListFemale, nameListMale)

export default ({genderId: genderIdInput }) => {
  const genderId = generateRealGender(genderIdInput)

  return new Name({
    firstName: generateUlutiun(genderId),
    genderId,
    raceId: PC_RACE_HUMAN,
  })
}

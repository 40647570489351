const {PC_RACE_AASIMAR} = require ('./../../../pcRaceIdList')
const {SOURCE_VGTM} = require ('./../../../sourceList')
const {PARAM_WIT} = require ('./../../../paramList')
const { GENDER_FEMALE, GENDER_MALE } = require ('./../../../genderList')
const {PC_SUBRACE_AASIMAR_PROTECTOR} = require('./../../../pcSubraceIdList')

module.exports = {
  id: PC_SUBRACE_AASIMAR_PROTECTOR,
  parentRaceId: PC_RACE_AASIMAR,
  name: {
    singular: {
      [GENDER_MALE]: 'аасимар-защитник',
      [GENDER_FEMALE]: 'аасимарка-защитница',
    },
    dual: {
      [GENDER_MALE]: 'аасимара-защитника',
      [GENDER_FEMALE]: 'аасимарки-защитницы',
    },
    plural: 'аасимары-защитники',
  },
  nameShort: {
    singular: {
      [GENDER_MALE]: 'защитник',
      [GENDER_FEMALE]: 'защитница',
    },
    dual: {
      [GENDER_MALE]: 'защитника',
      [GENDER_FEMALE]: 'защитницы',
    },
    plural: 'защитники',
  },
  nameEn: `Protector Aasimar`,
  description: [
    `Аасимар-защитник наполнен силой добра чтобы защищать слабых, искоренять зло где бы то ни было, и неусыпно стоять на пути тьмы. С юных лет аасимар-защитник получает советы и указания, которые побуждают его противостоять злу.

# Увеличение характеристик

Мудрость увеличивается на 1.

# Сияющая душа

Начиная с 3 уровня, Вы можете действием высвободить божественную энергию внутри себя, заставляя свои глаза мерцать, а два светящихся, бестелесных крыла вырасти у Вас за спиной.

Ваше превращение длится 1 минуту или пока Вы не закончите его бонусным действием. Во время превращения у Вас есть скорость полета 30 футов, и раз в ход Вы можете нанести дополнительный урон излучением одной цели, когда Вы наносите ей урон атакой или заклинанием. Дополнительный урон излучением равен Вашему уровню.

Использовав эту способность, Вы не можете использовать её снова, пока не завершите длинный отдых.`,
    {
      header: ``,
      text: ``,
      source: {
        id: SOURCE_VGTM,
        page: 107,
      },
    },
  ],
  paramBonuses: {
    [PARAM_WIT]: 1,
  },
  source: {
    id: SOURCE_VGTM,
    page: 107,
  },
}

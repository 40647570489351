import React, { PureComponent } from "react"
import PropTypes from "prop-types"

import enhanceTypeCollectionWithVisibility from './utils/enhanceTypeCollectionWithVisibility'

import ResultView from './components/ResultView'

import ContentGeneratorComponent from './ContentGeneratorComponent'

class ContentGeneratorContainer extends PureComponent {
  state = {
    generatedCount: 0,
    result: '',
    savedItemList: [],
    typeCollection: {},
  }

  componentDidMount() {
    const {typeList} = this.props

    const typeCollection = enhanceTypeCollectionWithVisibility(
      typeList.reduce(
        (summ, item) => ({
          ...summ,
          [item.type]: {
            ...item,
            chosen: 'chosen' in item
              ? item.chosen
              : item.list[0].value
          },
        }),
        {}
      )
    )

    this.setState({typeCollection})
  }

  onChangeRadio = type => chosen => {
    const {typeCollection: typeCollectionPrev} = this.state
    const typeCollection = enhanceTypeCollectionWithVisibility({
      ...typeCollectionPrev,
      [type]: {
        ...typeCollectionPrev[type],
        chosen,
      },
    })

    this.setState({typeCollection})
  }

  onGenerate = () => {
    const {typeCollection, savedItemList, generatedCount, result: oldResult} = this.state
    const {generate, saveItemsCount} = this.props

    const params = Object.keys(typeCollection).reduce(
      (summ, type) => ({
        ...summ,
        [type]: typeCollection[type].chosen,
      }),
      {}
    )

    const result = generate(params)
    const newCount = generatedCount + 1

    this.setState({
      result,
      generatedCount: newCount,
      savedItemList: saveItemsCount
        ? [
          {
            result: oldResult,
            key: newCount
          },
          ...savedItemList.slice(0, Math.max(savedItemList.length, saveItemsCount) - 1),
        ]
        : savedItemList,
    })
  }

  render() {
    const {ResultRenderer} = this.props
    const {result, typeCollection, savedItemList} = this.state
    const typeList = Object
      .values(typeCollection)
      .filter(
        ({visible}) => visible
      )
      .map(
        ({list, ...rest}) => ({
            ...rest,
            list: list.filter(({visible}) => visible)
        })
      )

    return (
      <ContentGeneratorComponent
        savedItemList={savedItemList}
        onChangeRadio={this.onChangeRadio}
        onGenerate={this.onGenerate}
        result={result}
        typeList={typeList}
        ResultRenderer={ResultRenderer}
      />
    )
  }
}

ContentGeneratorContainer.propTypes = {
  typeList: PropTypes.array,
  generate: PropTypes.func.isRequired,
  ResultRenderer: PropTypes.element,
}

ContentGeneratorContainer.defaultProps = {
  typeList: [],
  ResultRenderer: ResultView,
}

export default ContentGeneratorContainer

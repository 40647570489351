const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_CHONDATAN} = require('./../../../pcSubraceIdList')
const {SOURCE_PHB} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_CHONDATAN,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: 'чондатанец',
      [GENDER_FEMALE]: 'чондатанка',
    },
    dual: {
      [GENDER_MALE]: 'чондатанца',
      [GENDER_FEMALE]: 'чондатанки',
    },
    plural: 'чондатанцы',
  },
  description: `Чондатанцы — стройный, смуглый народ с коричневыми, от светлых до почти чёрных, волосами. Они высокие, у них зелёные или карие глаза, но это применимо не ко всем. Чондатанцы преобладают в центральных землях Фаэруна, вокруг Внутреннего моря.

# Имена чондатанцев

## Мужские

Горстаг, Грим, Дарвин, Дорн, Маларк, Морн, Рэндал, Стедд, Хельм, Эвендур

## Женские

Арвин, Джессаиль, Керри, Лурин, Мири, Рован, Тесселе, Шандри, Эсвель

## Фамилии

Бакмэн, Грэйкасл, Дандрэгон, Толстаг, Эвенвуд, Эмблкроун `,
  source: {
    id: SOURCE_PHB,
    page: 32,
  },
}

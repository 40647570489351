import React from 'react'

import AdsPlacer from '@/components/AdsPlacer'

import './AdsAtGeneratorStyles.less'

// Баннер РСЯ в генераторах
const AdsAtGenerator = () => (
  <AdsPlacer
    blockId='R-A-2421058-8'
    className='AdsAtGenerator'
    selfReload
  />
)

export default AdsAtGenerator
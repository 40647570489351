const phbSizeSource = require ('./../../constants/phbSizeSource')
const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_STR} = require ('./../../../paramList')
const {PC_RACE_DWARF} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_DWARF_MOUNTAIN} = require('./../../../pcSubraceIdList')
const {SOURCE_PHB, SOURCE_SCAG} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_DWARF_MOUNTAIN,
  parentRaceId: PC_RACE_DWARF,
  name: {
    singular: {
      [GENDER_MALE]: 'горный дварф',
      [GENDER_FEMALE]: 'горная дварфийка',
    },
    dual: {
      [GENDER_MALE]: 'горных дварфа',
      [GENDER_FEMALE]: 'горные дварфийки',
    },
    plural: 'горные дварфы',
  },
  nameAlt: `щитовые дварфы`,
  nameEn: `Mountain Dwarfs`,
  nameEnAlt: `Shield Dwarfs`,
  nameShort: {
    singular: {
      [GENDER_MALE]: 'горный',
      [GENDER_FEMALE]: 'горная',
    },
    dual: {
      [GENDER_MALE]: 'горных',
      [GENDER_FEMALE]: 'горные',
    },
    plural: 'горные',
  },
  description: [
    {
      header: `Щитовые дварфы`,
      text: `Родина щитовых дварфов — северный Фаэрун, где древние королевства дварфов существовали на Севере, в Дамаре, Импилтуре, Ваасе, Васте и западном Сердцеземье. Самый известный из старых городов щитовых дварфов — Цитадель Адбар — лежит к северо-востоку от Серебристой Луны. Многие из этих королевств столетиями переходили из рук в руки, то захваченные очередными врагами, то вновь отвоёванные дварфами. Из поколения в поколение живущие в состоянии почти непрерывной войны, щитовые дварфы — стойкий и выносливый народ, они не спешат доверять другим, у них долгая память и длинный список врагов. Более консервативные из них желают сохранить свои традиции и остатки своего народа изолированными от постороннего влияния, в безопасности от захватчиков за толстыми стенами из камня. Но щитовые дварфы с приключенческим складом ума с увлечением исследуют мир, лежащий за пределами их древних королевств. 

Щитовые дварфы имеют все расовые черты горных дварфов, описанных в Книге Игрока. Кожа у них, как правило, светлая, глаза — зелёные, карие или серебристо-голубые, а волосы — светлые, рыжие или коричневые. Мужчины обычно носят полные бороды и усы.

Щитовые дварфы славятся мастерством своих ремесленников, особенно искусных в работе с металлом и камнем. Они больше заботятся о прочности своих изделий, чем об их украшениях и позолоте, которые так любят их собратья — золотые дварфы. Работы щитовых дварфов очень надёжны и долговечны, и, когда мастер ставит на созданный им шедевр своё именное клеймо, его имя фактически обретает бессмертие.`,
      source: {
        id: SOURCE_SCAG,
        page: 104,
      },
    },
    {
      header: `Горные дварфы`,
      text: `Будучи горным дварфом, Вы сильны, выносливы и приспособленны к жизни в суровой местности. Вы довольно высоки (по дварфийской мерке), и скорее светлокожи. Щитовые дварфы из северного Фаэруна, а также правящий клан хиларов и благородный клан деваров из Саги о Копье — всё это горные дварфы.

# Увеличение характеристик

Сила увеличивается на 2.

# Владение доспехами дварфов

Вы владеете лёгкими и средними доспехами.`,
      source: {
        id: SOURCE_PHB,
        page: 21,
      },
    },
  ],
  size: {
    tall: {
      base: 4 * 12,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
    weight: {
      base: 130,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
    source: phbSizeSource,
  },
  paramBonuses: {
    [PARAM_STR]: 2,
  },
  source: {
    id: SOURCE_PHB,
    page: 21,
  },
}

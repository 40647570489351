import _ from "lodash";

import Name from '@/constructors/Name'
import { PC_RACE_GOLIATH } from '@/constants/pcRaceIdList'
import { GENDER_FEMALE } from "@/constants/genderList";
import generateRealGender from '@/utils/nameGeneration/generateRealGender'

import nameList from "./dict/nameList"
import nicknameList from "./dict/nicknameList"
import clanNameList from "./dict/clanNameList"

export default ({genderId: genderIdInput}) => {
  const genderId = generateRealGender(genderIdInput)

  return new Name({
    firstName: _.sample(nameList),
    nickName: _.sample(nicknameList)[Number(genderId === GENDER_FEMALE)],
    clan: _.sample(clanNameList),
    genderId,
    raceId: PC_RACE_GOLIATH,
  })
}

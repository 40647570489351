const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_SHOU} = require('./../../../pcSubraceIdList')
const {SOURCE_PHB} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_SHOU,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: 'шу',
      [GENDER_FEMALE]: 'шу',
    },
    dual: {
      [GENDER_MALE]: 'шу',
      [GENDER_FEMALE]: 'шу',
    },
    plural: 'шу',
  },
  nameEn: 'Shou',
  description: `Шу — наиболее многочисленная и могущественная раса в Кара-Туре, далеко на востоке Фаэруна. У них желтовато-бронзовый оттенок кожи, с тёмными волосами и чёрными глазами. Фамилии шу обычно стоят перед именами.

# Имена шу

## Мужские

Ан, Вэнь, Лонг, Лянь, Менг, Он, Фай, Цзюн, Цзянь, Чен, Чи, Шань, Шуй

## Женские

Бай, Ксяо, Лей, Мей, Тай, Цзя, Чао, Шуй

## Фамилии

Вань, Као, Кунг, Лао, Линг, Мей, Пинь, Сум, Тань, Хуан, Чиень, Шин`,
  source: {
    id: SOURCE_PHB,
    page: 32,
  },
}

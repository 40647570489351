const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require ('./../../../sourceList')
const {
  SPELL_ENLARGE_REDUCE,
  SPELL_INVISIBILITY,
} = require('./../../../spellIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require ('./../../../genderList')
const {CREATURE_MIND_FLAYER} = require ('./../../../creatureIdList')
const {PARAM_STR} = require ('./../../../paramList')
const {PC_RACE_DWARF} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_DWARF_GRAY} = require('./../../../pcSubraceIdList')

module.exports = {
  id: PC_SUBRACE_DWARF_GRAY,
  parentRaceId: PC_RACE_DWARF,
  name: {
    singular: {
      [GENDER_MALE]: 'двергар',
      [GENDER_FEMALE]: 'двергарка',
    },
    dual: {
      [GENDER_MALE]: 'двергарка',
      [GENDER_FEMALE]: 'двергарки',
    },
    plural: 'двергары',
  },
  nameAlt: [
    'серые дварфы',
    'дуэргары',
  ],
  nameEn: `Duergars`,
  nameEnAlt: `Gray Dwarfs`,
  description: [
    {
      text: `В глубоких городах Подземья живут двергары, или серые дварфы. Они совершают скрытные и жестокие налёты на поверхность, а пленных рабов продают другим расам Подземья. Серые дварфы обладают врождённой способностью становиться невидимыми и временно приобретать гигантские размеры. `,
      source: {
        id: SOURCE_PHB,
        page: 21,
      },
    },
    {
      header: 'Двергары',
      text: `Порабощённые жуткими [свежевателями разума](CREATURE:${CREATURE_MIND_FLAYER}) двергары, обретя свободу, стали грубыми и мрачными. Их скрытность и надменный нрав известны всему Подземью.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      text: `Серые дварфы или двергары живут в глубинах Подземья. Спустившись под землю гораздо глубже других дварфов, они на долгие тысячелетия попали в рабство к пожирателям разума. Теперь же, отвоевав, наконец, собственную свободу, эти мрачные пепельнокожие дварфы сами захватывают рабов, превратившись в таких же жестоких тиранов, как их бывшие хозяева.

Двергары внешне похожи на других дварфов, но более худые и жилистые, у них чёрные глаза и лысая голова, а мужчины отращивают длинные, растрёпанные серые бороды.

Превыше всего двергары ставят тяжёлый труд. В их культуре не одобряется демонстрация каких-либо эмоций, за исключением мрачной решимости или гнева, но, случается, они выглядят довольными во время работы. Они питают типичное для дварфов уважение к порядку и традициям и известны своим безупречным мастерством, но созданные ими вещи чисто утилитарны и лишены эстетической или художественной ценности.

Из-за подозрительности и некоторой ограниченности взглядов, свойственных этой расе, двергары редко становятся приключенцами и ещё реже странствуют по поверхности мира. Как правило, свои подземные города покидают только изгнанники. Если Вы хотели бы играть персонажем серым дварфом, узнайте у вашего Мастера Подземелий, возможно ли это.`,
      source: {
        id: SOURCE_SCAG,
        page: 105,
      },
    },
    {
      header: `Особенности подрасы двергаров`,
      text: `Подраса двергаров имеет все особенности дварфов из Книги Игрока, плюс черты подрасы, приведенные ниже.

# Увеличение характеристик

Сила увеличивается на 1.

# Превосходное тёмное зрение

Радиус Вашего тёмного зрения равен 120 футам.

# Дополнительный язык

Вы можете говорить, читать и писать на Подземном.

# Двергарская стойкость

Вы получаете преимущество на испытания против иллюзий и состояний очарования и паралича.

# Магия двергаров

Достигнув 3-го уровня, Вы можете один раз сотворить заклинание [Увеличение/Уменьшение](SPELL:${SPELL_ENLARGE_REDUCE}) на себя, используя только версию заклинания, связанную с увеличением.

По достижении 5-го уровня Вы можете один раз сотворить заклинание [Невидимость](SPELL:${SPELL_INVISIBILITY}).

Вам не требуются материальные компоненты для сотворения этих заклинаний. Вы не можете использовать эти способности под прямым солнечным светом, но, как только способность успешно применена, солнечный свет более не накладывает на неё никакого эффекта или ограничения. Вы снова получаете возможность творить заклинания с помощью этой способности после окончания длинного отдыха. Интеллект — заклинательная характеристика для сотворения этих заклинаний.

# Чувствительность к солнечному свету

Вы получаете помеху на броски атаки и на проверки Мудрости (Восприятия) опирающиеся на зрение, когда Вы, Ваша цель или то, что Вы пытаетесь рассмотреть, находится под прямым солнечным светом.`,
      source: {
        id: SOURCE_SCAG,
        page: 105,
      },
    },
  ],
  size: {
    tall: {
      base: 3 * 12 + 10,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
    weight: {
      base: 100,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 1,
  },
  source: {
    id: SOURCE_SCAG,
    page: 105,
  },
}

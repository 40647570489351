const {SOURCE_MOOT} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {PC_RACE_SATYR} = require ('./../../pcRaceIdList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {SETTING_THEROS} = require ('./../../settingList')
const {
  PARAM_CHA,
  PARAM_DEX,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_SATYR,
  isReady: false,
  name: creatureTypeCollection[PC_RACE_SATYR].name,
  nameEn: creatureTypeCollection[PC_RACE_SATYR].nameEn,
  note: creatureTypeCollection[PC_RACE_SATYR].note,
  description: creatureTypeCollection[PC_RACE_SATYR].description,
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 8,
      coefDice: {
        diceCount: 2,
        diceType: 8,
      },
    },
    weight: {
      base: 100,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_CHA]: 2,
    [PARAM_DEX]: 1,
  },
  setting: SETTING_THEROS,
  source: {
    id: SOURCE_MOOT,
    page: 25,
  },
}

import _ from "lodash";

import Name from "@/constructors/Name"
import { PC_RACE_DRAGONBORN } from "@/constants/pcRaceIdList"
import generateRealGender from '@/utils/nameGeneration/generateRealGender'

import generateNameListDragonborn from "./utils/generateNameListDragonborn"
import generateNickNameListDragonborn from "./utils/generateNickNameListDragonborn"
import clanNameList from "./dict/names_clan"

export default ({genderId: genderIdInput}) => {
  const genderId = generateRealGender(genderIdInput)

  return new Name({
    firstName: _.sample(generateNameListDragonborn(genderId)),
    nickName: _.sample(generateNickNameListDragonborn(genderId)),
    clan: _.sample(clanNameList),
    genderId,
    raceId: PC_RACE_DRAGONBORN,
  })
}

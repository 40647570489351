const {
  SOURCE_ERRATA_VGTM,
  SOURCE_VGTM,
} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_GOLIATH} = require ('./../../pcRaceIdList')
const {
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')

const {
  PARAM_STR,
  PARAM_CON,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_GOLIATH,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_GOLIATH].name,
  nameEn: creatureTypeCollection[PC_RACE_GOLIATH].nameEn,
  note: creatureTypeCollection[PC_RACE_GOLIATH].note,
  description: [
    ...creatureTypeCollection[PC_RACE_GOLIATH].description,
    {
      header: 'Особенности голиафов',
      text: `Голиафы разделяют несколько общих особенностей.

# Увеличение характеристик

Сила увеличивается на 2.

Телосложение увеличивается на 1.

# Возраст

Продолжительность жизни голиафов сопоставима с человеческой. Они достигают зрелости во второй половине своего второго десятка, и обычно живут меньше века.

# Мировоззрение

У общества голиафа, с его ясными ролями и задачами, есть сильная склонность к законному мировоззрению. Чувство справедливости голиафов, в сочетании с акцентом на самодостаточность и личную ответственность, склоняет их к нейтралитету.

# Размер

Голиафы ростом от 7 до 8 футов и весят от 280 до 340 фунтов. Ваш размер — Средний.

# Скорость

Вы обладаете базовой скоростью ходьбы 30 футов. Прирождённый Атлет. Вы владеете навыком Атлетика.

# Выносливость камня

Вы можете сосредоточиться, чтобы иногда не обращать внимание на рану.

Получая урон, Вы можете реакцией уменьшить его на к12 + модификатор Телосложения. После того, как Вы используете эту способность, Вы не можете использовать её снова, пока не завершите короткий или длинный отдых.

# Мощное телосложение

Вы считаетесь на один размер больше когда определяется Ваша грузоподъемность и вес, который Вы можете толкать, тянуть или поднимать от земли.

# Рождённый в горах

У Вас есть сопротивление к урону холодом. Вы привычны к большой высоте, включая высоту больше 20 000 футов.

# Языки

Вы можете говорить, читать и писать на Всеобщем и Великаньем языках.`,
      columnCount: 2,
      source: [
        {
          id: SOURCE_VGTM,
          page: 111,
        },
        {
          id: SOURCE_ERRATA_VGTM,
          title: 'Добавлено сопротивление к урону холодом',
          page: 1,
        },
      ],
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 6 * 12 + 2,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 200,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 2,
    [PARAM_CON]: 1,
  },
  noHairs: true,
  setting: [
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
  ],
  source: {
    id: SOURCE_VGTM,
    page: 111,
  },
}

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_STR} = require ('./../../../paramList')
const {PC_RACE_GENASI} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_GENASI_FIRE} = require('./../../../pcSubraceIdList')
const {SOURCE_EE} = require ('./../../../sourceList')
const {
  SPELL_BURNING_HANDS,
  SPELL_PRODUCE_FLAME,
} = require ('./../../../spellIdList')
const {CREATURE_EFREETI} = require ('./../../../creatureIdList')

module.exports = {
  id: PC_SUBRACE_GENASI_FIRE,
  parentRaceId: PC_RACE_GENASI,
  name: {
    singular: {
      [GENDER_MALE]: 'генази огня',
      [GENDER_FEMALE]: 'генази огня',
    },
    dual: {
      [GENDER_MALE]: 'генази огня',
      [GENDER_FEMALE]: 'генази огня',
    },
    plural: 'генази огня',
  },
  nameEn: `Fire Genasi`,
  description: [
    {
      header: `Генази огня`,
      text: `Будучи огненным генази, Вы унаследовали изменчивое настроение и острый ум [ифрита](CREATURE:${CREATURE_EFREETI}). Вы нетерпеливы и делаете поспешные выводы. Вместо того чтобы скрывать свой необычный вид, Вы демонстрируете его.

Почти все генази огня лихорадочно горячи, как будто внутри них горит огонь, а впечатление усиливает их пламенно-красного, угольно-чёрного или пепельного цвета кожа. Головы тех, что больше похожи на людей, покрыты огненно-рыжими волосами, которые извиваются при сильных эмоциях, но самые экзотичные те, у которых на головах танцует настоящее пламя. Голос огненного генази может звучать как треск костра, а их глаза вспыхивают от злости. От некоторых из них исходит слабый запах серы.`,
      source: {
        id: SOURCE_EE,
        page: 9,
      },
    },
    {
      header: `Особенности генази огня`,
      text: `# Увеличение характеристик

Интеллект увеличивается на 1.

# Тёмное зрение

Вы можете видеть в тусклом свете в пределах 60 футов, как если бы это был яркий свет, и в темноте, как будто это тусклый свет. Ваша связь со Стихийным Планом Огня сделала Ваше тёмное зрение особенным: всё, что находится в темноте, для Вас выглядит как оттенки красного цвета.

# Сопротивление огню

У Вас есть сопротивление к урону огнём

# Выброс пламени

Вам известен заговор [Сотворение пламени](SPELL:${SPELL_PRODUCE_FLAME}). Достигнув 3 уровня, Вы можете один раз сотворять заклинание [Огненные ладони](SPELL:${SPELL_BURNING_HANDS}) как заклинание 1 уровня. Вы не сможете вновь сотворить это заклинание этой способностью, пока не окончите длинный отдых. Заклинательной характеристикой для этих заклинаний является Телосложение.`,
      source: {
        id: SOURCE_EE,
        page: 9,
      },
    },
  ],
  paramBonuses: {
    [PARAM_STR]: 1,
  },
  source: {
    id: SOURCE_EE,
    page: 9,
  },
}

import mapNames from './../utils/mapNames'

export default [
  'Варенье',
  'Весельчак',
  'Капуста',
  'Козодой',
  'Котелок',
  'Котёнок',
  'Лесовик',
  'Лугодуг',
  'Лужа',
  'Медяк',
  'Пибоди',
  'Репейник',
  'Снобидль',
  'Чертополох',
  'Ягода',
  ['Вверхтормашкин', 'Вверхтормашкина'],
  ['Везучий', 'Везучая'],
  ['Великан', 'Великанша'],
  ['Весельчак', 'Веселушка'],
  ['Глупыш', 'Глупышка']
  ['Дурачок', 'Дурочка'],
  ['Жирдяй', 'Жируха'],
  ['Здоровяк', 'Здоровячка'],
  ['Крепыш', 'Крепышка'],
  ['Ловкач', 'Ловкачка'],
  ['Подтяжкин', 'Подтяжкина'],
  ['Похлёбкин', 'Похлёбкина'],
  ['Рыболов', 'Рыболовка'],
  ['Тростничник', 'Тростничница'],
  ['Умник', 'Умница'],
].map(mapNames)

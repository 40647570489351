const {SOURCE_GGTR} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_VEDALKEN} = require ('./../../pcRaceIdList')
const {SETTING_RAVNICA} = require ('./../../settingList')

const {
  PARAM_INT,
  PARAM_WIT,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_VEDALKEN,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_VEDALKEN].name,
  nameEn: creatureTypeCollection[PC_RACE_VEDALKEN].nameEn,
  description: [
    ...creatureTypeCollection[PC_RACE_VEDALKEN].description,
    {
      header: 'Особенности ведалкенов',
      text: `Ваш персонаж-ведалкен обладает следующими особенностями.

# Увеличение характеристик

Интеллект увеличивается на 2.

Мудрость увеличивается на 1.

# Возраст

Ведалкены взрослеют медленнее, чем люди, достигая зрелости к 40 годам. Их обычная продолжительность жизни — 350 лет, при этом некоторые доживают до 500 лет.

# Мировоззрение

Ведалкены обычно принципиальны и незлы.

# Размер

Высокий и тонкие, ведалкены обычно ростом от 6 до 6½ футов (от 182 до 198 см) и обычно весят меньше 200 фунтов (90 кг). Ваш размер — Средний.

# Скорость

Ваша базовая скорость ходьбы составляет 30 футов.

# Бесстрастность ведалкенов

Вы получаете преимущество на все испытания Интеллекта, Мудрости и Харизмы.

# Неутомимая точность

Вы владеете одним из навыков по Вашему выбору

* Анализ
* Выступление
* История
* Ловкость рук
* Магия
* Медицина

Кроме того, Вы владеете одним инструментов по Вашему выбору.

Когда Вы совершаете проверку характеристики, используя выбранный навык или инструмент, добавьте к4 к результату.

# Частичная амфибийность

Поглощая кислород кожей, Вы можете дышать под водой до 1 часа. Достигнув этого предела, Вы не сможете снова использовать эту способность, пока не закончите длинный отдых.

# Языки

Вы можете говорить, читать и писать на Всеобщем, языке ведалкенов и одном другом языке по Вашему выбору.`,
      columnCount: 2,
      source: {
        id: SOURCE_GGTR,
        page: 22,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 5 * 12 + 4,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 110,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_INT]: 2,
    [PARAM_WIT]: 1,
  },
  age: {
    adult: 40,
    max: 500,
  },
  setting: SETTING_RAVNICA,
  source: {
    id: SOURCE_GGTR,
    page: 22,
  },
}

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_ARKAIUN} = require('./../../../pcSubraceIdList')
const {SOURCE_SCAG} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_ARKAIUN,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: `аркаюн`,
      [GENDER_FEMALE]: `аркаюнка`,
    },
    dual: {
      [GENDER_MALE]: `аркаюна`,
      [GENDER_FEMALE]: `аркаюнки`,
    },
    plural: `аркаюны`,
  },
  nameEn: `Arkaiun`,
  description: `Низкорослые, смуглые и черноволосые, аркаюны обитают главным образом в Дамбрате, а также в Халруаа и Шаар. Многие аркаюны живут в рабстве под игом дроу, после того как столетия назад военная кампания их народа против тёмных эльфов провалилась. Это привело к разрушению королевства Аркаюн в Дамбрате.

# Имена аркайун

## Мужские

Амбрил, Зельтейбар, Зэймар, Ривон, Хоун

## Женские

Глоурис, Зраэла, Зэймара, Мэйве, Севаэра

## Фамилии

Виндэль, Ларэндо, Мристар`,
  source: {
    id: SOURCE_SCAG,
    page: 111,
  },
}

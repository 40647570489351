const {SOURCE_ERFTLW} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_CHANGELING} = require ('./../../pcRaceIdList')
const {SETTING_EBERRON} = require ('./../../settingList')

const {
  PARAM_ANY,
  PARAM_CHA,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_CHANGELING,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_CHANGELING].name,
  nameEn: creatureTypeCollection[PC_RACE_CHANGELING].nameEn,
  sizeType: SIZE_MEDIUM,
  note: {
    text: `Давным-давно жила женщина по имени Джес, и у неё было сто детей. Её враги сговорились против неё и поклялись убить её детей. Джес умоляла Владык о помощи, но их единственным ответом были ветер и дождь. В пучине отчаяния одинокий путник взял её за руку.

«Я буду защищать твоих детей, если они пойдут по моему пути. Пусть они блуждают по миру. Их могут пугаться и сторониться, но они никогда не будут уничтожены».

Джес согласилась, и путешественник дал ей свой плащ. Когда она накинула его на своих детей, их старые лица растаяли, и они смогли стать кем только захотели. Так и повелось с тех пор. Хотя все избегают детей Джес, дар Странника по-прежнему защищает их.`,
    author: 'Шанс, подменыш-жрец',
  },
  description: [
    `Подменыши могут изменять свои формы силой мысли. Многие подменыши используют этот дар как форму художественного и эмоционального выражения. Это также бесценный инструмент для мошенников, шпионов и других обманщиков. Это заставляет многих людей относиться к подменышам с подозрением.`,
    {
      header: 'Скрытый народ',
      text: `Где бы ни жили люди, там обитают и подменыши. Вопрос лишь в том, известно ли об их присутствии?

Подменыши рождаются на одном из трёх путей. Некоторые выросли в стабильных сообществах, где подменыши верны своей природе и открыто общаются с окружающими их людьми. Другие — сироты, воспитанные другими расами, находящие свой путь в мире, никогда не узнавая других, подобных себе. Третьи являются частью кланов кочевых подменышей, разбросанных среди Пяти наций, семей, скрывающих свою истинную природу от однокожих. Некоторые кланы имеют убежища в крупных городах и общинах, но большинство предпочитают блуждать по непредсказуемому пути бога, известного как Странник.

Создавая подменыша-приключенца, учитывайте отношения персонажа с окружающими его людьми. Скрывает ли персонаж свою истинную природу подменыша? Принимает ли он её? Связан ли он с другими подменышами или же он одинок и ищет товарищей?`,
      source: {
        id: SOURCE_ERFTLW,
        page: 18,
      },
    },
    {
      header: 'Маски и личности',
      text: `В своей истинной форме подменыши бледны, с бесцветными глазами и серебристо-белыми волосами. Редко можно увидеть подменыша в такой форме, поскольку обычно подменыш меняет свою форму так же просто, как другие переодеваются.

Случайная форма, созданная спонтанно, без глубины или истории, называется _маской_. Маску можно использовать для выражения настроения или для определённой цели, а затем её можно не использовать больше никогда.

Однако у многих подменышей складывается более глубокая _личность_. Со временем они выстраивают индивидуальность, создавая личность с историей и убеждениями. Эта сфокусированная индивидуальность помогает подменышу подчеркнуть конкретный навык или эмоцию. У приключенца-подменыша могут быть личности для многих ситуаций, включая переговоры, расследование и бой.

Личности могут быть общими для нескольких подменышей; в сообществе может быть три целителя, но тот из них, кто дежурит, примет образ Тека, доброго старого медика. Личности могут даже передаваться в семье, что позволяет молодому подменышу воспользоваться контактами, установленными предыдущими пользователями конкретной личности.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 19,
      },
    },
    {
      header: 'Имена подменышей',
      text: `Подменыш может использовать разные имена для каждой маски и личности и принимать новые имена так же легко, как меняет лица.

Истинные имена подменышей обычно простые и односложные; однако в их именах часто есть акценты, которые выражаются в изменении формы, чего, вероятно, однокожие никогда не заметят.

Например, два подменыша могут иметь имя Джин, но один — Джин-с-ярко-голубыми-глазами, а другой — Джин-с-золотыми-ногтями. У подменышей своеобразные отношения к полу, и они часто видят в нём ещё одну особенность, которую можно изменять, как и все прочие.

# Имена подменышей

Бин, Вил, Джин, Докс, Кас, Лам, Мас, Никс, Онн, От, Пайк, Раз, Сим, Тукс, Фай, Хэрс, Юг.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 19,
      },
    },
    {
      header: 'Особенности подменышей',
      text: `Ваш персонаж-подменыш обладает следующими врождёнными особенностями.

# Увеличение характеристик

Харизма увеличивается на 2/

Одна характеристика на Ваш выбор увеличивается на 1.

# Возраст

Подменыши взрослеют значительно быстрее людей, но имеют схожую продолжительность жизни — около столетия или меньше. Хотя подменыши могут легко скрывать свой возраст, старение влияет на них так же, как и на людей.

# Мировоззрение

Подменыши тяготеют к прагматичной нейтральности, хотя некоторые из них выбирают зло.

# Размер

Ваш размер — Средний.

Чтобы случайно определить Ваш рост и вес, определите модификаторы.

## Модификатор размера

2к4 

## Рост

5 футов + 1 дюйм + Модификатор размера (в дюймах)

## Вес

115 фунтов + (2к4 × Модификатор размера)

# Скорость

У Вас скорость ходьбы 30 футов.

# Перевёртыш

Вы можете действием изменить свою внешность и голос. Вы определяете изменения, включая цвет кожи, длину волос и пол. Вы также можете отрегулировать свой рост и вес, но не настолько, чтобы изменился Ваш размер. Вы можете выглядеть как представитель другой расы, но ни одна из Ваших игровых характеристик не изменится. Вы не можете дублировать внешний вид существа, которого никогда не видели, и Вы должны принять форму, имеющую такое же базовое расположение конечностей, что и у Вас. Эта способность не меняет Вашу одежду и снаряжение.

Вы остаетесь в новой форме, пока действием не вернётесь в свою истинную форму, или пока не умрёте.

# Инстинкты подменыша

Вы получаете владение двумя навыками по Вашему выбору из следующего списка

* Запугивание
* Обман
* Проницательность
* Убеждение

# Языки

Вы можете говорить, читать и писать на Всеобщем и двух языках по Вашему выбору.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 19,
      },
    },
  ],
  size: {
    tall: {
      base: 5 * 12 + 1,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
    weight: {
      base: 115,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
    source: {
      id: SOURCE_ERFTLW,
      page: 19,
    },
  },
  paramBonuses: {
    [PARAM_ANY]: 1,
    [PARAM_CHA]: 2,
  },
  setting: SETTING_EBERRON,
  source: {
    id: SOURCE_ERFTLW,
    page: 19,
  },
}

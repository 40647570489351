const {SOURCE_TTP} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_TORTLE} = require ('./../../pcRaceIdList')
const {
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')
const {
  PARAM_STR,
  PARAM_WIT,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_TORTLE,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_TORTLE].name,
  nameEn: creatureTypeCollection[PC_RACE_TORTLE].nameEn,
  note: creatureTypeCollection[PC_RACE_TORTLE].note,
  description: [
    ...creatureTypeCollection[PC_RACE_TORTLE].description,
    {
      header: 'Особенности тортлов',
      text: `Ваш персонаж-тортл имеет особенности, которые позволяют ему справляться с опасностями дикой природы.

# Увеличение характеристик

Сила увеличивается на 2.

Мудрость увеличивается на 1.

# Возраст

Новорождённые тортлы ползают, пока не научатся ходить на двух ногах. Они достигают зрелости к 15 годам и живут около 50 лет. 

# Мировоззрение

Жизни тортлов обычно упорядочены и полны ритуалов. Чем старше тортл, тем больше у него накапливается обычаев и привычек.

Большинство из тортлов принципиально-добрые. Хотя некоторые из них могут эгоистичными и жадными, склоняясь ко злу, но отбросить порядок ради хаоса для тортла — очень необычно.

# Размер

Рост взрослого тортла от 5 до 6 футов (от 152 до 185 сантиметров), а вес — около 450 фунтов.

Панцирь тортла весит примерно треть его веса. Ваш размер — Средний.

# Скорость

Ваша базовая скорость ходьбы — 30 футов.

# Когти

Ваши когти — природное оружие, которым можно совершать безоружные атаки.

При попадании Вы наносите рубящий урон 1к4 + Ваш модификатор Силы, вместо дробящего урона, обычного для безоружного удара.

# Задержка дыхания

Вы можете задерживать дыхание до 1 часа.

Тортлы — не прирождённые пловцы, но могут некоторое времени продержаться под водой без воздуха.

# Природный доспех

Из-за панциря и формы тела тортлы не могут носить доспехи. При этом Ваш панцирь даёт Вам достаточную защиту.

Ваш базовый КД 17 (модификатор Ловкости на него не влияет). Вы не получаете преимущест от ношения доспехов, но, используя щит, можете применять бонус щита к КД как обычно.

# Панцирная защита

Вы можете действием спрятаться в панцирь.

Пока Вы не выдвинетесь обратно, Вы получаете +4 КД и преимущество к испытаниям Силы и Телосложения. Пока Вы спрятаны в панцире, Вы считаетесь опрокинутым, Ваша скорость равна 0 и не может быть увеличена, Вы получаете помеху к испытаниям Ловкости, не можете совершать реакции.

Единственным доступным Вам действием становится выдвигание из панциря бонусным действием. 

# Инстинкт выживания

Инстинкты выживания у тортлов очень сильны.

Вы получаете владение Выживанием. 

# Языки

Вы можете говорить, читать и писать на Общем и Акване.`,
      source: {
        id: SOURCE_TTP,
        page: 4,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 8,
      coefDice: {
        diceCount: 4,
        diceType: 4,
      },
    },
    weight: {
      base: 400,
      coefDice: {
        diceCount: 1,
        diceType: 10,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 2,
    [PARAM_WIT]: 1,
  },
  age: {
    adult: 15,
    max: 50,
  },
  setting: [
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
  ],
  source: {
    id: SOURCE_TTP,
    page: 4,
  },
}

const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_BUGBEAR} = require ('./../../pcRaceIdList')
const {
  SETTING_EBERRON,
  SETTING_FORGOTTEN_REALMS,
  SETTING_GREYHAWK,
} = require ('./../../settingList')
const {
  SOURCE_ERFTLW,
  SOURCE_VGTM,
} = require ('./../../sourceList')
const {
  PARAM_STR,
  PARAM_DEX,
} = require ('./../../paramList')
const {monsterAdventurers} = require ('./../../textCommonParts')

module.exports = {
  id: PC_RACE_BUGBEAR,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_BUGBEAR].name,
  nameEn: creatureTypeCollection[PC_RACE_BUGBEAR].nameEn,
  nameAlt: creatureTypeCollection[PC_RACE_BUGBEAR].nameAlt,
  description: [
    ...creatureTypeCollection[PC_RACE_BUGBEAR].description,
    ...monsterAdventurers,
    {
      header: 'Особенности багбиров',
      text: `Ваш персонаж-багбир обладает следующими особенностями.

# Увеличение характеристик

Сила увеличивается на 2.

Ловкость увеличивается на 1.

# Возраст

Багбиры достигают зрелости к 16 годам, и живут до 80 лет.

# Мировоззрение

Багбиры ведут суровую жизнь, которая требует от каждого из них оставаться самодостаточным и независимым, даже за счёт своих собратьев. Они склонны быть хаотично-злыми.

# Размер

Багбиры ростом от 6 до 8 футов и весят от 250 до 350 фунтов. Ваш размер — Средний.

# Скорость

Ваша базовая скорость ходьбы составляет 30 футов.

# Тёмное зрение

На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Длинные конечности

Когда Вы совершаете рукопашную атаку в свой ход, Ваша досягаемость для нее на 5 футов больше, чем обычно.

# Мощное телосложение

Вы считаетесь на один размер больше, когда определяется Ваша грузоподъемность и вес, который Вы можете толкать, тянуть или поднимать.

# Скрытность

Вы владеете навыком Скрытность.

# Внезапное нападение

Если Вы застали врасплох существо и попали по нему атакой в свой первый ход в битве, атака наносит ей 2к6 дополнительного урона. Вы можете использовать эту способность только раз за бой.

# Языки

Вы можете говорить, читать и писать на Всеобщем и Гоблинском языках.`,
      columnCount: 2,
      source: {
        id: SOURCE_VGTM,
        page: 121,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 72,
      coefDice: {
        diceCount: 2,
        diceType: 12,
      },
    },
    weight: {
      base: 200,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 2,
    [PARAM_DEX]: 1,
  },
  age: {
    adult: 16,
    max: 80,
  },
  setting: [
    SETTING_EBERRON,
    SETTING_FORGOTTEN_REALMS,
    SETTING_GREYHAWK,
  ],
  source: [
    {
      id: SOURCE_VGTM,
      page: 121,
    },
    {
      id: SOURCE_ERFTLW,
      page: 26,
    },
  ],
}

const {SOURCE_VGTM} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_KENKU} = require ('./../../pcRaceIdList')
const {
  LANG_AURAN,
  LANG_PRIMORDIAL,
  LANG_COMMON,
} = require ('./../../languageIdList')
const {
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')

const {
  PARAM_DEX,
  PARAM_WIT,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_KENKU,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_KENKU].name,
  nameEn: creatureTypeCollection[PC_RACE_KENKU].nameEn,
  note: creatureTypeCollection[PC_RACE_KENKU].note,
  description: [
    ...creatureTypeCollection[PC_RACE_KENKU].description,
    {
      header: 'Особенности кенку',
      text: `Ваш персонаж-кенку обладает следующими особенностями.

# Увеличение характеристик

Ваша Ловкость увеличивается на 2.

Мудрость увеличивается на 1.

# Возраст

Кенку имеют более короткую жизнь, чем люди. Они достигают зрелости к 12 годам, и могут дожить до 60 лет.

# Мировоззрение

Кенку — хаотические существа, редко берущие на себя длительные обязательства, и они заботятся главным образом о собственной шкуре. Они как правило хаотично-нейтрального мировоззрения.

# Размер

Кенку примерно 5 футов в высоту и весят от 90 до 120 фунтов. Ваш размер — Средний.

# Скорость

Вы обладаете базовой скоростью ходьбы 30 футов.

# Мастер подделок

Вы можете копировать почерк и ремесло других существ. Вы получаете преимущество на все проверки, проводимые для создания подделки или копии существующего объекта.

# Подготовка кенку

Вы владеете на Ваш выбор двумя из следующих навыков:

* Акробатика,
* Ловкость рук,
* Обман,
* Скрытность.

# Имитирование

Вы можете подражать звукам, которые Вы слышали, включая голоса.

Существо, которое слышит звуки, которые Вы издаете, может определить что это имитация при успешной проверке Мудрости (Проницательность), против Вашей проверки Харизмы (Обман).

# Языки

Вы можете читать и писать на [Всеобщем](LANG:${LANG_COMMON}) и [Ауране](LANG:${LANG_AURAN}) (воздушный диалект [Первичного](LANG:${LANG_PRIMORDIAL})), но Вы можете говорить только используя способность _Имитирование_.`,
      columnCount: 2,
      source: {
        id: SOURCE_VGTM,
        page: 113,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 4,
      coefDice: {
        diceCount: 2,
        diceType: 8,
      },
    },
    weight: {
      base: 50,
      coefDice: {
        diceCount: 1,
        diceType: 6,
      },
    },
  },
  paramBonuses: {
    [PARAM_DEX]: 2,
    [PARAM_WIT]: 1,
  },
  age: {
    adult: 12,
    max: 60,
  },
  noHairs: true,
  setting: [
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
  ],
  source: {
    id: SOURCE_VGTM,
    page: 113,
  },
}

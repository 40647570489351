const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_TIEFLING} = require ('./../../pcRaceIdList')
const {
  SETTING_EBERRON,
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')
const {
  SPELL_BURNING_HANDS,
  SPELL_CHARM_PERSON,
  SPELL_DARKNESS,
  SPELL_ENTHRALL,
  SPELL_HELLISH_REBUKE,
  SPELL_THAUMATURGY,
  SPELL_VICIOUS_MOCKERY,
} = require('./../../spellIdList')
const {GOD_ASMODEUS} = require('./../../godIdList')
const {
  PARAM_INT,
  PARAM_CHA,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_TIEFLING,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_TIEFLING].name,
  nameEn: creatureTypeCollection[PC_RACE_TIEFLING].nameEn,
  note: creatureTypeCollection[PC_RACE_TIEFLING].note,
  description: [
    ...creatureTypeCollection[PC_RACE_TIEFLING].description,
    {
      header: `Особенности тифлингов`,
      text: `Из-за своего дьявольского происхождения все тифлинги обладают некоторыми общими качествами.

# Увеличение характеристик

Интеллект увеличивается на 1.

Харизма увеличивается на 2.

# Возраст

Тифлинги взрослеют тогда же, когда и люди, но живут немного дольше.

# Мировоззрение

Может, у тифлингов и нет врождённой тяги к злу, однако многие из них в итоге склоняются к нему. Злые они или нет, но независимая природа определяет хаотичное мировоззрение большинства тифлингов.

# Размер

Тифлинги по росту и телосложению схожи с людьми. Ваш размер — Средний.

# Скорость

Ваша базовая скорость ходьбы составляет 30 футов.

# Тёмное зрение

Благодаря Вашему дьявольскому наследию, Вы отлично видите при тусклом свете и в темноте. На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Адское сопротивление

Вы получаете сопротивление к урону огнём.

# Инфернальное наследие

Вы знаете заговор [Чудотворство](SPELL:${SPELL_THAUMATURGY}).

При достижении 3 уровня Вы можете сотворять [Адское возмездие](SPELL:${SPELL_HELLISH_REBUKE}) как заклинание 2 уровня.

При достижении 5 уровня Вы также можете сотворять заклинание [Тьма](SPELL:${SPELL_DARKNESS}).

Вы должны окончить длинный отдых, прежде чем сможете сотворять эти заклинания ещё раз этой способностью. Харизма — заклинательная характеристика для этих заклинаний.

# Языки

Вы можете говорить, читать и писать на Всеобщем и Инфернальном.`,
      source: {
        id: SOURCE_PHB,
        page: 44,
      },
    },
    {
      header: `Варианты тифлингов`,
      text: `Из-за того, что не все тифлинги произошли от крови [Асмодея](GOD:${GOD_ASMODEUS}), некоторые обладают особенностями, отличными от приведённых в Книге Игрока. Мастер может позволить приведённые ниже варианты персонажа тифлинга, хотя стоит иметь в виду, что варианты «Дьявольский язык», «Адское пламя» и «Крылатый» являются взаимоисключающими.

# Внешний вид

Ваш тифлинг может быть не похож на других тифлингов. Вместо обладания физическими характеристиками, приведенными в Книге Игрока, выберите 1к4+1 следующих особенностей:

* небольшие рожки;
* клыки или острые зубы;
* раздвоенный язык;
* кошачьи глаза;
* шесть пальцев на руках;
* козлиные ноги;
* копыта;
* раздвоенный хвост;
* грубая или чешуйчатая кожа красного или тёмно-синего цвета;
* не отбрасывание тени или отсутствие отражения;
* запах серы.

# Звероподобный

Интеллект увеличен на 1 и Ловкость увеличена на 2. Эта способность заменяет черту «Увеличение характеристик».

# Дьявольский язык

Вы знаете заговор [Злая насмешка](SPELL:${SPELL_VICIOUS_MOCKERY}).

На 3 уровне Вы получите возможность сотворять заклинание [Очарование личности](SPELL:${SPELL_CHARM_PERSON}) как заклинание 2-го круга.

На 5 уровне Вы получите возможность сотворять заклинание [Речь златоуста](SPELL:${SPELL_ENTHRALL}).

После сотворения одного из этих заклинания Вы не сможете сотворять его снова, пока не закончите длинный отдых. Заклинательная характеристика для этих заклинаний — Харизма.

Эта способность заменяет способность «Инфернальное наследие».

# Адское пламя

На 3 уровне Вы получите возможность сотворять заклинание [Огненные ладони](SPELL:${SPELL_BURNING_HANDS}) один раз в день как заклинание 2-го круга. Эта способность заменяет заклинание [Адское возмездие](SPELL:${SPELL_HELLISH_REBUKE}) из способности «Инфернальное наследие».

# Крылатый

У персонажа из лопаток растут перепончатые крылья. Персонаж обладает скоростью полёта 30 футов. Эта черта заменяет способность «Инфернальное наследие».`,
      source: {
        id: SOURCE_SCAG,
        page: 119,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 9,
      coefDice: {
        diceCount: 2,
        diceType: 8,
      },
    },
    weight: {
      base: 110,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_INT]: 1,
    [PARAM_CHA]: 2,
  },
  setting: [
    SETTING_EBERRON,
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
  ],
  source: {
    id: SOURCE_PHB,
    page: 43,
  },
}

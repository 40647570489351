import {
  GENDER_ANY,
  GENDER_MIDDLE,
} from '@/constants/genderList'
import generateRandomGender from "@/utils/nameGeneration/generateRandomGender"

const generateRealGender = (genderIdInput, excludeMiddleGender = true) => (
  genderIdInput === GENDER_ANY
  || (
    excludeMiddleGender
    && genderIdInput === GENDER_MIDDLE
  )
)
  ? generateRandomGender(excludeMiddleGender)
  : genderIdInput

export default generateRealGender

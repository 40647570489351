const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_BEDINE} = require('./../../../pcSubraceIdList')
const {SOURCE_SCAG} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_BEDINE,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: `бедин`,
      [GENDER_FEMALE]: `бединка`,
    },
    dual: {
      [GENDER_MALE]: `бедина`,
      [GENDER_FEMALE]: `бединки`,
    },
    plural: `бедины`,
  },
  nameEn: `Bedine`,
  description: `Темнокожие и темноволосые, бедины были воинами и кочевниками южного Анороха. Однажды разделившиеся на более чем сотню племён, склонные к клановости бедины по большей части оставались в пустынных землях и мало общались с иноземцами, лишь изредка торгуя с ними. Из поколения в поколение, всё больше бединов становились жителями городов, оставляя позади своё кочевническое прошлое, тем самым сокращая количество племён, которые всё ещё поддерживают традиционный образ жизни.

# Имена бединов

## Мужские

Аали, Валид, Рашид, Тахнун, Танзим

## Женские

Айша, Залибъех, Нура, Рашида, Фарах

## Названия племён

Алайи, Борджиа, Голдор, Дакава, Десай, Дурсалай, Зазалаар, Ирифава, Йеттай, Каатан, Келлордай, Клеларра, Лалайар`,
  source: {
    id: SOURCE_SCAG,
    page: 111,
  },
}

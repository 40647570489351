const {SOURCE_PHB} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_DRAGONBORN} = require ('./../../pcRaceIdList')
const {
  SETTING_DRAGON_LANCE,
  SETTING_EBERRON,
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')
const {
  LANG_COMMON,
  LANG_DRACONIC,
} = require ('./../../languageIdList')
const {
  GOD_BAHAMUT,
  GOD_TIAMAT,
} = require('./../../godIdList')
const {
  PARAM_STR,
  PARAM_CHA,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_DRAGONBORN,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_DRAGONBORN].name,
  nameEn: creatureTypeCollection[PC_RACE_DRAGONBORN].nameEn,
  note: creatureTypeCollection[PC_RACE_DRAGONBORN].note,
  description: [
    ...creatureTypeCollection[PC_RACE_DRAGONBORN].description,
    {
      header: `Особенности драконорождённых`,
      text: `Ваше драконье наследие проявляется в ряде качеств, общих для всех драконорождённых.

# Увеличение характеристик

Сила увеличивается на 2.

Харизма увеличивается на 1.

# Возраст

Юные драконорождённые растут быстро. Они начинают ходить через час после вылупления, к трём годам достигают роста и сложения 10-летнего человека, и становятся взрослыми в 15. Живут они примерно до 80 лет.

# Мировоззрение

Драконорождённые склонны к крайностям, сознательно выбирая ту или иную сторону во вселенской борьбе добра и зла (представленных, соответственно [Багамутом](GOD:${GOD_BAHAMUT}) и [Тиамат](GOD:${GOD_TIAMAT})). Большинство драконорождённых добры, но те, кто принял сторону [Тиамат](GOD:${GOD_TIAMAT}), могут быть чрезвычайно жестокими.

# Размер

Драконорождённые выше и тяжелее людей, их рост около 6 футов (2 метров) и вес около 250 фунтов (115 килограмм). Ваш размер — Средний.

# Скорость

Ваша базовая скорость перемещения составляет 30 футов.

# Языки

Вы можете говорить, читать и писать на [Всеобщем](LANG:${LANG_COMMON}) и [Драконьем](LANG:${LANG_DRACONIC}) языках. Драконий язык считается одним из старейших и часто используется во время изучения магии. Этот язык звучит грубо для большинства других существ, и содержит много твёрдых согласных и шипящих звуков.

# Сопротивление урону

Вы получаете сопротивление к урону того вида, который указан в Вашем наследии драконов.

# Наследие драконов

Вы получаете драконье наследие. Выберите тип дракона из таблицы. Вы получаете оружие дыхания и сопротивление к урону соответствующего вида, как указано в таблице.

| Дракон     | Вид урона     | Оружие дыхания             |
|------------|---------------|----------------------------|
| Белый      | Холод         | Конус 15 фт. (исп. Тел.)   |
| Бронзовый  | Электричество | Линия 5×30 фт. (исп. Лов.) |
| Зелёный    | Яд            | Конус 15 фт. (исп. Тел.)   |
| Золотой    | Огонь         | Конус 15 фт. (исп. Лов.)   |
| Красный    | Огонь         | Конус 15 фт. (исп. Лов.)   |
| Латунный   | Огонь         | Линия 5×30 фт. (исп. Лов.) |
| Медный     | Кислота       | Линия 5×30 фт. (исп. Лов.) |
| Серебряный | Холод         | Конус 15 фт. (исп. Тел.)   |
| Синий      | Электричество | Линия 5×30 фт. (исп. Лов.) |
| Чёрный     | Кислота       | Линия 5×30 фт. (исп. Лов.) |

# Оружие дыхания

Вы можете действием выдохнуть разрушительную энергию. Ваше наследие драконов определяет размер, форму и вид урона Вашего выдоха.

Когда Вы используете _оружие дыхания_, все существа в области выдоха должны пройти испытание СЛ 8 + Ваш модификатор Телосложения + бонус мастерства. Вид испытания определяется Вашим наследием. Существа получают урона 2к6 при провале испытания, или половину этого урона при успехе. Урон повышается до 3к6 на 6 уровне, до 4к6 на 11, и до 5к6 на 16 уровне.

После использования _оружия дыхания_ Вы не можете использовать его повторно, пока не завершите короткий либо длинный отдых.`,
      source: {
        id: SOURCE_PHB,
        page: 33,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 5 * 12 + 6,
      coefDice: {
        diceCount: 2,
        diceType: 8,
      },
    },
    weight: {
      base: 175,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 2,
    [PARAM_CHA]: 1,
  },
  age: {
    adult: 15,
    max: 80,
  },
  noHairs: true,
  setting: [
    SETTING_DRAGON_LANCE,
    SETTING_EBERRON,
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
  ],
  source: {
    id: SOURCE_PHB,
    page: 33,
  },
}

const listToCollectionById = require ('./../../utils/listToCollectionById')

const pcSubRaceRawList = require ('./pcSubRaceRawList')

const pcSubRaceList = pcSubRaceRawList
  .map(
    subRace => ({
      ...subRace,
      noHairs: Boolean(subRace.noHairs),
      nameShort: subRace.nameShort || subRace.name,
    })
  )
  .sort(
    ({name: {plural: A}}, {name: {plural: B}}) => A > B
      ? 1
      : -1
  )

module.exports = pcSubRaceList

module.exports.pcSubraceCollection = listToCollectionById(pcSubRaceList)

module.exports.pcSubraceIdList = pcSubRaceList.map(({id}) => id)

module.exports.pcSubraceIdByRaceIdDict = pcSubRaceList.reduce(
  (dict, {id, parentRaceId}) => {
    if (dict[parentRaceId]) {
      dict[parentRaceId].push(id)
    } else {
      dict[parentRaceId] = [id]
    }

    return dict
  },
  {}
)

import mapNames from './../utils/mapNames'

export default [
  'банка',
  'бочка',
  'брос',
  'дудка',
  'зад',
  'камень',
  'каша',
  'кот',
  'мышь',
  'нож',
  'норка',
  'палка',
  'плащ',
  'рост',
  'ручка',
  'скор',
  'сумка',
  'хват',
  'ход',
  'шляп',
  ['банкин', 'банкина'],
  ['бочкин', 'бочкина'],
  ['броскин', 'броскина'],
  ['вар', 'варка'],
  ['веткин', 'веткина'],
  ['вод', 'вода'],
  ['глаз', 'глазка'],
  ['глазкин', 'глазкина'],
  ['дверкин', 'дверкина'],
  ['дудкин', 'дудкина'],
  ['задкин', 'задкина'],
  ['кочка', 'кочка'],
  ['кочкин', 'кочкина'],
  ['кошкин', 'кошкина'],
  ['куст', 'кустка'],
  ['лист', 'листка'],
  ['лов', 'ловка'],
  ['луг', 'лужа'],
  ['лужкин', 'лужкина'],
  ['мост', 'мостка'],
  ['мосткин', 'мосткина'],
  ['мышкин', 'мышкина'],
  ['ног', 'ножка'],
  ['ножкин', 'ножкина'],
  ['нор', 'норка'],
  ['норкин', 'норкина'],
  ['пад', 'падка'],
  ['палкин', 'палкина'],
  ['плот', 'плотка'],
  ['пых', 'пыха'],
  ['пышкин', 'пышкина'],
  ['речкин', 'речкина'],
  ['росткин', 'росткина'],
  ['рук', 'рука'],
  ['ручкин', 'ручкина'],
  ['сад', 'садка'],
  ['сбор', 'сбор'],
  ['сборкин', 'сборкина'],
  ['свин', 'свинка'],
  ['свист', 'свистка'],
  ['свисткин', 'свисткина'],
  ['скоркин', 'скоркина'],
  ['сумкин', 'сумкина'],
  ['фляг', 'фляга'],
  ['флягин', 'флягина'],
  ['фляжкин', 'фляжкина'],
  ['хваткин', 'хваткина'],
  ['ходкин', 'ходкина'],
  ['холм', 'холмка'],
  ['цвет', 'цветка'],
  ['шляпкин', 'шляпкина'],
  ['щёк', 'щёка'],
  ['щёчкин', 'щёчкина'],
].map(mapNames)

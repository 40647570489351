const {SOURCE_TWBTW} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_FAIRY} = require ('./../../pcRaceIdList')
const {SETTING_FORGOTTEN_REALMS} = require ('./../../settingList')
const {PC_RACE_ELF} = require ('./../../pcRaceIdList')
const {SIZE_SMALL} = require ('./../../sizeList')
const {PARAM_ANY} = require ('./../../paramList')
const {
  CREATURE_PIXIE,
  CREATURE_SPRITE,
} = require ('./../../creatureIdList')
const {
  LANG_COMMON,
  LANG_ELVEN,
  LANG_GOBLIN,
  LANG_SYLVAN,
} = require ('./../../languageIdList')
const {
  SPELL_DRUIDCRAFT,
  SPELL_ENLARGE_REDUCE,
  SPELL_FAERIE_FIRE,
} = require ('./../../spellIdList')
const customParamBonuses = require ('./../constants/customParamBonuses')
const customLanguages = require ('./../constants/customLanguages')

module.exports = {
  id: PC_RACE_FAIRY,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_FAIRY].name,
  nameEn: creatureTypeCollection[PC_RACE_FAIRY].nameEn,
  setting: SETTING_FORGOTTEN_REALMS,
  sizeType: SIZE_SMALL,
  paramBonuses: {
    [PARAM_ANY]: 3,
  },
  description: [
    {
      text: `Страна Фей — дом для множества фантастический народов, в том числе и для фэйри. Фэйри — маленький народец, хоть и не столь крошечный, как их друзья [пикси](CREATURE:${CREATURE_PIXIE}) и [спрайты](CREATURE:${CREATURE_SPRITE}). Первые фэйри говорили на [эльфийском](LANG:${LANG_ELVEN}), [гоблинском](LANG:${LANG_GOBLIN}) или [сильване](LANG:${LANG_SYLVAN}), а встречи с людьми побудили многих их них выучить ещё и [всеобщий язык](LANG:${LANG_COMMON}).

Наполненные магией Страны Фей, большинство фэйри выглядят как маленькие [эльфы](PC_RACE:${PC_RACE_ELF}) с насекомообразными крыльями, но у каждой отдельной фэйри есть своя особенность, заметно выделяющая её среди прочих фэйри. Вы можете определить такую особенность Вашей фэйри случайно, по таблице, либо выбрать её самостоятельно. Вы также можете придумать подходящую Вашему персонажу особенность самостоятельно, если ни один из вариантов Вас не устроит.`,
      source: {
        id: SOURCE_TWBTW,
        page: 12,
      },
    },
    {
      header: 'Фейские особенности',
      text: `
| к8 | Особенность |
|-|-|
| 1 | Ваши крылья похожи на птичьи. |
| 2 | У Вас блестящая многоцветная кожа. |
| 3 | У Вас невероятно большие уши. |
| 4 | Вас постоянно окружает поблескивающий туман. |
| 5 | На Вашем лбу есть небольшой призрачный рог, как у единорога. |
| 6 | У Вас насекомообразные ноги. |
| 7 | Вы пахнете свежими пирожными. |
| 8 | Вас окружает заметный, но безвредный мороз. |
`,
      source: {
        id: SOURCE_TWBTW,
        page: 13,
      },
    },
    {
      header: 'Особенности фэйри',
      text: `
# Возраст

Фэйри живут около века.

${customParamBonuses}

# Фея

Ваш тип существа — фея, а не гуманоид.

# Размер

Ваш размер — Маленький.

# Скорость

Ваша скорость ходьбы — 30 футов. Кроме того, Вы обладаете скоростью полёта 30 футов.

# Полёт

Благодаря своим крыльям Вы получаете скорость полёта, равную Вашей скорости ходьбы. Вы не можете использовать эту скорость полёта, пока носите средние или тяжёлые доспехи.

# Магия фэйри

Вы знаете заговор [Искусство друидов](SPELL:${SPELL_DRUIDCRAFT}).

Начиная с 3 уровня, Вы можете сотворять этой способностью заклинание [Огонь фей](SPELL:${SPELL_FAERIE_FIRE}).

Начиная с 5 уровня, Вы можете сотворять этой способностью заклинание [Увеличение/Уменьшение](SPELL:${SPELL_ENLARGE_REDUCE}).

Сотворив этой способностью заклинание [Огонь фей](SPELL:${SPELL_FAERIE_FIRE}) или [Увеличение/Уменьшение](SPELL:${SPELL_ENLARGE_REDUCE}), Вы не сможете сотворять это заклинание снова, пока не завершите длинный отдых. Также Вы можете сотворять эти заклинания, тратя свои ячейки заклинаний соответствующего уровня.

Ваша заклинательная характеристика для этих заклинаний, когда Вы сотворяете их этой способностью — Интеллект, Мудрость или Харизма (выберите, когда выбираете эту расу).

${customLanguages}`,
      source: {
        id: SOURCE_TWBTW,
        page: 13,
      },
    },
  ],
  source: {
    id: SOURCE_TWBTW,
    page: 12,
  },
}

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_WIT} = require ('./../../../paramList')
const {PC_RACE_ELF} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_ELF_WOOD} = require('./../../../pcSubraceIdList')
const {SOURCE_PHB, SOURCE_SCAG} = require ('./../../../sourceList')
const {
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
  GEAR_SHORTBOW,
  GEAR_SHORTSWORD,
} = require ('./../../../gearIdList')

const phbSizeSource = require ('./../../constants/phbSizeSource')

module.exports = {
  id: PC_SUBRACE_ELF_WOOD,
  parentRaceId: PC_RACE_ELF,
  name: {
    singular: {
      [GENDER_MALE]: 'лесной эльф',
      [GENDER_FEMALE]: 'лесная эльфийка',
    },
    dual: {
      [GENDER_MALE]: 'лесных эльфа',
      [GENDER_FEMALE]: 'лесные эльфийки',
    },
    plural: 'лесные эльфы',
  },
  nameShort: {
    singular: {
      [GENDER_MALE]: 'лесной',
      [GENDER_FEMALE]: 'лесная',
    },
    dual: {
      [GENDER_MALE]: 'лесных',
      [GENDER_FEMALE]: 'лесные',
    },
    plural: 'лесные',
  },
  nameEn: 'Wood Elves',
  nameAlt: [
    'Дикие эльфы',
    'Зелёные эльфы',
    'Медные эльфы',
    'Си’Тель’Кессир',
  ],
  nameEnAlt: [
    'Wild Elves',
    'Forest Elves',
    'Green Elves',
    'Copper Elves',
    'Sy’Tel’Quessir',
  ],
  size: {
    tall: {
      base: 4 * 12 + 6,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 100,
      coefDice: {
        diceCount: 1,
        diceType: 4,
      },
    },
    source: phbSizeSource,
  },
  description: [
    {
      header: `Лесные эльфы`,
      text: `Поскольку Вы — лесной эльф, у Вас обострённые чувства и интуиция, и стремительные ноги быстро и незаметно несут Вас через родные леса. Эта категория включает диких эльфов Серого Ястреба и кагонести из Саги о Копье, а также расы, называемые лесными эльфами Серого Ястреба и Забытых Королевств. В Фаэруне лесные эльфы (также называемые дикими или зелёными) являются затворниками, не доверяющими не-эльфам.

Кожа лесных эльфов, как правило, имеет медный оттенок, иногда со следами зелёного. У них часто коричневые и чёрные волосы, но иногда они бывают светлого или бронзового оттенков. У них зелёные, карие или орехового цвета глаза.

# Увеличение характеристик

Мудрость увеличивается на 1.

# Владение эльфийским оружием

Вы владеете [длинным мечом](GEAR:${GEAR_LONGSWORD}), [коротким мечом](GEAR:${GEAR_SHORTSWORD}), [коротким](GEAR:${GEAR_SHORTBOW}) и [длинным](GEAR:${GEAR_LONGBOW}) луками.

# Быстрые ноги

Ваша базовая скорость перемещения увеличивается до 35 футов.

# Маскировка в дикой местности

Вы можете предпринять попытку спрятаться, даже если Вы слабо заслонены листвой, сильным дождём, снегопадом, туманом или другими природными явлениями.`,
      source: {
        id: SOURCE_PHB,
        page: 25,
      },
    },
    {
      header: `Лесные эльфы`,
      text: `Также известные как медные эльфы или _Си’Тель’Кессир_. Лесные эльфы являются наиболее распространёнными эльфами, оставшимися на Фаэруне. Их предки прекратили участие в Войнах Короны сотни лет назад, для того чтобы основать твердыни и поселения глубоко в чащах лесов. Ныне, большинство лесных эльфов стоят на страже руин прошлого, веря в то, что их священный долг — сохранить память об их былой славе, как напоминание об опасностях излишнего высокомерия.

Лесные эльфы преимущественно крепче прочих эльфов, более трезво и приземлённо мыслящие. Это отношение находит отражение в их культуре и традициях. Лесные эльфы более склонны к материальным занятиям чем прочие эльфы и более критично смотрят на древнюю эльфийскую историю. Для лесных эльфов «великие» эльфийские королевства являются виновниками равновеликих ошибок. Они считают Раскол, Войны Короны, падение дроу и прочие потрясения результатом надменных действий предков, отчасти и предков лесных эльфов. Живя среди напоминаний об этом высокомерии и будучи свидетелями взлётов и падений многих эльфийских империй, лесные эльфы по-другому видят место эльфов в этом мире, чем лунные или солнечные эльфы. Лесные эльфы ищут тихой гармонии с целым миром, а не доминирования над ним.

Лесные отражения лунных и солнечных эльфов, лесные эльфы сторонятся городов и твердынь своих сородичей, предпочитая им жизнь на лоне природы. Лесные эльфы не претендовали на большие государства со времён королевства Эаэрланн, павшего столетия назад. Вместо этого они поддерживают ряд небольших поселений, которые способствуют тому, чтобы оставаться незамеченными и защищёнными. Лесные эльфы считают своими территории в Высоком Лесу, Великой Долине, Западном Сердцеземье и за ним. Некоторые лесные эльфы живут в других общинах и территориях эльфов, где выступают в качестве разведчиков, следопытов и охотников.

Несмотря на то, что они считают себя частью этого мира, лесные эльфы не часто выбираются из своих владений для встреч с не-эльфами. Также, и в глубоких чащах и лесах мира, лесные эльфы сторонятся других рас. Искатели приключений, дипломаты, курьеры и представители схожих профессий, путешествующие за пределы лесных владений и встречающиеся с большим количеством людей, являются исключением.

Лесным эльфам Фаэруна присущи черты лесных эльфов, приведенные в Книге Игрока. Их кожа имеет смуглый или медный оттенок, а волосы бывают каштановыми, золотисто-светлые, чёрные или с медным отливом. Цвет глаз варьируется от зелёного до тёмно-карего.

Опытные натуралисты, лесные эльфы часто избирают профессии, которые позволяют им оставаться близко к дикой природе или использовать свои знания лесов, флоры и фауны. Лесные эльфы весьма умелы в военном деле, в частности в области лучного дела. Они менее склонны к магии, чем их сородичи, но всё же имеют немалую часть практикующих Искусство, а также жрецов и друидов.`,
      source: {
        id: SOURCE_SCAG,
        page: 107,
      },
    },
  ],
  paramBonuses: {
    [PARAM_WIT]: 1,
  },
  source: {
    id: SOURCE_PHB,
    page: 25,
  },
}

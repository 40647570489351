const {SOURCE_VGTM} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')
const {
  PC_RACE_ELF,
  PC_RACE_HUMAN,
  PC_RACE_FILBORG,
} = require ('./../../pcRaceIdList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {
  PARAM_STR,
  PARAM_WIT,
} = require ('./../../paramList')
const {
  SPELL_DETECT_MAGIC,
  SPELL_DISGUISE_SELF,
} = require ('./../../spellIdList')
const {
  PC_CLASS_BARBARIAN,
  PC_CLASS_BARD,
  PC_CLASS_CLERIC,
  PC_CLASS_DRUID,
  PC_CLASS_FIGHTER,
  PC_CLASS_MONK,
  PC_CLASS_PALADIN,
  PC_CLASS_RANGER,
  PC_CLASS_ROGUE,
  PC_CLASS_SORCERER,
  PC_CLASS_WARLOCK,
  PC_CLASS_WIZARD,
} = require ('./../../pcClassIdList')

module.exports = {
  id: PC_RACE_FILBORG,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_FILBORG].name,
  nameEn: creatureTypeCollection[PC_RACE_FILBORG].nameEn,
  note: creatureTypeCollection[PC_RACE_FILBORG].note,
  description: [
    ...creatureTypeCollection[PC_RACE_FILBORG].description,
    {
      header: 'Изгнанные искатели приключений ',
      text: `Будучи хранителями леса, очень немногие фирболги помышляют о том, чтобы покинуть свой дом или попробовать влиться в человеческое общество. Но у изгнанного фирболга, или у того, чей клан уничтожен, нет выбора. Большинство фирболгов — искателей приключений попадают в эту категорию.

Изгнанные фирболги никогда не смогут вернуться домой. Они совершили что-то непростительное, как правило что-то, что поставило под угрозу их родину, например, устроили лесной пожар или убили редкое, или красивое дикое животное. Эти фирболги — одиночки, которые блуждают по миру в надежде найти новое место, которое смогут назвать домом.

Осиротевшие фирболги это те, чей кланы или родина были уничтожены. Они становятся крестоносцами природы — стремятся отомстить за свою потерю и предотвратить дальнейшее уничтожение мира природы.

Изредка бывает, что клан поручает фирболгу важную миссию за пределами их дома. Эти фирболги чувствуют себя странниками в чужой стране, и обычно они хотят как можно быстрее закончить свою миссию и вернуться домой.`,
      source: {
        id: SOURCE_VGTM,
        page: 108,
      },
    },
    {
      header: 'Фирболги-приключенцы',
      text: `Таблица может служить вдохновением для того, чтобы определить, почему персонаж-фирболг покинул дом.

| к8 | Причина отправиться в приключения                  |
|----|----------------------------------------------------|
| 1  | Изгнан за убийство                                 |
| 2  | Изгнан за серьёзное разрушение домашней территории |
| 3  | Клан уничтожен вторгшимися гуманоидами             |
| 4  | Клан уничтожен драконом или демоном                |
| 5  | Отделился от племени и потерялся                   |
| 6  | Родина уничтожена стихийным бедствием              |
| 7  | Личное задание, полученное как предзнаменование    |
| 8  | Отправлен на задание лидерами племени              |`,
      source: {
        id: SOURCE_VGTM,
        page: 108,
      },
    },
    {
      header: 'Классы фирболгов',
      text: `Большинство фирболгов — [друиды](PC_CLASS:${PC_CLASS_DRUID}), [следопыты](PC_CLASS:${PC_CLASS_RANGER}) или [воины](PC_CLASS:${PC_CLASS_FIGHTER}). Среди их вида эти призвания передаются из поколения в поколение. Магическое наследие фирболгов также проявляет себя другими способами; те, кто становится [бардами](PC_CLASS:${PC_CLASS_BARD}), оберегают знания клана, а фирболги-[чародеи](PC_CLASS:${PC_CLASS_SORCERER}) защищают свои сообщества. [Волшебники](PC_CLASS:${PC_CLASS_WIZARD}) фирболгов возникают, когда клан начинает дружить с [эльфами](PC_RACE:${PC_RACE_ELF}).

[Плуты](PC_CLASS:${PC_CLASS_ROGUE}) фирболгов это, как правило, скауты, которым дают задание следить за соседним народом, чтобы определить их намерения. Они наиболее распространены среди фирболгов, чьи дома граничат с поселениями [людей](PC_RACE:${PC_RACE_HUMAN}).

[Варвары](PC_CLASS:${PC_CLASS_BARBARIAN}) среди фирболгов редки, за исключением кланов, которые постоянно находятся перед лицом угрозы со стороны злых гуманоидов и других захватчиков.

[Жрецы](PC_CLASS:${PC_CLASS_CLERIC}) и [паладины](PC_CLASS:${PC_CLASS_PALADIN}) фирболгов обычно служат богам природы и на них взирают как на проводников воли богов.

[Колдуны](PC_CLASS:${PC_CLASS_WARLOCK}) фирболгов редки, но некоторые кланы создают союзы и тайные договоры с влиятельными феями.

О фирболгах-[монахах](PC_CLASS:${PC_CLASS_MONK}) почти никто ничего не слышал, хотя монастырь мог бы взять на воспитание маленького сироту из уничтоженного клана фирболгов.`,
      source: {
        id: SOURCE_VGTM,
        page: 109,
      },
    },
    {
      header: 'Особенности ',
      text: `Ваш персонаж фирболг обладает следующими особенностями.

# Увеличение характеристик

Мудрость увеличивается на 2.

Сила увеличивается на 1.

# Возраст

Как у гуманоидов, родственных с феями, у фирболгов большая продолжительность жизни. Фирболги достигают зрелости приблизительно к 30 годам, самые старейшие из них доживали до 500 лет.

# Мировоззрение

Как народ, который подчиняется циклам природы и рассматривает себя как её хранители, фирболги обычно нейтрально-добрые.

Злые фирболги — редкость и обычно они являются заклятыми врагами остальной части их вида.

# Размер

Фирболги ростом от 7 до 8 футов и весят от 240 до 300 фунтов. Ваш размер — Средний.

# Скорость

Ваша базовая скорость перемещения 30 футов.

# Магия фирболгов

Вы можете сотворять заклинания [Обнаружение магии](SPELL:${SPELL_DETECT_MAGIC}) и [Маскировку](SPELL:${SPELL_DISGUISE_SELF}). Мудрость — заклинательная характеристики для них. Наложив одно из этих заклинаний, Вы не можете наложить его снова этой способностью, пока не завершите короткий или длинный отдых.

Когда Вы используете эту версию заклинания [Маскировка](SPELL:${SPELL_DISGUISE_SELF}), Вы можете казаться на 3 фута короче, чем обычно, чтобы легче сливаться с [людьми](PC_RACE:${PC_RACE_HUMAN}) и [эльфами](PC_RACE:${PC_RACE_ELF}).

# Незримая поступь

Вы можете бонусным действием магически стать невидимыми до начала своего следующего хода или пока Вы не атакуете, нанесёте урон, или не вынудите кого-то пройти испытание. Использовав эту способность, Вы не можете использовать её снова, пока не завершите короткий или длинный отдых.

# Мощное телосложение

Вы считаетесь на один размер больше при определении грузоподъемности и веса, который Вы можете толкать, тянуть или поднимать.

# Язык зверей и листвы

У Вас есть способность к ограниченному общению с животными и растениями. Они могут понимать значение Ваших слов, хотя у Вас нет никакой специальной способности понять их в ответ. Вы получаете преимущество на все проверки Харизмы, которые Вы совершаете, чтобы повлиять на них.

# Языки

Вы можете говорить, читать и писать на Всеобщем, Эльфийском и Великаньем языках.`,
      source: {
        id: SOURCE_VGTM,
        page: 109,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 6 * 12 + 2,
      coefDice: {
        diceCount: 2,
        diceType: 12,
      },
    },
    weight: {
      base: 175,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 1,
    [PARAM_WIT]: 2,
  },
  age: {
    adult: 30,
    max: 500,
  },
  setting: [
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
  ],
  source: {
    id: SOURCE_VGTM,
    page: 109,
  },
}

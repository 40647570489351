import arrify from 'arrify'
import {GENDER_FEMALE, GENDER_MALE} from '@/constants/genderList'

export default name => {
  const nameList = arrify(name)
  const [male, female] = nameList.length === 1
    ? [nameList[0], nameList[0]]
    : nameList

  return ({
    [GENDER_MALE]: male,
    [GENDER_FEMALE]: female,
  })
}

const {
  PC_RACE_CUSTOM,
  PC_RACE_ELF,
  PC_RACE_DWARF,
  PC_RACE_HALFLING,
} = require ('./../../pcRaceIdList')
const {SOURCE_TCoE} = require ('./../../sourceList')
const {GEAR_LONGSWORD} = require ('./../../gearIdList')
const {
  LANG_ABYSSAL,
  LANG_COMMON,
  LANG_DEEP_SPEECH,
  LANG_DRACONIC,
  LANG_DWARVISH,
  LANG_ELVEN,
  LANG_GIANT,
  LANG_GNOMISH,
  LANG_GOBLIN,
  LANG_HALFLING,
  LANG_ORC,
  LANG_PRIMORDIAL,
  LANG_SYLVAN,
  LANG_UNDERCOMMON,
} = require ('./../../languageIdList')
const {
  SIZE_MEDIUM,
  SIZE_SMALL,
} = require ('./../../sizeList')
const customLanguages = require ('./../constants/customLanguages')

module.exports = {
  id: PC_RACE_CUSTOM,
  isReady: true,
  name: 'Индивидуальная раса',
  nameEn: 'Custom race',
  description: [
    {
      header: 'Настройка Вашего происхождения',
      text: `На 1 уровне Вы выбираете разнообразные аспекты своего персонажа, включая значения характеристик, расу, [класс](/pc-class-catalog) и предысторию. Все эти элементы вместе помогают показать происхождение Вашего персонажа и дают Вам возможность создавать разные типы персонажей.

Несмотря на такую гибкость, обычно раса персонажа в D&D даёт очень мало выбора (или вовсе его не даёт). Это проблема, которая часто мешает создавать определённые концепты персонажей. Этот раздел предназначен для исправления этого недостатка расы персонажа, позволяя настроить очки характеристик, языки и некоторые владения для соответствия образу персонажа, который складывается у Вас в голове.

Раса персонажа в игре — это фэнтезийный вид плюс некоторые культурные предположения. Ниже Вы найдёте способы выйти за пределы этих предположений и создать действительно уникального персонажа.`,
      source: {
        id: SOURCE_TCoE,
        page: 1,
      },
    },
    {
      header: 'Увеличение характеристик',
      text: `Какой бы D&D расы не был Ваш персонаж, Вы получаете особенность «Увеличение характеристик». Это увеличение отражает архетипичные особенности приключенцев этой расы в прошлом D&D. Например, если Вы — [дварф](PC_RACE:${PC_RACE_DWARF}), то Ваше Телосложение увеличивается на 2, потому что герои [дварфов](PC_RACE:${PC_RACE_DWARF}) в D&D обычно невероятно крепкие. Это увеличение касается не каждого [дварфа](PC_RACE:${PC_RACE_DWARF}), а только [дварфов](PC_RACE:${PC_RACE_DWARF})-приключенцев и служит укреплению сложившегося архетипа. Это приемлемо, если Вы хотите следовать принятому архетипу, но бесполезно в случае, когда Ваш персонаж этому архетипу не следует.

Если Вы хотите чтобы Ваш персонаж следовал своему собственному пути, то Вы можете проигнорировать особенность «Увеличение характеристик» и увеличить более подходящие для Вашего персонажа характеристики. Для этого Вам нужно взять предлагаемые очки увеличения одной характеристики и применить их к другой характеристике по Вашему выбору. Если Вы можете повысить больше одной характеристики, то не можете применять несколько повышений к одной и той же характеристике. Кроме того, Вы не можете повышать характеристики выше 20.

Например, если особенность «Увеличение характеристик» Вашей расы или подрасы повышает Телосложение на 2, а Мудрость на 1, то Вы можете вместо этого повысить Интеллект на 2, а Харизму на 1.`,
      source: {
        id: SOURCE_TCoE,
        page: 2,
      },
    },
    {
      header: 'Языки',
      text: `Раса Вашего персонажа включает языки, которые, как предполагается, Ваш персонаж знает. Обычно это [всеобщий](LANG:${LANG_COMMON}) и язык Ваших предков. Например, предполагается что приключенец-[полурослик](PC_RACE:${PC_RACE_HALFLING}) знает [всеобщий](LANG:${LANG_COMMON}) и [полуросличий](LANG:${LANG_HALFLING}). Но дело в том, что приключенцы в D&D — экстраординарные личности и Ваш персонаж мог вырасти, говоря на каких-то других языках.

Для индивидуализации известных Вам языков Вы можете заменить любые языки из расовой способности «Языки» на языки из следующего списка

* [Великаний](LANG:${LANG_GIANT})
* [Всеобщий](LANG:${LANG_COMMON})
* [Глубинная речь](LANG:${LANG_DEEP_SPEECH})
* [Гномий](LANG:${LANG_GNOMISH})
* [Гоблинский](LANG:${LANG_GOBLIN})
* [Дварфийский](LANG:${LANG_DWARVISH})
* [Драконий](LANG:${LANG_DRACONIC})
* Инфернальный
* Небесный
* [Орочий](LANG:${LANG_ORC})
* [Первичный](LANG:${LANG_PRIMORDIAL})
* [Подземный](LANG:${LANG_UNDERCOMMON})
* [Полуросличий](LANG:${LANG_HALFLING})
* [Сильван](LANG:${LANG_SYLVAN})
* [Эльфийский](LANG:${LANG_ELVEN})
* [язык Бездны](LANG:${LANG_ABYSSAL})

Ваш Мастер может добавлять или убирать языки из этого списка, в зависимости от языков, подходящих для вашей кампании.`,
      columnCount: 2,
      source: {
        id: SOURCE_TCoE,
        page: 2,
      },
    },
    {
      header: 'Владения',
      text: `Некоторые расы и подрасы дают разнообразные владения. Эти владения обычно культурно обусловлены, но Ваш персонаж мог не иметь ничего общего с этой культурой или, возможно, мог посещать совершенно другие тренировки. Вы можете заменять эти владения на другие по Вашему выбору, соблюдая ограничения из таблицы «Замена владений».

Например, приключенцы высшие [эльфы](PC_RACE:${PC_RACE_ELF}) владеют [длинными мечами](GEAR:${GEAR_LONGSWORD}), которые являются воинским оружием. Согласно таблице «Замена владений» мы видим, что Ваш высший [эльф](PC_RACE:${PC_RACE_ELF}) может поменять это владение на владение другим оружием или инструментом. Возможно, Ваш [эльф](PC_RACE:${PC_RACE_ELF}) — музыкант, выбравший владение музыкальным инструментом (видом инструментов) вместо [длинных мечей](GEAR:${GEAR_LONGSWORD}). При этом, [эльфы](PC_RACE:${PC_RACE_ELF}) владеют навыком Внимательность. Ваш [эльф](PC_RACE:${PC_RACE_ELF}) может не иметь общих для [эльфов](PC_RACE:${PC_RACE_ELF}) острых чувств и может мог выбрать владение другим навыком, например — Выступлением.`,
      source: {
        id: SOURCE_TCoE,
        page: 2,
      },
    },
    {
      header: 'Замена владений',
      text: `
| Владение | Заменяется на… |
|-|-|
| Навык | Навык |
| Доспехи | Простое/воинское оружие или инструмент |
| Простое оружие | Простое оружие или инструмент |
| Воинское оружие | Простое/воинское оружие или инструмент |
| Инструмент | Простое оружие или инструмент |`,
      source: {
        id: SOURCE_TCoE,
        page: 2,
      },
    },
    {
      header: 'Личность',
      text: `Описание расы может предлагать особенности поведения и личностей типичных её представителей, подавшихся в приключенцы. Вы можете проигнорировать эти предложения, вне зависимости от того, касаются ли мировоззрения, настроения, интересов или других черт личности. Личность и поведение Вашего персонажа целиком определяете Вы.`,
      source: {
        id: SOURCE_TCoE,
        page: 3,
      },
    },
    {
      header: 'Собственное происхождение',
      text: `Вместо выбора одной из игровых рас при создании персонажа 1 уровня Вы можете использовать следующие особенности для представления происхождения Вашего персонажа.

Это даст Вам полный контроль над тем, как происхождение Вашего персонажа влияет на него.

# Тип существа

Вы — гуманоид. Вы определяете свою внешность и то, насколько Вы похожи на своих соплеменников.

# Размер

Ваш размер — Маленький или Средний (на Ваш выбор).

# Скорость

Вы скорость ходьбы — 30 футов.

# Повышение характеристик

Одна характеристика по Вашему выбору повышается на 2.

# Черта

Вы получаете одну [черту](/feat-catalog) по Вашему выбору.

# Способность на выбор

Вы получаете одну из следующих способностей по Вашему выбору

* Тёмное зрение дальностью 60 футов.
* Владение одним навыком по Вашему выбору.

${customLanguages}`,
      columnCount: 2,
      source: {
        id: SOURCE_TCoE,
        page: 3,
      },
    },
  ],
  sizeType: [
    SIZE_MEDIUM,
    SIZE_SMALL,
  ],
  source: {
    id: SOURCE_TCoE,
    page: 3,
  },
}

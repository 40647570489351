const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require ('./../../constants/genderList')
const {SIZE_MEDIUM} = require ('./../../constants/sizeList')
const {settingIdList} = require ('./../../constants/settingList')

const generateListReleasedBeforeAs = require ('./../../utils/generateListReleasedBeforeAs')
const checkIsFromUA = require ('./../../utils/checkIsFromUA')

const EMPTY = require ('./constants/EMPTY')
const humanAge = require ('./constants/humanAge')
const humanSize = require ('./constants/humanSize')
const phbSizeSource = require ('./constants/phbSizeSource')

class Race {
  nameScheme = 'name.plural.nominative'

  constructor(
    {
      abandonedAt = null,
      age = humanAge,
      description = EMPTY,
      id = null,
      isReady = true,
      name = null,
      nameAlt = null,
      nameEn = null,
      nameEnAlt = null,
      noHairs = false,
      note = null,
      paramBonuses = null,
      releasedAs = null,
      setting = settingIdList,
      size = humanSize,
      sizeType = SIZE_MEDIUM,
      source = null,
    },
    list = []
  ) {
    this.abandonedAt = abandonedAt
    this.age = age
    this.description = description
    this.id = id
    this.isReReleased = Boolean(releasedAs)
    this.isReady = isReady
    this.isUA = checkIsFromUA(source)
    this.nameAlt = nameAlt
    this.nameEn = nameEn
    this.nameEnAlt = nameEnAlt
    this.noHairs = Boolean(noHairs)
    this.note = note
    this.paramBonuses = paramBonuses
    this.releasedAs = releasedAs
    this.releasedBeforeAsList = generateListReleasedBeforeAs(list, id)
    this.setting = setting
    this.sizeType = sizeType
    this.source = source

    this.size = size.source
      ? size
      : {
        ...size,
        source: phbSizeSource,
      }

    this.name = typeof name === 'string'
      ? {
        singular: {
          [GENDER_MALE]: {
            nominative: name,
            genitive: name,
            dative: name,
            accusative: name,
            instrumental: name,
            prepositional: name,
          },
          [GENDER_FEMALE]: {
            nominative: name,
            genitive: name,
            dative: name,
            accusative: name,
            instrumental: name,
            prepositional: name,
          },
        },
        plural: {
          nominative: name,
          genitive: name,
          dative: name,
          accusative: name,
          instrumental: name,
          prepositional: name,
        },
      }
      : name
  }
}

module.exports = Race

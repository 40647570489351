import _ from "lodash";

import { PC_RACE_AARAKOCRA } from "@/constants/pcRaceIdList"
import Name from "@/constructors/Name"
import generateRealGender from '@/utils/nameGeneration/generateRealGender'

import nameList from "./dict/nameList"

export default ({genderId: genderIdInput}) => new Name({
  firstName: _.sample(nameList),
  raceId: PC_RACE_AARAKOCRA,
  genderId: generateRealGender(genderIdInput),
})

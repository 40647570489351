const {SOURCE_MTOF} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PARAM_INT} = require ('./../../paramList')
const {PC_RACE_GITH} = require ('./../../pcRaceIdList')
const {SETTING_FORGOTTEN_REALMS} = require ('./../../settingList')
const {
  LANG_COMMON,
  LANG_GITH,
} = require ('./../../languageIdList')

module.exports = {
  id: PC_RACE_GITH,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_GITH].name,
  nameEn: creatureTypeCollection[PC_RACE_GITH].nameEn,
  note: creatureTypeCollection[PC_RACE_GITH].note,
  description: [
    ...creatureTypeCollection[PC_RACE_GITH].description,
    {
      header: `Особенности гитов`,
      text: `Ваш персонаж обладает рядом врождённых способностей, являющихся частью его природы.

# Увеличение характеристик

Интеллект увеличивается на 1.

# Возраст

Гиты достигают совершеннолетия в позднем подростковом возрасте и живут около века.

# Размер

Гиты выше и стройнее людей, их рост может достигать 6 футов.

# Скорость

Ваша базовая скорость перемещения 30 футов.

# Языки

Вы можете говорить, читать и писать на [Всеобщем](LANG:${LANG_COMMON}) и [Гитском](LANG:${LANG_GITH}).

# Подрасы

Гиты делятся на два вида: гитцераи и гитьянки. Выберите один из них.`,
      source: {
        id: SOURCE_MTOF,
        page: 97,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  paramBonuses: {
    [PARAM_INT]: 1,
  },
  age: {
    adult: 20,
    max: 100,
  },
  setting: SETTING_FORGOTTEN_REALMS,
  source: {
    id: SOURCE_MTOF,
    page: 97,
  },
}

const {SOURCE_PHB} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PARAM_CON} = require ('./../../paramList')
const {PC_RACE_DWARF} = require ('./../../pcRaceIdList')
const {
  LANG_COMMON,
  LANG_DWARVISH,
} = require ('./../../languageIdList')
const {
  SETTING_DRAGON_LANCE,
  SETTING_EBERRON,
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
  SETTING_GREYHAWK,
} = require ('./../../settingList')
const {
  GEAR_BATTLEAXE,
  GEAR_BREWERS_SUPPLIES,
  GEAR_HANDAXE,
  GEAR_LIGHT_HAMMER,
  GEAR_MASONS_TOOLS,
  GEAR_SMITHS_TOOLS,
  GEAR_WARHAMMER,
} = require ('./../../gearIdList')

module.exports = {
  id: PC_RACE_DWARF,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_DWARF].name,
  nameEn: creatureTypeCollection[PC_RACE_DWARF].nameEn,
  nameAlt: creatureTypeCollection[PC_RACE_DWARF].nameAlt,
  note: creatureTypeCollection[PC_RACE_DWARF].note,
  description: [
    ...creatureTypeCollection[PC_RACE_DWARF].description,
    {
      header: `Особенности дварфов`,
      text: `Ваш персонаж дварф обладает рядом врождённых способностей, являющихся частью его природы.

# Увеличение характеристик

Телосложение увеличивается на 2.

# Возраст

Дварфы взрослеют с той же скоростью, что и люди, но считаются юными, пока не достигнут пятидесятилетнего возраста. В среднем, они живут свыше 350 лет.

# Мировоззрение

Большинство дварфов принципиальные, твёрдо верящие в преимущества хорошо организованного общества. Они также стремятся к добру, обладают развитым чувством справедливости и верят, что все заслуживают пользования преимуществами закона и порядка.

# Размер

Рост дварфов находится между 4 и 5 футами (122 и 152 сантиметрами), и весят они около 150 фунтов (68 килограмм). Ваш размер — Средний.

# Скорость

Ваша базовая скорость перемещения — 25 футов. Ношение тяжёлых доспехов не снижает Вашу скорость.

# Тёмное зрение

Привыкнув к жизни под землёй, Вы обладаете превосходным зрением в темноте и при тусклом освещении. На расстоянии в 60 футов при тусклом освещении Вы можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Дварфийская устойчивость

Вы совершаете с преимуществом испытания от яда, и получаете сопротивление к урону ядом. 

# Дварфийская боевая тренировка

Вы владеете [боевым топором](GEAR:${GEAR_BATTLEAXE}), [ручным топором](GEAR:${GEAR_HANDAXE}), [лёгким](GEAR:${GEAR_LIGHT_HAMMER}) и [боевым молотами](GEAR:${GEAR_WARHAMMER}).

# Владение инструментами

Вы владеете ремесленными инструментами на Ваш выбор: [инструменты кузнеца](GEAR:${GEAR_SMITHS_TOOLS}), [пивовара](GEAR:${GEAR_BREWERS_SUPPLIES}) или [каменщика](GEAR:${GEAR_MASONS_TOOLS}).

# Знание камня

Если Вы совершаете проверку Интеллекта (История), связанную с происхождением работы по камню, Вы считаетесь владеющим навыком История, и добавляете к проверке удвоенный бонус мастерства вместо обычного.

# Языки

Вы разговариваете, читаете и пишете на [Всеобщем](LANG:${LANG_COMMON}) и [Дварфийском](LANG:${LANG_DWARVISH}) языках. Дварфийский язык состоит из твёрдых согласных и гортанных звуков, и этот акцент будет присутствовать в любом языке, на котором дварф будет говорить.

# Разновидности

Два основных вида дварфов населяют миры D&D: холмовые дварфы и горные дварфы. Выберите один из этих видов.`,
      source: {
        id: SOURCE_PHB,
        page: 21,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  paramBonuses: {
    [PARAM_CON]: 2,
  },
  age: {
    adult: 50,
    max: 350,
  },
  setting: [
    SETTING_DRAGON_LANCE,
    SETTING_EBERRON,
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
    SETTING_GREYHAWK,
  ],
  source: {
    id: SOURCE_PHB,
    page: 19,
  },
}

module.exports = [
  require('./races/aarakocra'),
  require('./races/aasimar'),
  require('./races/autognome'),
  require('./races/bugbear'),
  require('./races/centaur'),
  require('./races/custom'),
  require('./races/changeling'),
  require('./races/dhampir'),
  require('./races/dragonborn'),
  require('./races/dwarf'),
  require('./races/elf'),
  require('./races/fairy'),
  require('./races/filborg'),
  require('./races/genasi'),
  require('./races/giff'),
  require('./races/giff_UA_2021_10_08'),
  require('./races/gith'),
  require('./races/glitchling'),
  require('./races/gnome'),
  require('./races/goblin'),
  require('./races/goliath'),
  require('./races/grung'),
  require('./races/hadozee'),
  require('./races/half_elf'),
  require('./races/half_orc'),
  require('./races/halfling'),
  require('./races/harengon'),
  require('./races/hexblood'),
  require('./races/hobgoblin'),
  require('./races/human'),
  require('./races/kalashtar'),
  require('./races/kender'),
  require('./races/kenku'),
  require('./races/kobold'),
  require('./races/leonin'),
  require('./races/lizardfolk'),
  require('./races/locathah'),
  require('./races/loxodon'),
  require('./races/minotaur'),
  require('./races/orc'),
  require('./races/owlin'),
  require('./races/plasmoid'),
  require('./races/reborn'),
  require('./races/satyr'),
  require('./races/shifter'),
  require('./races/simic_hybrid'),
  require('./races/tabaxi'),
  require('./races/thri_kreen'),
  require('./races/tiefling'),
  require('./races/tortle'),
  require('./races/triton'),
  require('./races/vedalken'),
  require('./races/verdan'),
  require('./races/warforged'),
  require('./races/yuan_ti_pureblood'),
]

import {
  faFrog,
  faCat,
  faSeedling,
  faMale,
  faGavel,
  faGrimace,
  faHamburger,
  faDragon,
  faTools,
  faHandRock,
  faFistRaised,
  faRobot,
  faMountain,
  faDove,
  faEgg,
} from '@fortawesome/free-solid-svg-icons'
import {
  faEnvira,
  faFreebsd,
} from '@fortawesome/free-brands-svg-icons'

import upLetter from '@/utils/upLetter'
import sortByText from '@/utils/sortByText'
import {
  pcRaceCollection,
} from '@/constants/pcRaceList'
import {
  PC_RACE_AARAKOCRA,
  PC_RACE_DRAGONBORN,
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_GNOME,
  PC_RACE_GOLIATH,
  PC_RACE_GRUNG,
  PC_RACE_GOBLIN,
  PC_RACE_HALF_ELF,
  PC_RACE_HALF_ORC,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
  PC_RACE_ORC,
  PC_RACE_TABAXI,
  PC_RACE_TIEFLING,
  PC_RACE_TORTLE,
  PC_RACE_WARFORGED,
} from '@/constants/pcRaceIdList'

const raceList = [
  {
    text: upLetter(pcRaceCollection[PC_RACE_HUMAN].name.plural.nominative),
    value: PC_RACE_HUMAN,
    icon: faMale,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_GNOME].name.plural.nominative),
    value: PC_RACE_GNOME,
    icon: faTools,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_GOLIATH].name.plural.nominative),
    value: PC_RACE_GOLIATH,
    icon: faMountain,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_TABAXI].name.plural.nominative),
    value: PC_RACE_TABAXI,
    icon: faCat,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_GRUNG].name.plural.nominative),
    value: PC_RACE_GRUNG,
    icon: faFrog,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_DWARF].name.plural.nominative),
    value: PC_RACE_DWARF,
    icon: faGavel,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_DRAGONBORN].name.plural.nominative),
    value: PC_RACE_DRAGONBORN,
    icon: faDragon,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_ELF].name.plural.nominative),
    value: PC_RACE_ELF,
    icon: faEnvira,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_HALF_ELF].name.plural.nominative),
    value: PC_RACE_HALF_ELF,
    icon: faSeedling,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_HALFLING].name.plural.nominative),
    value: PC_RACE_HALFLING,
    icon: faHamburger,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_GOBLIN].name.plural.nominative),
    value: PC_RACE_GOBLIN,
    icon: faGrimace,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_ORC].name.plural.nominative),
    value: PC_RACE_ORC,
    icon: faHandRock,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_HALF_ORC].name.plural.nominative),
    value: PC_RACE_HALF_ORC,
    icon: faFistRaised,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_TIEFLING].name.plural.nominative),
    value: PC_RACE_TIEFLING,
    icon: faFreebsd,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_WARFORGED].name.plural.nominative),
    value: PC_RACE_WARFORGED,
    icon: faRobot,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_TORTLE].name.plural.nominative),
    value: PC_RACE_TORTLE,
    icon: faEgg,
  },
  {
    text: upLetter(pcRaceCollection[PC_RACE_AARAKOCRA].name.plural.nominative),
    value: PC_RACE_AARAKOCRA,
    icon: faDove,
  },
].sort(sortByText)

export default raceList

import _ from "lodash";

import generateRealGender from '@/utils/nameGeneration/generateRealGender'
import Name from '@/constructors/Name'
import { PC_RACE_HALFLING } from '@/constants/pcRaceIdList'

import generateNameListHalfling from "./utils/generateNameListHalfling"
import generateFamilyNameHalfling from "./utils/generateFamilyNameHalfling"

export default ({genderId: genderIdInput}) => {
  const genderId = generateRealGender(genderIdInput)

  return new Name({
    firstName: _.sample(generateNameListHalfling(genderId)),
    lastName: generateFamilyNameHalfling(genderId),
    genderId,
    raceId: PC_RACE_HALFLING,
  })
}

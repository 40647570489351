const {
  SOURCE_ERRATA_VGTM,
  SOURCE_VGTM,
} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_TRITON} = require ('./../../pcRaceIdList')
const {
  SETTING_FORGOTTEN_REALMS,
  SETTING_THEROS,
} = require ('./../../settingList')
const {
  PARAM_STR,
  PARAM_CON,
  PARAM_CHA,
} = require ('./../../paramList')
const {
  SPELL_FOG_CLOUD,
  SPELL_GUST_OF_WIND,
  SPELL_WALL_OF_WATER,
} = require ('./../../spellIdList')

module.exports = {
  id: PC_RACE_TRITON,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_TRITON].name,
  nameEn: creatureTypeCollection[PC_RACE_TRITON].nameEn,
  note: creatureTypeCollection[PC_RACE_TRITON].note,
  description: [
    ...creatureTypeCollection[PC_RACE_TRITON].description,
    {
      header: 'Особенности тритонов',
      text: `Ваш персонаж-тритон обладает следующими особенностями.

# Увеличение характеристик

Сила, Телосложение и Харизма увеличиваются на 1.

# Возраст

Тритоны достигают зрелости к 15 годам, и могут дожить до 200 лет.

# Мировоззрение

Тритоны склонны к принципиально-доброму мировоззрению. Как стражей самых тёмных морских глубин, их культура склоняет их к порядку и добродетельности.

# Размер

Тритоны немного ниже чем люди, в среднем около 5 футов в высоту. Ваш размер — Средний.

# Скорость

Ваша базовая скорость ходьбы — 30 футов, также Вы обладаете скоростью плавания 30 футов.

# Амфибия

Вы можете дышать и воздухом, и под водой.

# Управление воздухом и водой

Как дитя моря, Вы можете воззвать к стихийной магии воздуха и воды. 

Вы можете сотворить наложить [Туманное облако](SPELL:${SPELL_FOG_CLOUD}).

Начиная с 3 уровня, Вы также можете сотворить [Порыв ветра](SPELL:${SPELL_GUST_OF_WIND}).

Начиная с 5 уровня, Вы также можете сотворить [Стену воды](SPELL:${SPELL_WALL_OF_WATER}).

Сотворив этой способностью заклинание, Вы не сможете сделать это снова, пока не завершите длинный отдых. Харизма — Ваша заклинательная характеристика для этих заклинаний.

# Тёмное зрение

На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Посланник моря

У водных животных есть исключительная близость к Вашему народу.

Вы можете передавать простые идеи животным, которые могут дышать под водой. Они могут понимать значение Ваших слов, хотя у Вас нет никакой специальной способности понять их в ответ.

# Стражи глубин

Приспособленность к даже самым экстремальным океанским глубинам даёт Вам сопротивление к урону холодом.

# Языки

Вы можете говорить, читать и писать на Всеобщем и Первичном языках.`,
      source: [
        {
          id: SOURCE_VGTM,
          page: 119,
        },
        {
          id: SOURCE_ERRATA_VGTM,
          title: 'Добавлено тёмное зрение; убрано игнорирование глубинных угроз',
          page: 1,
        },
      ],
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 6,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 90,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 1,
    [PARAM_CON]: 1,
    [PARAM_CHA]: 1,
  },
  age: {
    adult: 15,
    max: 200,
  },
  setting: [
    SETTING_FORGOTTEN_REALMS,
    SETTING_THEROS,
  ],
  source: {
    id: SOURCE_VGTM,
    page: 119,
  },
}

const {SOURCE_UA_2022_03_08_HEROES_OF_KRYNN} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_KENDER} = require ('./../../pcRaceIdList')
const {SETTING_DRAGON_LANCE} = require ('./../../settingList')
const {SIZE_SMALL} = require ('./../../sizeList')

module.exports = {
  id: PC_RACE_KENDER,
  isReady: false,
  name: creatureTypeCollection[PC_RACE_KENDER].name,
  nameEn: creatureTypeCollection[PC_RACE_KENDER].nameEn,
  sizeType: SIZE_SMALL,
  setting: SETTING_DRAGON_LANCE,
  source: {
    id: SOURCE_UA_2022_03_08_HEROES_OF_KRYNN,
    page: 21,
  },
}

const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {CREATURE_TYPE_WARFORGED} = require ('./../../creatureTypeIdList')
const {PC_RACE_WARFORGED} = require ('./../../pcRaceIdList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {SOURCE_ERFTLW} = require ('./../../sourceList')
const {SETTING_EBERRON} = require ('./../../settingList')
const {LANG_COMMON} = require ('./../../languageIdList')

const {
  PARAM_ANY,
  PARAM_CON,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_WARFORGED,
  isReady: true,
  name: creatureTypeCollection[CREATURE_TYPE_WARFORGED].name,
  nameEn: creatureTypeCollection[CREATURE_TYPE_WARFORGED].nameEn,
  note: {
    text: `
— „Укол был построен по плану, а ты — по случайности.“ — сказала Лакаштай. „Важна сама душа, а не форма её сосуда“.

— „Что заставляет тебя считать что у него есть душа?“ — спросил Герион.

— „А что заставляет тебя считать что она есть у тебя?“`,
    author: `Кейт Бэйкер, «Расколотая земля»`,
  },
  description: [
    ...creatureTypeCollection[CREATURE_TYPE_WARFORGED].description,
    {
      header: `Живые сталь и камень`,
      text: `Кованые сформированы из сплава органических и неорганических материалов. Их каркас сделан из стали, тёмнодрева или камня. Каркас обвивают мускулы — корнеподобные шнуры, пропитанные алхимическими жидкостями. Бронированные пластины формируют внешнюю защитную оболочку и укрепляют шарниры. Конструкция лиц кованых схожа — откидная шарнирная челюсть, глаза-кристаллы и усиленная надбровная кромка. Кроме этих общих для всех кованых элементов дизайна, материалы и конструкция каждого конкретного кованого различаются, в зависимости от задач, для которых он был спроектирован.

Хотя кованые и были изготовлены, они — живые гуманоиды. Отдых, лечащая магия и навык Медицины оказывают на них те же эффекты, что и на прочих гуманоидов.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 36,
      },
    },
    {
      header: `Личности кованых`,
      text: `Кованые были построены чтобы служить и драться. Большую часть своего существования у кованых были ясные задачи и они были рады выполнять свои роли. Договор Престолов даровал им свободу, но многие кованые всё ещё пытаются найти своё место в послевоенном мире или найти создавших их существ.

Типичный кованый малоэмоционален. Многие кованые выбирают себе совершенно конкретную задачу. Например — защиту союзников, исполнение договора или исследование земель. Кованый относится к выполнению этой задачи так же старательно, как раньше относился к войне. В то же время существуют кованые, которым нравится исследовать свои чувства, свободу и отношения с другими. Большинство кованых не интересуется религией, но некоторые, в поисках высшей цели и скрытого смысла принимают веру и мистицизм.

Тело типичного кованого бесполо. Некоторые из кованых полностью игнорируют само понятие пола, в то время как другие выбирают свою гендерную идентичность.

Чем больше кованый развивает индивидуальность, тем более вероятно что он будет модифицировать своё тело и будет заинтересован в изобретателе для изменений вида своей брони, конечностей и лица.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 36,
      },
    },
    {
      header: `Причуды`,
      text: `Кованые часто имеют одну-две странных черт характера, в зависимости от того, насколько они неопытны в окружающем мире. Таблица «Причуды кованых» предлагает примеры таких странностей.

# Причуды кованых

| к8 | Причуда |
|-|-|
| 1 | Вы анализируете потенциальную угрозу, исходящую, по Вашему мнению, от каждого встреченного Вами существа. Вслух.  |
| 2 | Вы часто неправильно понимаете эмоциональные сигналы. |
| 3 | Вы яростно защищаете друзей. |
| 4 | Вы примеряете военную дисциплину к любой ситуации. |
| 5 | Вы не знаете как фильтровать свои чувства и склонны к резким эмоциональным всплескам. |
| 6 | Вы не понимаете концепцию одежды помимо её утилитарного предназначения и полагаете что по ней определяются функции носителя. |
| 7 | Вы одержимы своей внешностью — постоянно чиститесь и полируетесь. |
| 8 | Война — единственное что для Вас имеет смысл, поэтому Вы постоянно ищете драки. |
`,
      source: {
        id: SOURCE_ERFTLW,
        page: 36,
      },
    },
    {
      header: `Имена кованых`,
      text: `Большинство кованых во время военной службы имели номерные знаки. Многие из них стали использовать прозвища, часто даваемые им товарищами. Будучи независимыми личностями, некоторые выбрали новые имена, чтобы выразить свой жизненный путь. Некоторые выбирают человеческие имена, зачастую в честь павшего друга или наставника.

# Имена кованых

Бастион, Булава, Вмятина, Глефа, Дуб, Жезл, Железо, Знамя, Клинок, Красный, Кристалл, Кузнец, Лук, Молот, Нож, Оникс, Пять, Разведчик, Разрез, Ржавь, Семь, Синий, Стена, Телега, Убежище, Удача, Укол, Храм, Хруст, Церковь, Шип, Штаны, Щит, Якорь`,
      source: {
        id: SOURCE_ERFTLW,
        page: 37,
      },
    },
    {
      header: `Особенности кованых`,
      text: `Ваш персонаж-кованый обладает следующими особенностями. Некоторые из них дают Вам выбор. Примите во внимание то, как Ваш выбор отражает цель, для которой был спроектирован Ваш кованый.

# Увеличение характеристик

Телосложение увеличивается на 2.

Одна другая характеристика на Ваш выбор увеличивается на 1.

# Возраст

Типичный кованый возрастом от двух до тридцати лет. Максимальная продолжительность жизни кованого остаётся загадкой; кованые не выказывают никаких признаков износа с возрастом.

Вы иммунны к эффектам магического старения.

# Мировоззрение

Для многих кованых комфортны порядок и дисциплина, со склонностью к принципам и нейтральности. Но другие принимают для себя мораль (или её отсутствие) сущностей, которым служат.

# Размер

Ваш размер — Средний.

Чтобы случайно определить Ваш рост и вес, определите модификаторы.

## Модификатор размера

2к6 

## Рост

5 футов + 10 дюймов + Модификатор размера (в дюймах)

## Вес

270 фунтов + (4 × Модификатор размера)

# Скорость

Ваша скорость ходьбы 30 футов.

# Спроектированная устойчивость

Вы были созданы иметь значительную прочность, что проявляется следующими преимуществами

* Вы получаете преимущество к испытаниям от отравления.
* Вы получаете сопротивление к урону ядом.
* Вы иммунны к болезням
* Вам не нужно есть, пить или дышать.
* Вам не нужно спать и магия не может Вас усыпить.

# Охранный отдых

Когда Вы совершаете длинный отдых, то вместо сна Вы проводите не менее 6 часов в неактивном, неподвижном состоянии. В этом состоянии Вы кажетесь неактивным, но находитесь в сознании и можете видеть и слышать как обычно.

# Встроенная защита

Ваше тело оборудовано встроенными защитными слоями, которые можно ещё усилить ношением доспехов

* Вы получаете бонус +1 к КД
* Вы можете носить только доспехи, которыми владеете. Для надевания доспеха Вы должны вставлять его в своё тело в течение часа, всё это время его касаясь. Для снятия доспеха Вы должны вынимать его из своего тела в течение часа. Снятие или надевание доспеха можно совмещать с отдыхом.
* Пока Вы живы, Ваш доспех не может быть снят с Вашего тела против Вашей воли.

# Специализированный дизайн

Вы получаете владение одним навыком и одним инструментом по своему выбору. 

# Языки

Вы можете говорить, читать и писать на [всеобщем](LANG:${LANG_COMMON}) и одном другом языке по Вашему выбору.`,
      source: {
        id: SOURCE_ERFTLW,
        page: 37,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 5 * 12 + 10,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
    weight: {
      base: 270,
      coefDice: {
        diceCount: 4,
        diceType: 1,
      },
    },
  },
  paramBonuses: {
    [PARAM_CON]: 2,
    [PARAM_ANY]: 1,
  },
  age: {
    adult: 2,
    max: 30,
  },
  noHairs: true,
  setting: SETTING_EBERRON,
  source: {
    id: SOURCE_ERFTLW,
    page: 36,
  },
}

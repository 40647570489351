import _ from 'lodash'

import genderFieldList from '@/constants/nameGeneration/genderFieldList'
import {
  GENDER_ANY,
  GENDER_MIDDLE,
} from '@/constants/genderList'

const genderIdList = genderFieldList.map(({ value }) => value)

const generateRandomGender = (excludeMiddleGender = false) => _.sample(
  genderIdList.filter(
      e => e !== GENDER_ANY && (
        excludeMiddleGender
          ? e !== GENDER_MIDDLE
          : true
      )
    )
)

export default generateRandomGender

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_TETHYRIAN} = require('./../../../pcSubraceIdList')
const {SOURCE_PHB} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_TETHYRIAN,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: 'тетирец',
      [GENDER_FEMALE]: 'тетирка',
    },
    dual: {
      [GENDER_MALE]: 'тетирца',
      [GENDER_FEMALE]: 'тетирки',
    },
    plural: 'тетирцы',
  },
  nameEn: 'Tethyrian',
  description: `Распространившиеся вдоль всего побережья Меча в западной части Фаэруна, тетирцы являются людьми среднего сложения и роста со смуглой кожей, которая светлее у северных поселенцев. Цвет волос и глаз у них сильно различается, но коричневые волосы и голубые глаза — наиболее типичны.

Тетирцы в основном используют чондатанские имена.`,
  source: {
    id: SOURCE_PHB,
    page: 32,
  },
}

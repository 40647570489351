import _ from 'lodash'

import { PC_RACE_GOBLIN } from "@/constants/pcRaceIdList"
import Name from "@/constructors/Name"

import generateNameByParts from "./utils/generateNameGoblinByParts";
import generatePremadeNameListGoblin from "./utils/generatePremadeNameListGoblin";
import generateRealGender from '@/utils/nameGeneration/generateRealGender'

const GENERATED_NAME_COEF = 0.95

export default ({genderId: genderIdInput}) => {
  const genderId = generateRealGender(genderIdInput)

  return new Name({
    firstName: Math.random() <= GENERATED_NAME_COEF
      ? generateNameByParts()
      : _.sample(generatePremadeNameListGoblin({genderId})),
    genderId,
    raceId: PC_RACE_GOBLIN,
  })
}

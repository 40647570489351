const {
  SIZE_MEDIUM,
  SIZE_SMALL,
} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_VERDAN} = require ('./../../pcRaceIdList')
const {SOURCE_AI} = require ('./../../sourceList')
const {SETTING_FORGOTTEN_REALMS} = require ('./../../settingList')

const {
  PARAM_CON,
  PARAM_CHA,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_VERDAN,
  isReady: false,
  name: creatureTypeCollection[PC_RACE_VERDAN].name,
  nameEn: creatureTypeCollection[PC_RACE_VERDAN].nameEn,
  note: creatureTypeCollection[PC_RACE_VERDAN].note,
  description: [
    ...creatureTypeCollection[PC_RACE_VERDAN].description,
    {
      header: 'Особенности вердан',
      text: `Verdan characters have a number of traits in common with others of their kind.

# Ability Score Increase

Your Constitution score increases by 1, and your Charisma score increases by 2.

# Age

Verdan reach adulthood at around the age of 24, and it is thought that they might live to nearly 200 years old. However, because no verdan has died of old age since the race's initial creation, their upper age limits remain subject to speculation.

# Alignment

Verdan are generally good, although their absence of racial identity and shared history can sometimes see individual verdan become untethered from any moral or ethical framework.

# Size

Verdan start out similar in size to the goblins they were created from, ranging from 3 to 4 feet in height. But at some point after reaching maturity, each verdan undergoes a sudden growth spurt of 2 feet or more. At 1st level, you are a Small creature. When you reach 5th level, you become a Medium creature.

# Speed

Your base walking speed is 30 feet.

# Black Blood Healing

The black blood that is a sign of your people's connection to That-Which-Endures boosts your natural healing. When you roll a 1 or 2 on any Hit Die you spend at the end of a short rest, you can reroll the die and must use the new roll.

# Limited Telepathy

You can telepathically speak to any creature you can see within 30 feet of you. You don't need to share a language with the creature for it to understand your telepathy, but it must be able to understand at least one language. This process of communication is slow and limited, allowing you to transmit and receive only simple ideas and straightforward concepts.

# Persuasive

Your people's Jack of history makes you trustworthy and humble. You have proficiency in the Persuasion skill.

# Telepathic Insight

Your mind's connection to the world around you strengthens your will. You have advantage on all Wisdom and Charisma saving throws.

# Languages

You speak, read, and write Common, Goblin, and one additional language of your choice. This language typically has some connection to one of the areas or cultures that has been part of your life.`,
      source: {
        id: SOURCE_AI,
        page: 75,
      },
    },
  ],
  sizeType: [
    SIZE_MEDIUM,
    SIZE_SMALL,
  ],
  age: {
    adult: 24,
    max: 200,
  },
  paramBonuses: {
    [PARAM_CHA]: 2,
    [PARAM_CON]: 1,
  },
  setting: SETTING_FORGOTTEN_REALMS,
  source: {
    id: SOURCE_AI,
    page: 75,
  },
}

const {SOURCE_SAIS} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {CREATURE_TYPE_GIFF} = require ('./../../creatureTypeIdList')
const {PC_RACE_GIFF} = require ('./../../pcRaceIdList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {
  SETTING_DRAGON_LANCE,
  SETTING_GREYHAWK,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')
const customLanguages = require ('./../constants/customLanguages')
const customParamBonuses = require ('./../constants/customParamBonuses')

module.exports = {
  id: PC_RACE_GIFF,
  isReady: true,
  name: creatureTypeCollection[CREATURE_TYPE_GIFF].name,
  nameEn: creatureTypeCollection[CREATURE_TYPE_GIFF].nameEn,
  sizeType: SIZE_MEDIUM,
  setting: [
    SETTING_DRAGON_LANCE,
    SETTING_GREYHAWK,
    SETTING_FORGOTTEN_REALMS,
  ],
  description: [
    ...creatureTypeCollection[CREATURE_TYPE_GIFF].description,
    {
      header: 'Гиффы',
      text: `Гиффы — высокий широкоплечий народ, внешне схожий с бегемотами. У одних гиффов гладкая кожа, а у других она покрыта короткими щетинками на лице и макушке головы. Как существа внушительных размеров и незабываемой внешности, гиффы везде привлекают внимание.

Большинство гиффов верит что они родом с одного конкретного мира, но их родной мир ныне упоминается лишь в легендах, потому как нет живых гиффов, что его видели или знают где он. Сотворившие гиффов божественные сущности ныне тоже забыта. Их титанические окаменевшие тела дрейфуют по Астральному Морю, заброшенные и уже совершенно неузнаваемые.

Хотя сами гиффы этого и не понимают, но их народ привязан к Астральному Плану, на глубоком психическому уровне сохраняя связь с своими богами-создателями, обладающими как раз достаточной божественной искрой, дабы наделить весь народ гиффов их собственными искрами. Гиффы научились направлять собственные искры, питая ими своё оружие. Большинство гиффов понятия не имеет откуда берётся так называемая астральная искра, но они особенно сильно чувствуют её наличие в Диком Космосе и Астральном Море.

Гиффы разделены на два лагеря в зависимости от того, как они произносят своё самоназвание. Половина из них произносят твёрдое «гифф», а другая половина — мягкое «жифф». Разногласия по поводу правильного произношения часто перерастают в обиды, громкие споры и бодание лбами, но редко перерастают во что-то более серьезное.`,
      source: {
        id: SOURCE_SAIS,
        page: 12,
      },
    },
    {
      header: 'Особенности гиффов',
      text: `Вы получаете следующие расовые особенности 

# Тип существа

Вы — гуманоид.

# Размер

Ваш размер — Средний.

${customParamBonuses}

# Скорость

Ваша скорость ходьбы 30 футов. Вы получаете скорость плавания, равную скорости ходьбы.

# Астральная искра

Ваше психическое единение с Астральным Планом даёт Вам мистический доступ к искре божественной силы. Вы можете наполнять этой искрой оружие.

Когда Вы попадаете по цели простым или воинским оружием, Вы можете заставить цель получить дополнительный урон силовым полем, равный Вашему бонусу мастерства.

Вы можете использовать эту способность число раз, равное Вашему бонусу мастерства (но не более раза в ход) и восстанавливаете все использования, оканчивая длинный отдых.

# Владение огнестрелом

У Вас есть мистическое сродство с огнестрелом. Оно досталось Вам от богов гиффов, которые восхищались этим видом оружия. Вы владеете всем огнестрельным оружием и игнорируете у него свойство Перезарядка. Кроме того, совершение атаки огнестрелом в пределах его максимальной дистанции не вызывает у Вас помеху к броску атаки.

# Телосложение бегемота

Вы получаете преимущество на все проверки Силы и испытания Силы. Кроме того, Вы считаетесь на один размер больше, когда определяется Ваша грузоподъемность и вес, который Вы можете толкать, тянуть или поднимать.

${customLanguages}`,
      source: {
        id: SOURCE_SAIS,
        page: 12,
      },
    },
  ],
  source: {
    id: SOURCE_SAIS,
    page: 12,
  },
}

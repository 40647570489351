const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_CON} = require ('./../../../paramList')
const {PC_RACE_HALFLING} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HALFLING_STOUT} = require('./../../../pcSubraceIdList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HALFLING_STOUT,
  parentRaceId: PC_RACE_HALFLING,
  name: {
    singular: {
      [GENDER_MALE]: 'коренастый полурослик',
      [GENDER_FEMALE]: 'коренастая полурослица',
    },
    dual: {
      [GENDER_MALE]: 'коренастая полурослика',
      [GENDER_FEMALE]: 'коренастых полурослицы',
    },
    plural: 'коренастые полурослики',
  },
  nameAlt: `сильные сердцем`,
  nameShort: {
    singular: {
      [GENDER_MALE]: 'коренастый',
      [GENDER_FEMALE]: 'коренастая',
    },
    dual: {
      [GENDER_MALE]: 'коренастая',
      [GENDER_FEMALE]: 'коренастых',
    },
    plural: 'коренастые',
  },
  nameEn: `Stout Halflings`,
  nameEnAlt: `Stronghearts`,
  description: [
    {
      header: 'Сильные сердцем полурослики',
      text: `Существа земли, любящие теплый уют и хорошую компанию — сильные сердцем полурослики тот народ, у которого мало врагов и много друзей. Прочие расы иногда ласково именуют сильных сердцем полуросликов _«добрым людом»_ так как мало что может омрачить их дух или развратить их сердца. Для многих из них, нет ничего страшнее жизни в мире, лишённом хорошей компании, свободы, дружеского комфорта и полном злых намерений.

Когда сильные сердцем полурослики собираются осесть на определённом месте, они собираются осесть на долго. Проживание какой-то династии сильных сердцем полуросликов на одном месте на протяжении столетий не является чем-то необычным. Сильные сердцем полурослики не стремятся к уединению своей общины. Напротив, развиваясь, они делают всё, чтобы вписаться в местный социум и стать его неотъемлемой частью. Эта точка зрения выделяет кооперацию сильнее прочих черт, а способность к плодотворному сотрудничеству ценится в их землях превыше всего.

Вынужденные сняться со своих мест, сильные сердцем полурослики обычно стараются взять с собой как можно больше вещей, напоминающих им о домашнем комфорте. Представители других рас, склонные к большей практичности, находят эти их особенности путешествий сумасбродными, но их легконогих кузенов обычно развлекают такие новшества — пока им не приходится нести никакого багажа.

Несмотря на то, что о них часто думают, как о толстых и ленивых домоседах, повёрнутых на хорошей еде, обычно, сильные сердцем полурослики достаточно трудолюбивы. Тонкие руки, терпеливый склад ума и тяга к качеству делает их прекрасными ткачами, резчиками по дереву, мастерами лозоплетения, художниками и фермерами.

Сильные сердцем полурослики имеют расовые черты коренастых полуросликов из Книги Игрока. Они немногим ниже своих легконогих сородичей и часто более круглолицые. Цвет их кожи и волос такой же, как и у людей, но превалируют каштановые волосы. В отличие от их легконогих кузенов, сильные сердцем полурослики чаще бывают блондинами или совсем тёмными брюнетами и обладают голубыми или зелёными глазами. Мужчины не отпускают бород и усов, но у обоих полов растут бакенбарды до средины щеки, которые они заплетают в длинные косы.`,
      source: {
        id: SOURCE_SCAG,
        page: 110,
      },
    },
    {
      header: 'Коренастые полурослики',
      text: `Коренастые полурослики выносливее других и обладают некоторой устойчивостью к ядам. Поговаривают, что в их жилах течёт толика дварфийской крови. В мире Забытых Королевств таких полуросликов зовут сильными сердцем, и чаще всего они встречаются на юге.`,
      source: {
        id: SOURCE_PHB,
        page: 29,
      },
    },
    {
      header: 'Особенности коренастых полуросликов',
      text: `
# Увеличение характеристик

Телосложение увеличивается на 1.

# Устойчивость коренастых

Вы совершаете с преимуществом испытания от яда, и получаете сопротивление к урону ядом.`,
      source: {
        id: SOURCE_PHB,
        page: 29,
      },
    },
  ],
  paramBonuses: {
    [PARAM_CON]: 1,
  },
  source: {
    id: SOURCE_PHB,
    page: 29,
  },
}

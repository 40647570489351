import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import "./ResultViewStyles.less"

const ResultViewComponent = ({ result, refForClick, selectText, savedItemView }) => (
  <p
    className={classNames(
      'ResultView',
      {
        'ResultView-savedItemView': savedItemView,
      },
    )}
    children={result}
    ref={refForClick}
    onClick={selectText}
  />
)

ResultViewComponent.propTypes = {
  savedItemView: PropTypes.bool,
  selectText: PropTypes.func.isRequired,
  result: PropTypes.string,
  ref: PropTypes.object.isRequired,
}

ResultViewComponent.defaultProps = {
  savedItemView: false,
  result: '',
}

export default ResultViewComponent

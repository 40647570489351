const {SOURCE_PHB} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PARAM_DEX} = require ('./../../paramList')
const {PC_RACE_ELF} = require ('./../../pcRaceIdList')
const {PC_CLASS_BARD} = require ('./../../pcClassIdList')
const {
  SETTING_DRAGON_LANCE,
  SETTING_EBERRON,
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
  SETTING_GREYHAWK,
  SETTING_RAVNICA,
} = require ('./../../settingList')

module.exports = {
  id: PC_RACE_ELF,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_ELF].name,
  nameEn: creatureTypeCollection[PC_RACE_ELF].nameEn,
  note: creatureTypeCollection[PC_RACE_ELF].note,
  nameEnAlt: creatureTypeCollection[PC_RACE_ELF].nameEnAlt,
  nameAlt: creatureTypeCollection[PC_RACE_ELF].nameAlt,
  description: [
    ...creatureTypeCollection[PC_RACE_ELF].description,
    {
      header: `Особенности эльфов`,
      text: `Ваш персонаж эльф обладает рядом разнообразных качеств, отражающих тысячелетия эльфийского совершенствования.

# Увеличение характеристик

Ловкость увеличивается на 2.

# Возраст

Несмотря на то, что физически эльфы взрослеют в том же возрасте, что и люди, их понимание о взрослении выходит за рамки физического развития, и располагается в сфере житейского опыта. Обычно эльф получает статус взрослого и взрослое имя в возрасте 100 лет, и может прожить до 750 лет.

# Мировоззрение

Эльфы любят свободу, разнообразие и самовыражение. Таким образом, они относятся к добрым аспектам хаоса. Они ценят защиту чужой свободы так же как и своей, и чаще они скорее добры, чем нет. Исключением из этого правила являются дроу. Их изгнание в Подземье сделало их злобными и опасными. Дроу чаще являются злыми.

# Размер

Рост эльфов колеблется между 5 и 6 футами (152 и 183 сантиметрами), у них стройное телосложение. Ваш размер — Средний.

# Скорость

Ваша базовая скорость перемещения — 30 футов.

# Тёмное зрение

Привыкнув к сумраку леса и ночному небу, Вы обладаете превосходным зрением в темноте и при тусклом освещении. На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Обострённые чувства

Вы владеете навыком Внимательность.

# Наследие фей

Вы получаете преимущество на испытания от Очарования, и Вас невозможно магически усыпить.

# Транс

Эльфы не спят. Вместо этого они погружаются в глубокую медитацию, находясь в полубессознательном состоянии до 4 часов в сутки (обычно такую медитацию называют трансом). Во время этой медитации Вы можете грезить о разных вещах. Некоторые из этих грёз являются ментальными упражнениями, выработанными за годы тренировок. После такого отдыха Вы получаете все преимущества, которые получает человек после 8 часов сна.

# Языки

Вы можете говорить, читать и писать на Всеобщем и Эльфийском языках. Эльфийский язык текучий, с утончёнными интонациями и сложной грамматикой. Эльфийская литература богата и разнообразна, а стихи и песни известны среди представителей других рас. Многие [барды](PC_CLASS:${PC_CLASS_BARD}) учат эльфийский язык, чтоб добавить песни в свой репертуар.

# Разновидности

Древний раскол среди эльфийских народов привёл к возникновению трёх видов: высших эльфов, лесных эльфов и тёмных эльфов, которых обычно называют дроу. Выберите один из этих видов. В некоторых мирах они делятся ещё сильнее, (например на солнечных и лунных эльфов в мире Забытых Королевств) так что если хотите, можете выбрать более уточнённый вариант.`,
      source: {
        id: SOURCE_PHB,
        page: 24,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  paramBonuses: {
    [PARAM_DEX]: 2,
  },
  age: {
    adult: 100,
    max: 750,
  },
  setting: [
    SETTING_DRAGON_LANCE,
    SETTING_EBERRON,
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
    SETTING_GREYHAWK,
    SETTING_RAVNICA,
  ],
  source: {
    id: SOURCE_PHB,
    page: 22,
  },
}

import Name from '@/constructors/Name'
import { PC_RACE_HUMAN } from '@/constants/pcRaceIdList'

import nameListTribe from "./dict/names_tribe"
import nameListFemale from "./dict/names_female"
import nameListMale from "./dict/names_male"

import generateFullNameHumanCommon from "../../utils/generateFullNameHumanCommon"

const fullNameGenerator = ({firstName, genderId, surName: tribe}) =>  new Name({
  firstName,
  tribe,
  genderId,
  raceId: PC_RACE_HUMAN,
})

export default generateFullNameHumanCommon({
  nameListFamily: nameListTribe,
  nameListFemale,
  nameListMale,
  fullNameGenerator,
})

const {
  GEAR_LONGBOW,
  GEAR_LONGSWORD,
  GEAR_SHORTBOW,
  GEAR_SHORTSWORD,
} = require ('./../../gearIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
} = require ('./../../sourceList')
const {PARAM_INT} = require ('./../../paramList')
const {PC_CLASS_WIZARD} = require ('./../../pcClassIdList')
const {PC_RACE_ELF} = require ('./../../pcRaceIdList')

const phbSizeSource = require ('./phbSizeSource')

module.exports = {
  parentRaceId: PC_RACE_ELF,
  size: {
    tall: {
      base: 4 * 12 + 6,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 90,
      coefDice: {
        diceCount: 1,
        diceType: 4,
      },
    },
    source: phbSizeSource,
  },
  description: [
    `Поскольку Вы — высший эльф, у Вас острый ум и Вы знакомы, по крайней мере, с основами магии. Во многих мирах D&D существует два вида высших эльфов. Один вид (который включает серых эльфов и эльфов долин Серого Ястреба, сильванести Саги о Копье и солнечных эльфов Забытых Королевств) высокомерен и замкнут, считая себя выше не-эльфов и даже других эльфов. Другой вид (включающий высших эльфов Серого Ястреба, квалинести из Саги о Копье и лунных эльфов из Забытых Королевств) более распространён и дружелюбен, и часто встречается среди людей и других рас.

У солнечных эльфов Фаэруна (также называемых золотыми эльфами или эльфами восхода) бронзовая кожа и волосы медного, чёрного или золотистого оттенка. У них золотые, серебристые или чёрные глаза. Лунные эльфы (также называемые серебряными или серыми эльфами) гораздо бледнее, с алебастровой кожей, имеющей иногда оттенок синего. У них часто серебристо-белые, чёрные или синие волосы, но и различные оттенки светлых, коричневых и рыжих тонов также не являются редкими. У них синие или зелёные глаза с золотыми вкраплениями.

# Увеличение характеристик

Интеллект увеличивается на 1.

# Владение эльфийским оружием

Вы владеете [длинными мечами](GEAR:${GEAR_LONGSWORD}), [короткими мечами](GEAR:${GEAR_SHORTSWORD}), [короткими](GEAR:${GEAR_SHORTBOW}) и [длинными](GEAR:${GEAR_LONGBOW}) луками.

# Заговор

Вы знаете один заговор из списка заклинаний [Волшебника](PC_CLASS:${PC_CLASS_WIZARD}). Заклинательной характеристикой для его использования является Интеллект.

# Дополнительный язык

Вы можете говорить, читать и писать на ещё одном языке, на Ваш выбор.`,
    {
      header: 'Высшие эльфы',
      text: `Высшие эльфы происходят из загадочной Страны Фей. Они высоко ценят магию в любых её видах — и даже те, кто не изучает заклинания, умеют управлять Плетением.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  paramBonuses: {
    [PARAM_INT]: 1,
  },
  source: {
    id: SOURCE_PHB,
    page: 24,
  },
}

import _ from "lodash";

import generateRealGender from "@/utils/nameGeneration/generateRealGender"
import Name from "@/constructors/Name"
import { PC_RACE_GNOME } from "@/constants/pcRaceIdList"

import generateNameListGnome from "./utils/generateNameListGnome"
import generateNickNameListGnome from "./utils/generateNickNameListGnome"
import clanNameList from "./dict/names_clan"

const NICKNAME_MAX = 5

const generateNickName = (genderId) => {
  let nickNameCount = Math.ceil(Math.random() * NICKNAME_MAX)
  const nickNameList = []

  while (nickNameCount--) {
    const nickName = _.sample(generateNickNameListGnome(genderId))
    if (nickNameList.includes(nickName)) {
      nickNameCount++
    } else {
      nickNameList.push(nickName)
    }
  }

  return nickNameList.join(', ')
}

export default ({genderId: genderIdInput}) => {
  const genderId = generateRealGender(genderIdInput)

  return new Name({
    firstName: _.sample(generateNameListGnome(genderId)),
    nickName: generateNickName(genderId),
    clan: _.sample(clanNameList),
    genderId,
    raceId: PC_RACE_GNOME,
  })
}

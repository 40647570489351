const {SOURCE_GGTR} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_SIMIC_HYBRID} = require ('./../../pcRaceIdList')
const {SETTING_RAVNICA} = require ('./../../settingList')

const {
  PARAM_CON,
  PARAM_ANY,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_SIMIC_HYBRID,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_SIMIC_HYBRID].name,
  nameEn: creatureTypeCollection[PC_RACE_SIMIC_HYBRID].nameEn,
  description: [
    ...creatureTypeCollection[PC_RACE_SIMIC_HYBRID].description,
    {
      header: 'Особенности гибридов Симиков',
      text: `Ваш персонаж-гибрид обладает следующими расовыми особенностями.

# Увеличение характеристик

Телосложение увеличивается на 2.

Одна другая характеристика на Ваш выбор увеличивается на 1.

# Возраст

Гибриды начинают свою жизнь как взрослые люди, эльфы или ведалкены. Они стареют со слегка увеличенной скоростью, поэтому их максимальная продолжительность жизни, вероятно, несколько сокращена. Проект Стражник пока что длится недостаточно долго, чтобы можно было наблюдать полный эффект этого явления.

# Мировоззрение

Большинство гибридов разделяют в целом нейтральный взгляд Объединения Симиков. Она больше заинтересованы в научных исследованиях и защите интеерсов гильдии, чем в моральных и этических вопросах. Те же, кто покидает Объединение, часто делают это из-за того, что их философия и мировоззрение больше подходят какой-то другой гильдии.

# Размер

Ваш размер — Средний, в пределах нормального для вашей базовой гуманоидной расы.

# Скорость

Ваша базовая скорость ходьбы 30 футов.

# Тёмное зрение

На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Языки

Вы можете говорить, читать и писать на Всеобщем и на Ваш выбор — Эльфийском или языке Ведалкенов.

# Животное улучшение

Ваше тело изменилось, воплощая некоторые умения животных. Вы можете выбрать одно животное улучшение на 1 уровне и второе — на 5 уровне.

## 1 Уровень

На 1 уровне выберите один из следующих вариантов — _Плавники ската_, _Подводная адаптация_ или _Цепкие пальцы_.

### Плавники ската

У Вас есть скатоподобные плавники, которые Вы можете использовать как крылья для плавного планирования или замедления падения.

Если Вы падаете и дееспособны, то при рассчёте урона от падения Вы можете вычесть до 100 футов из высоты падения.

Кроме того, Вы можете сдвинуться на 2 фута по горизонтали за каждый фут спуска по вертикали.

### Подводная адаптация

Вы можете дышать воздухом и водой и получаете скорость плавания, равную Вашей скорости ходьбы.

### Цепкие пальцы

Вы получаете скорость лазания, равную Вашей скорости ходьбы.

## 5 Уровень

На 5 уровне Ваше тело развивается дальше, проявляя новые особенности. Выберите один из вариантов, не выбранных Вами на 1 уровне, или один из следующих — _Кислотный плевок_, _Панцирь_ или _Хватательные придатки_.

### Кислотный плевок

Вы можете действием распылить кислоту из гланд во рту, целясь в одно существо или предмет, видимый Вами в пределах 30 футов. Цель проходит испытание Ловкости (СЛ равна 8 + модификатор Телосложения + бонус мастерства), получая 2к10 урона кислотой при провале или половину при успехе.

Урон увеличивается на 1к10 при достижении Вами 11 (3к10) и 17 (4к10) уровней.

Вы можете использовать эту способность число раз, равное Вашему модификатору Телосложения (минимум один раз). Вы восстанавливаете все потраченные использования, когда заканчиваете длинный отдых.

### Панцирь

Ваша кожа местами покрывается твёрдым панцирем. Вы получаете +1 КД, если не носите тяжёлый доспех. 

### Хватательные придатки

У Вас есть два особых _придатка_, выросших рядом с руками. Выберите — клешни это или щупальца.

Вы можете действием использовать один из них, чтобы схватить существо. Каждый из _придатков_ считается природным оружием, которым Вы можете совершать безоружные атаки. Если Вы попадаете такой атакой, то цель получает 1к6 + модификатор Силы дробящего урона, вместо обычного урона безоружной атаки. Немедленно после попадания Вы можете бонусным действием попытаться схватить цель.

_Придатки_ не могут производить точные манипуляции и поэтому не нести держать оружие, магические предметы или прочее специализированное снаряжение.`,
      source: {
        id: SOURCE_GGTR,
        page: 21,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 8,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 110,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_CON]: 2,
    [PARAM_ANY]: 1,
  },
  age: {
    adult: 0,
    max: 100, // Unknown
  },
  setting: SETTING_RAVNICA,
  source: {
    id: SOURCE_GGTR,
    page: 21,
  },
}

import _ from 'lodash'
import {
  PC_RACE_AARAKOCRA,
  PC_RACE_ANY,
  PC_RACE_DRAGONBORN,
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_GNOME,
  PC_RACE_GOBLIN,
  PC_RACE_GOLIATH,
  PC_RACE_GRUNG,
  PC_RACE_HALF_ELF,
  PC_RACE_HALF_ORC,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
  PC_RACE_ORC,
  PC_RACE_TABAXI,
  PC_RACE_TIEFLING,
  PC_RACE_TORTLE,
  PC_RACE_WARFORGED,
} from '@/constants/pcRaceIdList'

import generateNameAarakocra from '../races/aarakocra/generateNameAarakocra'
import generateNameDragonborn from '../races/dragonborn/generateNameDragonborn'
import generateNameDwarf from '../races/dwarf/generateNameDwarf'
import generateNameElf from '../races/elf/generateNameElf'
import generateNameHalfling from '../races/halfling/generateNameHalfling'
import generateNameHuman from '../races/human/generateNameHuman'
import generateNameTabaxi from '../races/tabaxi/generateNameTabaxi'
import generateNameGrung from '../races/grung/generateNameGrung'
import generateNameGnome from '../races/gnome/generateNameGnome'
import generateNameHalfElf from '../races/halfelf/generateNameHalfElf'
import generateNameOrc from '../races/orc/generateNameOrc'
import generateNameHalfOrc from '../races/halforc/generateNameHalfOrc'
import generateNameTiefling from '../races/tiefing/generateNameTiefling'
import generateNameGoblin from '../races/goblin/generateNameGoblin'
import generateNameWarforged from '../races/warforged/generateNameWarforged'
import generateNameGoliath from '../races/goliath/generateNameGoliath'
import generateNameTortle from '../races/tortle/generateNameTortle'

const generateFullName = ({ raceId, ...rest }) => {
  switch (raceId) {
    case PC_RACE_AARAKOCRA:
      return generateNameAarakocra(rest)

    case PC_RACE_GNOME:
      return generateNameGnome(rest)

    case PC_RACE_DRAGONBORN:
      return generateNameDragonborn(rest)

    case PC_RACE_DWARF:
      return generateNameDwarf(rest)

    case PC_RACE_ELF:
      return generateNameElf(rest)

    case PC_RACE_HALF_ELF:
      return generateNameHalfElf(rest)

    case PC_RACE_HALFLING:
      return generateNameHalfling(rest)

    case PC_RACE_HUMAN:
      return generateNameHuman(rest)

    case PC_RACE_TABAXI:
      return generateNameTabaxi(rest)

    case PC_RACE_GRUNG:
      return generateNameGrung(rest)

    case PC_RACE_ORC:
      return generateNameOrc(rest)

    case PC_RACE_HALF_ORC:
      return generateNameHalfOrc(rest)

    case PC_RACE_TIEFLING:
      return generateNameTiefling(rest)

    case PC_RACE_GOBLIN:
      return generateNameGoblin(rest)

    case PC_RACE_WARFORGED:
      return generateNameWarforged(rest)

    case PC_RACE_GOLIATH:
      return generateNameGoliath(rest)

    case PC_RACE_TORTLE:
      return generateNameTortle(rest)

    case PC_RACE_ANY:
      return _.sample([
        generateNameDragonborn,
        generateNameDwarf,
        generateNameElf,
        generateNameGnome,
        generateNameGoblin,
        generateNameGrung,
        generateNameHalfElf,
        generateNameHalfling,
        generateNameHalfOrc,
        generateNameHuman,
        generateNameOrc,
        generateNameTabaxi,
        generateNameTiefling,
        generateNameWarforged,
        generateNameTortle,
      ])(rest)

    default:
      return 'NOT READY'
  }
}

export default generateFullName

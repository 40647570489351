import _ from "lodash";

import Name from "@/constructors/Name"

import generateNameListDwarf from "./utils/generateNameListDwarf"
import clanNameList from "./dict/names_clan"
import generateRealGender from '@/utils/nameGeneration/generateRealGender'
import { PC_RACE_DWARF } from '@/constants/pcRaceIdList'

export default ({genderId: genderIdInput}) => {
  const genderId = generateRealGender(genderIdInput)

  return new Name({
    firstName: _.sample(generateNameListDwarf(genderId)),
    clan: _.sample(clanNameList),
    genderId,
    raceId: PC_RACE_DWARF,
  })
}

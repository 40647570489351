import _ from "lodash";

import generateRealGender from '@/utils/nameGeneration/generateRealGender'
import Name from '@/constructors/Name'
import { PC_RACE_ORC } from '@/constants/pcRaceIdList'

import generateNameListOrc from "./utils/generateNameListOrc"

export default ({genderId: genderIdInput}) => {
  const genderId = generateRealGender(genderIdInput)

  return new Name({
    firstName: _.sample(generateNameListOrc(genderId)),
    genderId,
    raceId: PC_RACE_ORC,
  })
}

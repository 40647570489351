const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_RASHEMI} = require('./../../../pcSubraceIdList')
const {SOURCE_PHB} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_RASHEMI,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: 'рашеми',
      [GENDER_FEMALE]: 'рашеми',
    },
    dual: {
      [GENDER_MALE]: 'рашеми',
      [GENDER_FEMALE]: 'рашеми',
    },
    plural: 'рашеми',
  },
  nameEn: 'Rashemi',
  description: `Рашеми, обитающие к востоку от Внутреннего моря и часто смешивающиеся с муланами, обычно низкорослые, коренастые и мускулистые. У них, как правило, тёмная кожа, чёрные глаза, и густые чёрные волосы.

# Имена рашеми

## Мужские

Боривик, Владислак, Джандар, Канитар, Мадислак, Ральмевик, Фаургар, Шаумар

## Женские

Имзель, Иммит, Наварра, Таммит, Файварра, Хульмарра, Шеварра, Юльдра

## Фамилии

Дайрнина, Илтазяра, Мурнитара, Стаянога, Улмокина, Чергоба`,
  source: {
    id: SOURCE_PHB,
    page: 32,
  },
}

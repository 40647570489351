const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_HALRUAAN} = require('./../../../pcSubraceIdList')
const {SOURCE_SCAG} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_HALRUAAN,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: `халруанец`,
      [GENDER_FEMALE]: `халруанка`,
    },
    dual: {
      [GENDER_MALE]: `халруанца`,
      [GENDER_FEMALE]: `халруанки`,
    },
    plural: `халруане`,
  },
  nameEn: `Halruaan`,
  description: `Народ мистического и полного магии королевства Халруа, они отмечены магией и многие из них имеют талант к Искусству. Они и их страна исчезли во время Магической Чумы, то тем же мистическим образом вернулись после второго Раскола. Большинство Халруанцев — блондины или брюнеты и имеют оливковый цвет кожи. Черные, карие и зелёные глаза наиболее распространены.

# Халруанские имена

## Мужские

Альдим, Мелегост, Пресмир, Сандру, Урегонт, Чанд

## Женские

Аити, Олома, Сарэд, Фаэли, Чалан

## Фамилии

Авостэ, Даранте, Моурмериль, Стамарастер`,
  source: {
    id: SOURCE_SCAG,
    page: 112,
  },
}

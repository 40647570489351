const {CREATURE_GIANT_OWL} = require ('./../../../creatureIdList')
const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_WIT} = require ('./../../../paramList')
const {PC_RACE_HALFLING} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HALFLING_GHOST} = require('./../../../pcSubraceIdList')
const {SOURCE_SCAG} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HALFLING_GHOST,
  parentRaceId: PC_RACE_HALFLING,
  name: {
    singular: {
      [GENDER_MALE]: 'призрачный полурослик',
      [GENDER_FEMALE]: 'призрачная полурослица',
    },
    dual: {
      [GENDER_MALE]: 'призрачная полурослика',
      [GENDER_FEMALE]: 'призрачных полурослицы',
    },
    plural: 'призрачные полурослики',
  },
  nameShort: {
    singular: {
      [GENDER_MALE]: 'призрачный',
      [GENDER_FEMALE]: 'призрачная',
    },
    dual: {
      [GENDER_MALE]: 'призрачная',
      [GENDER_FEMALE]: 'призрачных',
    },
    plural: 'призрачные',
  },
  nameEn: `Ghost Halflings`,
  description: [
    {
      header: 'Призрачные полурослики',
      text: `Призрачные полурослики возводят свою родословную ко временам войн между племенами полуросликов, которые привели к исходу их предков из Луирена. Призрачные полурослики, встречающиеся реже всех прочих хин, живут только в Чондалвуде несколькими сплочёнными кланами в изоляции лесной глуши.

Многие призрачные кланы выбирают естественную особенность местности, как центр их территории, и члены клана всегда носят с собой частичку этой особенности с собой.

Воины клана, ещё называемые _«парящими в ночи»_, используют [гигантских сов](CREATURE:${CREATURE_GIANT_OWL}) как верховых животных и образовывают с ними связь.`,
      source: {
        id: SOURCE_SCAG,
        page: 111,
      },
    },
    {
      header: 'Особенности призрачных полуросликов',
      text: `Из-за того, что их народ имеет закрытую, клановую культуру, недоверчивую ко всему чужому, призрачные полурослики в качестве искателей приключений встречаются редко. Спросите своего Мастера, можете ли Вы играть представителем этой подрасы, которая имеет все расовые черты полуросликов из Книги Игрока, плюс черты подрасы приведенные ниже.

# Увеличение характеристик

Мудрость увеличивается на 1.

# Бесшумная речь

Вы можете общаться телепатически с любым существом в пределах 30 футов. Существо способно Вас понять только если Вы оба владеете каким-то общим языком. Вы можете одновременно общаться телепатически только с одним существом.`,
      source: {
        id: SOURCE_SCAG,
        page: 111,
      },
    },
  ],
  paramBonuses: {
    [PARAM_WIT]: 1,
  },
  source: {
    id: SOURCE_SCAG,
    page: 111,
  },
}

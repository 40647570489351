const {PC_RACE_GNOME} = require ('./../../../pcRaceIdList')
const {PARAM_DEX} = require ('./../../../paramList')
const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_SUBRACE_GNOME_DEEP} = require('./../../../pcSubraceIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_EE,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_GNOME_DEEP,
  parentRaceId: PC_RACE_GNOME,
  name: {
    singular: {
      [GENDER_MALE]: 'свирфнеблин',
      [GENDER_FEMALE]: 'свирфнеблинка',
    },
    dual: {
      [GENDER_MALE]: 'свирфнеблина',
      [GENDER_FEMALE]: 'свирфнеблинки',
    },
    plural: 'свирфнеблины',
  },
  nameShort: {
    singular: {
      [GENDER_MALE]: 'свирфнеблин',
      [GENDER_FEMALE]: 'свирфнеблинка',
    },
    dual: {
      [GENDER_MALE]: 'свирфнеблина',
      [GENDER_FEMALE]: 'свирфнеблинки',
    },
    plural: 'свирфнеблины',
  },
  nameEn: 'Svirfneblin',
  nameEnAlt: 'Deep gnomes',
  nameAlt: 'Глубинные гномы',
  description: [
    {
      header: `Глубинные гномы`,
      text: `Третья подраса гномов, глубинные гномы (или свирфнеблины), живут маленькими общинами, разбросанными в Подземье. В отличие от двергаров и дроу, свирфнеблины такие же хорошие, как их родственники с поверхности. Тем не менее, их юмор и энтузиазм омрачены гнетущей окружающей средой, и их изобретательское мастерство в основном выражается в работе с камнем.`,
      source: {
        id: SOURCE_PHB,
        page: 37,
      },
    },
    {
      header: `Глубинные гномы`,
      subHeader: `Свирфнеблины`,
      text: `Известные как свирфнеблины, глубинные гномы Подземья являют собой полную противоположность их надземных собратьев — представляя строгость и серьёзность, в отличие от веселья и общего оптимизма скальных и лесных гномов. Они разделяют тягу их собратьев к приватности, а их дома под поверхностью Фаэруна глубоко спрятаны и хорошо охраняются.

Благодаря враждебности их соседей по Подземью, в частности дроу, поселения и королевства свирфнеблинов находятся в постоянной опасности быть захваченными, уничтоженными или вынужденными сниматься с мест. Такая судьба постигла Блингденстоун, одну из величайших крепостей свирфнеблинов, которая существовала более двух тысячелетий, пока не была опустошена немногим более чем столетие назад тёмными эльфами Мензоберранзана. Глубинные гномы недавно вернули себе свой старый дом, и теперь борются со всем пагубным, что закралось в туннели и жилища в их отсутствие.

Глубинные гномы худощавы с тёмной, землистой кожей серых оттенков. Мужчины обычно лысы и не носят бороды, в то время как женщины имеют волосы на своих головах. Оба пола имеют мало волосяного покрова, а кожа их на вид похожа на камень.

Искатели приключений из глубинных гномов такие же любознательные и смелые, как и из прочих рас. Некоторые находят своё призвание живя среди других подземных рас, а некоторые из них проделывают свой путь на поверхность. Те из свирфнеблинов, кто изучает таинства искусства иллюзий часто отправляются из дому в дальний путь, в поисках знаний, недоступных в близлежащих землях.`,
      source: {
        id: SOURCE_SCAG,
        page: 116,
      },
    },
    {
      header: `Глубинные гномы`,
      text: `Лесные и скальные гномы часто встречаются в землях поверхностного мира. Существует ещё одна подраса гномов, которую редко можно увидеть на поверхности: это глубинные гномы, также известные как свирфнеблины. Осторожные и подозрительные к посторонним, свирфнеблины хитры и молчаливы, но могут быть столь же добрыми, верными и сострадательными, как и их собратья с поверхности.`,
      source: {
        id: SOURCE_EE,
        page: 5,
      },
    },
    {
      header: `Рождённые под землёй`,
      text: `Свирфнеблины больше похожи на существ из камня, чем из плоти. Их кожа, как правило, серого, коричневого или серовато-коричневого оттенка, который действует как естественный камуфляж со скальной породой вокруг них. Их тела — угловатые, с крепкими мускулами или откровенно жирные, и несмотря на маленький рост, они тяжелее, чем кажутся; свирфнеблины часто весят 100 фунтов и больше, но редко вырастают выше 3 футов.

Мужчины свирфнеблины лысые с раннего детства, хотя, повзрослев, могут вырастить жёсткие бороды или усы. У женщин на головах много волос, но они обычно заплетают их в косы или обрезают, чтобы те не мешали им в работе.

Свирфнеблины хорошо приспособлены для подземного существования. Они обладают отличным зрением в темноте, и многие из них обладают магическими талантами, которые могут соперничать с врождёнными способностями дроу и двергаров. Они удивительно сильны для своего размера, а также устойчивы к тяжёлому труду и опасностям, которые могли бы сломить большинство других существ.`,
      source: {
        id: SOURCE_EE,
        page: 5,
      },
    },
    {
      header: `Великолепные шахтёры`,
      text: `Несмотря на осторожный характер, свирфнеблины не безрадостны. Они восхищаются искусной работой и тонким мастерством так же как и любой другой гном. Свирфнеблины обожают драгоценные камни всех видов, и они смело ищут самоцветы в самых глубоких и тёмных туннелях. Они также являются экспертами в огранке и добыче, и больше других драгоценных камней ценят рубины.`,
      source: {
        id: SOURCE_EE,
        page: 5,
      },
    },
    {
      header: `Обитатели глубин`,
      text: `Свирфнеблины известны как глубинные гномы потому что предпочитают жить глубоко под земной поверхностью. Большинство свирфнеблинов никогда не видело дневной свет. Их дома умело спрятаны за подобными лабиринту проходами и хитрыми иллюзиями. Поселения свирфнеблинов окружены обширными сетями шахтёрских туннелей, напичканными смертельными ловушками и скрытыми сторожками. Как только путешественник проходит через внешние защиты, туннели выводят его в изумительные городки-пещеры, вырезанные из окружающей породы с большим изяществом. Свирфнеблины строги в своих удобствах по сравнению со своими собратьями с поверхности, но они гордятся своей каменной кладкой.

Глубинные гномы делают всё возможное, чтобы остаться скрытыми. Даже если путешественник с поверхности преуспел в поиске свирфнеблинов, завоевание их доверия может оказаться ещё более трудной задачей. Те редкие путешественники, которые смогли подружиться с глубинными гномами, понимают, что это верные и храбрые союзники против любого противника`,
      source: {
        id: SOURCE_EE,
        page: 5,
      },
    },
    {
      header: `Глубинные гномы`,
      text: `Свирфнеблины (или глубинные гномы) более недоверчивы, чем их собратья с поверхности. Выжить в Подземье им помогает ночное зрение и развитые навыки скрытности.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Разведчики и шпионы`,
      text: `Гномов с поверхностности жизнь искателя приключений привлекает из желания узнать о мире вокруг них, увидеть новые вещи и познакомиться с новыми людьми. В сравнении с ними, большинство свирфнеблинов очень редко желает путешествовать. Они считают поверхностный мир сумасбродным местом без границ, заполненным неведомыми опасностями. Тем не менее, некоторые из свирфнеблинов понимают, что нужно знать кое-что о том, что происходит на поверхности вблизи их тайных убежищ. В результате некоторые свирфнеблины становятся разведчиками, шпионами и посыльными, отправляющимися на рисковые задания и делая всё возможное, чтобы избежать внимания. Они не любят распространяться о своих целях и своём доме, но если к ним будут добры, они могут довериться обитателям поверхности.

Некоторые свирфнеблины становятся торговцами, ведущими дела с другими расами над и под землей. Дроу, двергары и другие народы знают, что свирфнеблины нейтральны в своих взглядах и, как правило, честны в торговых делах. Работа в качестве посредника между враждующими расами, которые не могут иметь дел напрямую, может быть чрезвычайно прибыльной, и они выполняют эту функцию. Посредники свирфнеблины, как правило, знают больше о слухах и угрозах между торговцами враждующих сторон, чем кто-либо ещё.`,
      source: {
        id: SOURCE_EE,
        page: 5,
      },
    },
    {
      header: `Имена глубинных гномов`,
      text: `Свирфнеблины предпочитают менее яркие имена, чем родственные им скальные или лесные гномы. Названия кланов отражают умения или профессию, с которыми связана семья, но могут меняться, если кто-то преуспевает в новом направлении.

# Мужские имена

Белвар, Брикерс, Валшуд, Дуртмек, Кригер, Кронхад, Тулвар, Фирбл, Шнейктик, Шнелфек

# Женские имена

Белисс, Дарзии, Фрикнати, Ивридда, Криви, Люлзисс, Налвати, Шнелла, Тилмарра, Версидда

# Имена кланов

Кристалфист, Гемкаттер, Айронфут, Рокхевер, Симфайндер, Стоункаттер`,
      source: {
        id: SOURCE_EE,
        page: 5,
      },
    },
    {
      header: `Глубинные гномы в Забытых Королевствах`,
      text: `В Забытых Королевствах глубинные гномы живут в опасном подземном мире, известном как Подземье. Этот лабиринт из пещер и туннелей является домом для одних из самых страшных существ Фаэруна. Из-за этого свирфнеблины, живущие здесь, гораздо более недоверчивы и осторожны, чем их родственники в других мирах. Они редко позволяют незнакомцам приближаться к себе, а те, что вынуждены делать это, держатся на почтительном расстоянии. Если кому-то посчастливилось заслужить доверие глубинного гнома, то он обретает друга на всю жизнь. Для свирфнеблинов Подземья истинное доверие гораздо более ценно, чем золото.`,
      source: {
        id: SOURCE_EE,
        page: 5,
      },
    },
    {
      header: `Особенности глубинных гномов`,
      text: `Создавая персонажа гнома, Вы можете выбрать свирфнеблина в качестве альтернативной подрасы для гнома из Книги игрока. Для вашего удобства особенности гнома и глубинного гнома здесь описаны вместе.

# Увеличение характеристик

Интеллект увеличивается на 2.

Ловкость увеличивается на 1.

# Возраст

Глубинные гномы живут меньше, чем другие гномы. Они, как и люди, достигают зрелости и считаются взрослыми к 25 годам. Они живут от 200 до 250 лет, хотя тяжёлый труд и опасности подземной жизни могут привести к их досрочной кончине.

# Мировоззрение

Свирфнеблины считают, что выживание зависит от взаимопонимания с другими существами и стараются не наживать врагов, и поэтому предпочитают нейтральное мировоззрение. Они редко желают другим зла и вряд ли рискнут жизнью, защищая интересы другого.

# Размер

Обычно рост свирфнеблинов составляет от 3 до 3,5 футов (от 91 до 106 сантиметров), а вес от 80 до 120 фунтов (от 36 до 54 килограмм). Ваш размер — Маленький.

# Скорость

Ваша базовая скорость перемещения — 25 футов.

# Превосходное тёмное зрение

Ваше тёмное зрение имеет радиус 120 футов.

# Гномья хитрость

Вы совершаете с преимуществом испытания Интеллекта, Мудрости и Харизмы против магии.

# Каменный камуфляж

Вы совершаете с преимуществом проверки Ловкости (Скрытность), когда пытаетесь спрятаться на каменистой местности.

# Языки

Вы разговариваете, читаете и пишете на Всеобщем, Гномьем и Подземном. Диалект Гномьего у свирфнеблинов более гортанный, чем у гномов с поверхности, а большинство свирфнеблинов знает Всеобщий очень плохо, но те, кто имеет дело с посторонними (это относится и к Вам, как искателю приключений) знают Всеобщий достаточно хорошо, чтобы путешествовать.`,
      source: {
        id: SOURCE_EE,
        page: 6,
      },
    },
    {
      header: `Особенности подрасы свирфнеблинов`,
      text: `Свирфнеблины обладают чертами расы гномов из Книги Игрока, плюс чертами подрасы, приведенными ниже. В отличие от прочих гномов, свирфнеблины склонны быть нейтральными, весить от 80 до 120 фунтов, достигая совершеннолетия в 25 и жить до 200–250 лет.

# Увеличение характеристик

Ловкость увеличивается на 1.

# Превосходное тёмное зрение

Радиус тёмного зрения равен 120 футам.

# Каменный камуфляж

Свирфнеблины получают преимущество при проверках Ловкости (Скрытности) для того, чтобы скрыться в каменистой местности.

# Дополнительный язык

Свирфнеблины умеют говорить, читать и писать на Подземном.`,
      columnCount: 2,
      source: {
        id: SOURCE_SCAG,
        page: 116,
      },
    },
  ],
  paramBonuses: {
    [PARAM_DEX]: 1,
  },
  source: {
    id: SOURCE_PHB,
    page: 38,
  },
}

import { faBaby, faChild } from '@fortawesome/free-solid-svg-icons'

export default [
  {
    text: 'Взрослый',
    value: 'grown',
    icon: faChild,
  },
  {
    text: 'Ребёнок',
    value: 'child',
    icon: faBaby,
  },
]

const {SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {CREATURE_TYPE_PLASMOID} = require ('./../../creatureTypeIdList')
const {PC_RACE_PLASMOID} = require ('./../../pcRaceIdList')
const {
  SIZE_MEDIUM,
  SIZE_SMALL,
} = require ('./../../sizeList')
const {
  SETTING_DRAGON_LANCE,
  SETTING_GREYHAWK,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')
const customParamBonuses = require ('./../constants/customParamBonuses')

module.exports = {
  id: PC_RACE_PLASMOID,
  isReady: true,
  name: creatureTypeCollection[CREATURE_TYPE_PLASMOID].name,
  nameEn: creatureTypeCollection[CREATURE_TYPE_PLASMOID].nameEn,
  sizeType: [
    SIZE_MEDIUM,
    SIZE_SMALL,
  ],
  setting: [
    SETTING_DRAGON_LANCE,
    SETTING_GREYHAWK,
    SETTING_FORGOTTEN_REALMS,
  ],
  description: [
    {
      header: 'Плазмоиды',
      text: `Плазмоиды — аморфные создания, не имеющие типичной формы. В присутствии иных народов плазмоиды часто принимают схожие с ними формы, но перепутать плазмоида с кем-то другим почти невозможно. Они поглощают пищу как амёбы, процессом осмоса, а отходы выводят через крошечные поры. Плазмоиды дышат, поглощая кислород другими порами. Их конечности достаточно сильны и ловки, чтобы управляться с оружием и инструментами. Хотя большинство плазмоидов полупрозрачно-серые, они могут поглощать через поры красители, меняя свой цвет и прозрачность.

У плазмоидов нет привычных внутренних органов. Их тела состоят из клеток, волокон, плазмообразной слизи и клубков нервов, называемых ганглиями. Ганглии позволяют плазмоидам чувствоваться свет, тепло, текстуру, звук, боль и вибрацию. Плазмоиды могут делать внешний слой тела жёстким настолько, чтобы он поддерживал гуманоидную форму, что позволяет им носить одежду и снаряжение. Говорят плазмоиды, выталкивая воздух из трубчатых полостей, которые сжимаются, создавая звуки.

Когда плазмоиды спят, они теряют форму и растекаются, из-за чего их легко ошибочно принять за камень или иную особенность местности.`,
      source: {
        id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
        page: 3,
      },
    },
    {
      header: 'Особенности плазмоидов',
      text: `Вы получаете следующие расовые особенности 

# Тип существа

Вы — слизь.

# Размер

Ваш размер — Маленький или Средний (выберите, когда выбираете эту расу).

${customParamBonuses}

# Скорость

Ваша скорость ходьбы 30 футов.

# Аморфность

Вы можете протискиваться через пространство шириной в 1 дюйм, если ничего не несёте и не носите.

Вы получаете преимущество к проверкам характеристик для совершения и избегания Захвата.

# Тёмное зрение

На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Задержка дыхания

Вы можете задерживать дыхание до 1 часа.

# Естественная устойчивость

Вы получаете сопротивление к урону кислотой и ядом и преимущество к испытания от Отравления.

# Смена формы

Будучи дееспособным, Вы можете переформировать своё тело так, чтобы создать себе голову, одну или две руки, одну или две ноги и некое подобие ладоней и ступней, либо же Вы можете вернуться в форму пузыря без конечностей.

Вы можете бонусным действием вытянуть псевдоподу (до 6 дюймов шириной и до 10 футов длинной) или, наоборот, втянуть её в себя. Вы можете использовать эту псевдоподу для манипулирования предметами, открытия или закрытия дверей и контейнеров, класть или доставать предметы в открытые контейнеры или выливать содержимое контейнеров. Псевдоподой нельзя атаковать, активировать магические предметы или поднимать больше 10 фунтов. 

`,
      source: {
        id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
        page: 4,
      },
    },
  ],
  source: {
    id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
    page: 3,
  },
}

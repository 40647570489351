import React from 'react'

import Layout from '@/components/Layout'
import Seo from '@/components/Seo'
import NameGenerator from '@/components/NameGenerator'

export default () => (
  <Layout>
    <Seo
      title='Генератор имён для D&D'
      description='Генератор имён для Dungeons & Dragons'
    />
    <NameGenerator/>
  </Layout>
)

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_STR} = require ('./../../../paramList')
const {PC_RACE_GENASI} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_GENASI_EARTH} = require('./../../../pcSubraceIdList')
const {SOURCE_EE} = require ('./../../../sourceList')
const {SPELL_PASS_WITHOUT_TRACE} = require ('./../../../spellIdList')
const {CREATURE_DAO} = require ('./../../../creatureIdList')

module.exports = {
  id: PC_SUBRACE_GENASI_EARTH,
  parentRaceId: PC_RACE_GENASI,
  name: {
    singular: {
      [GENDER_MALE]: 'генази земли',
      [GENDER_FEMALE]: 'генази земли',
    },
    dual: {
      [GENDER_MALE]: 'генази земли',
      [GENDER_FEMALE]: 'генази земли',
    },
    plural: 'генази земли',
  },
  nameEn: `Earth Genasi`,
  description: [
    {
      header: `Генази земли`,
      text: `Будучи генази земли, Вы произошли от жестокого и жадного [дао](CREATURE:${CREATURE_DAO}), хотя Вам не обязательно быть злым. В некоторой мере Вы унаследовали контроль над землёй, Вас радует её прочность и твёрдость. Вы склонны избегать поспешных решений, делая длительную паузу, чтобы рассмотреть все варианты.

Стихия земли проявляется в разных генази по разному. У некоторых с тела постоянно сыплются частички пыли, а к их одежде так и липнет грязь, как бы часто они её не чистили. Другие же блестящие и отполированные, как драгоценные камни, с тёмно-коричневой или чёрной кожей и глазами, сверкающими как агаты. Генази земли также могут иметь гладкую металлическую плоть, тусклую кожу с пятнами ржавчины, галечно-грубую шкуру, а могут быть покрыты крошечными вкраплениями кристаллов. Самое невероятное — это трещины в их плоти, из которых исходит слабое свечение.`,
      source: {
        id: SOURCE_EE,
        page: 9,
      },
    },
    {
      header: `Особенности генази земли`,
      text: `# Увеличение характеристик

Сила увеличивается на 1.

# Хождение по земле

Вы можете перемещаться по труднопроходимой местности из земли или камня, не затрачивая дополнительное движение

# Слияние с камнем

Вы можете один раз наложить заклинание [Бесследное передвижение](SPELL:${SPELL_PASS_WITHOUT_TRACE}), не нуждаясь в материальных компонентах. Вы не сможете вновь использовать эту способность, пока не окончите длинный отдых. Заклинательной характеристикой для её использования является Телосложение.`,
      source: {
        id: SOURCE_EE,
        page: 9,
      },
    },
  ],
  paramBonuses: {
    [PARAM_STR]: 1,
  },
  source: {
    id: SOURCE_EE,
    page: 9,
  },
}

const {SOURCE_LR} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_LOCATHAH} = require ('./../../pcRaceIdList')
const {GEAR_SHIELD} = require ('./../../gearIdList')
const {SETTING_FORGOTTEN_REALMS} = require ('./../../settingList')
const {
  LANG_AQUAN,
  LANG_COMMON,
} = require ('./../../languageIdList')
const {
  PARAM_STR,
  PARAM_DEX,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_LOCATHAH,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_LOCATHAH].name,
  nameEn: creatureTypeCollection[PC_RACE_LOCATHAH].nameEn,
  sizeType: SIZE_MEDIUM,
  description: [
    {
      header: 'Локаты',
      text: `Показанный в «Призраках Солёного болота», этот стойкий и гордый рыбий народ пережил войны, рабство и жестокое обращение со стороны других водных существ.

Локаты живут полуподводными племенными общинами вдоль берегов морей и охотятся как над, так и под водой.`,
      source: {
        id: SOURCE_LR,
        page: 24,
      },
    },
    {
      header: 'Особенности локат',
      text: `Ваш персонаж-локат обладает врождёнными особенностями локат.

# Увеличение характеристик

Сила увеличивается на 2.

Ловкость увеличивается на 1.

# Возраст

Локаты достигают зрелости к 10 годам, и часто живут до 80 лет.

# Мировоззрение

Большинство локат истинно нейтральны или хотя бы частично нейтральны. Они имеют тягу к добру, потому что в их культуре сострадание к слабым считается нормой.

# Природная броня

Ваша кожа жёсткая и чешуйчатая. Когда Вы не носите доспех, Ваш КД 12 + Ваш модификатор Ловкости. Вы можете использовать КД своей природной брони, если носимый Вами доспех даёт более низкий КД. При использовании природного доспеха эффект от [щита](GEAR:${GEAR_SHIELD}) применяется как обычно.

# Внимательные и атлетичные

Вы владеете Атлетикой и Внимательностью.

# Воля левиафана

Вы имеете преимущество к испытаниям от Очарования, Испуга, Паралича, Отравления, Ошеломления и усыпления.

# Ограниченная амфибийность

Вы можете может дышать и воздухом и под водой, но, чтобы не задохнуться, Вам нужно погружаться в воду хотя бы раз в 4 часа.

# Размер

Стоящий вертикально локат ростом от 5 до 6 футов и весит около 150 фунтов. Ваш размер — Средний.

# Скорость

У Вас скорость ходьбы 30 футов, и скорость плавания 30 футов.

# Языки

Вы можете говорить, читать и писать на [Всеобщем](LANG:${LANG_COMMON}) и [Акване](LANG:${LANG_AQUAN}).`,
      columnCount: 2,
      source: {
        id: SOURCE_LR,
        page: 24,
      },
    },
  ],
  size: {
    tall: {
      base: 4 * 12 + 8,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 110,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 2,
    [PARAM_DEX]: 1,
  },
  age: {
    adult: 10,
    max: 80,
  },
  setting: SETTING_FORGOTTEN_REALMS,
  source: {
    id: SOURCE_LR,
    page: 24,
  },
}

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_CON} = require ('./../../../paramList')
const {PC_RACE_AASIMAR} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_AASIMAR_SCOURGE} = require('./../../../pcSubraceIdList')
const {SOURCE_VGTM} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_AASIMAR_SCOURGE,
  parentRaceId: PC_RACE_AASIMAR,
  name: {
    singular: {
      [GENDER_MALE]: 'аасимар-каратель',
      [GENDER_FEMALE]: 'аасимарка-карательница',
    },
    dual: {
      [GENDER_MALE]: 'аасимара-карателя',
      [GENDER_FEMALE]: 'аасимарки-карательницы',
    },
    plural: 'аасимары-каратели',
  },
  nameShort: {
    singular: {
      [GENDER_MALE]: 'каратель',
      [GENDER_FEMALE]: 'карательница',
    },
    dual: {
      [GENDER_MALE]: 'карателя',
      [GENDER_FEMALE]: 'карательницы',
    },
    plural: 'каратели',
  },
  nameEn: `Scourge Aasimar`,
  description: {
    text: `Аасимар-каратель наполнен божественной энергией, которая пылает внутри. Она подпитывает сильное желание уничтожать зло — желание в лучшем случае твёрдое, а в худшем — всепоглощающее. Многие аасимары-каратели носят маски, чтобы оградиться от мира и сосредоточиться на том, чтобы сдерживать эту силу, снимая с себя маску только во время сражения.

# Увеличение характеристик

Телосложение увеличивается на 1.

# Испускание сияния

Начиная с 3 уровня, Вы можете действием высвободить божественную энергию внутри себя, начав излучать испепеляющий свет, льющийся из глаз и рта, и угрожающий опалить Вас.

Ваше превращение длится 1 минуту или пока Вы не закончите его бонусным действием. Во время превращения Вы излучаете яркий свет в пределах 10 футов и тусклый свет в пределах ещё 10 футов, и в конце каждого Вашего хода, Вы и все существа в пределах 10 футов от Вас получаете урон излучением, равный половине Вашего уровня (округляя вверх). Кроме того, один раз на каждом Вашем ходу Вы можете нанести дополнительный урон излучением одной цели, когда Вы наносите ей урон атакой или заклинанием. Дополнительный урон излучением равен Вашему уровню.

Использовав эту способность, Вы не можете использовать её снова, пока не завершите длинный отдых.`,
    source: {
      id: SOURCE_VGTM,
      page: 107,
    },
  },
  paramBonuses: {
    [PARAM_CON]: 1,
  },
  source: {
    id: SOURCE_VGTM,
    page: 107,
  },
}

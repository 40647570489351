const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_NAR} = require('./../../../pcSubraceIdList')
const {SOURCE_SCAG} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_NAR,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: `нар`,
      [GENDER_FEMALE]: `нар`,
    },
    dual: {
      [GENDER_MALE]: `нар`,
      [GENDER_FEMALE]: `нар`,
    },
    plural: `нары`,
  },
  nameEn: `Nar`,
  description: `Более тысячи лет назад, тёмные жрецы Нарфелла, с помощью демонов, собрали огромную силу, но их действия принесли войну, уничтожившую их цивилизацию. Нары бросили их разрушенные и проклятые города и стали кочевниками и торговцами. Кожа наров смуглая, глаза карие или тёмные, а волосы чёрные. Часто Нары носят длинные волосы, схваченные в хвост или пучок.

# Имена наров

## Мужские

Аван, Остарам, Петро, Стор, Таман, Таламан, Урт

## Женские

Анва, Даша, Дима, Ольга, Вестра, Златара

## Фамилии

Дашкев, Лабода, Лакман, Стонар, Стормвинд, Сулима, Харгот`,
  source: {
    id: SOURCE_SCAG,
    page: 112,
  },
}

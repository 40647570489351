const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_MULAN} = require('./../../../pcSubraceIdList')
const {SOURCE_PHB} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_MULAN,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: 'мулан',
      [GENDER_FEMALE]: 'мулан',
    },
    dual: {
      [GENDER_MALE]: 'мулана',
      [GENDER_FEMALE]: 'мулана',
    },
    plural: 'муланы',
  },
  nameEn: 'Mulan',
  description: `Преобладающие на восточных и юго-восточных берегах Внутреннего моря, муланы, как правило, высокие, стройные, с кожей цвета янтаря и карими или светло-коричневыми глазами. Волосы у них от чёрного до тёмно-коричневого оттенков, но на землях, где муланы наиболее многочисленны, дворяне и другие жители сбривают все волосы.

# Имена муланов

## Мужские

Аот, Барерис, Кетот, Мумед, Рамас, Со-Кехур, Тхазар-Де, Урхур, ЭхпутКи

## Женские

Аризима, Золис, Мурити, Нефис, Нулара, Сефрис, Тола, Умара, Чатхи

## Фамилии

Анкхалаб, Анскульд, Натандем, Серпет, Уутракт, Фезим, Хахпет`,
  source: {
    id: SOURCE_PHB,
    page: 32,
  },
}

import { GENDER_FEMALE, GENDER_MALE, GENDER_ANY } from '@/constants/genderList'

export default (NAMES_FEMALE, NAMES_MALE) => genderId => {
  switch (genderId) {
    case GENDER_ANY: {
      return [
        ...NAMES_FEMALE,
        ...NAMES_MALE,
      ]
    }

    case GENDER_FEMALE: {
      return NAMES_FEMALE
    }

    case GENDER_MALE: {
      return NAMES_MALE
    }
  }
}

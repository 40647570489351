const {SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {CREATURE_TYPE_THRI_KREEN} = require ('./../../creatureTypeIdList')
const {PC_RACE_THRI_KREEN} = require ('./../../pcRaceIdList')
const {GEAR_SHIELD} = require ('./../../gearIdList')
const {
  SIZE_MEDIUM,
  SIZE_SMALL,
} = require ('./../../sizeList')
const {
  SETTING_DRAGON_LANCE,
  SETTING_GREYHAWK,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')
const customParamBonuses = require ('./../constants/customParamBonuses')

module.exports = {
  id: PC_RACE_THRI_KREEN,
  isReady: true,
  name: creatureTypeCollection[CREATURE_TYPE_THRI_KREEN].name,
  nameEn: creatureTypeCollection[CREATURE_TYPE_THRI_KREEN].nameEn,
  sizeType: [
    SIZE_MEDIUM,
    SIZE_SMALL,
  ],
  setting: [
    SETTING_DRAGON_LANCE,
    SETTING_GREYHAWK,
    SETTING_FORGOTTEN_REALMS,
  ],
  description: [
    ...creatureTypeCollection[CREATURE_TYPE_THRI_KREEN].description,
    {
      header: 'Три-крины',
      text: `Три-крины обладают чертами насекомых и имеют две пары рук. Их тела защищены прочным хитином. Три-крины могут изменять цвет своих панцирей, сливаясь с окружением. 

Хотя три-крины не спят, им необходимы периоды неактивности для восстановления организма. В это время они находятся в сознании и прекрасно осознают всё, что происходит вокруг них.

Общение три-кринов выглядит как клацанье мандибулами и помахивание антеннами. Так они выражают свои свои мысли и чувства. Прочие существа считают такой способ общение очень сложным для понимания и невозможным для повторения. Для взаимодействия с прочими народами три-крины полагаются на особую форму телепатии.`,
      source: {
        id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
        page: 3,
      },
    },
    {
      header: 'Особенности три-кринов',
      text: `Вы получаете следующие расовые особенности 

# Тип существа

Вы — монстр.

# Размер

Ваш размер — Маленький или Средний (выберите, когда выбираете эту расу).

${customParamBonuses}

# Скорость

Ваша скорость ходьбы 30 футов.

# Хамелеонный панцирь

Пока Вы не носите доспехов, Ваш КД равен 13 + модификатор Ловкости.

Вы можете действием менять цвет своего панциря для адаптации к цвету и текстуре окружения, что даёт Вам преимущество к проверкам Ловкости (Скрытность) при попытках спрятаться в этом окружении. 

# Тёмное зрение

На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Вторая пара рук

Помимо основной пары рук, у Вас есть дополнительная, меньшая пара. Дополнительные руки действуют так же, как и основные, но со следующими исключениями

* Вы можете держать в дополнительных руках оружие со свойством Лёгкое. Вы не можете держать в них другие виды оружия.
* Дополнительными руками нельзя держать [щит](GEAR:${GEAR_SHIELD}).

# Восстановление без сна

Вам не нужно спать и Вы можете решить оставаться в сознании весь длинный отдых. Хотя, для получения выгод от такого отдыха, Вам всё ещё нужно воздерживаться от напряжённой деятельности.

# Телепатия три-кринов

Вы обладаете магической способностью мысленно общаться с любым числом согласных существ, видимых Вами в пределах 120 футов. Затронутое существо не обязано знать общий с Вами язык, но оно должно знать хотя бы один язык. Ваша телепатическая связь с существом разрывается, если Вы с ним  окажетесь дальше 120 футов друг от друга или если Вы станете Недееспособны или если Вы мысленно разорвёте связь с ним (действий не требуется).`,
      source: {
        id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
        page: 4,
      },
    },
  ],
  source: {
    id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
    page: 3,
  },
}

const phbSizeSource = require ('./../../constants/phbSizeSource')
const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_WIT} = require ('./../../../paramList')
const {PC_RACE_DWARF} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_DWARF_HILL} = require('./../../../pcSubraceIdList')
const {SOURCE_GAME_BG_3, SOURCE_PHB, SOURCE_SCAG} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_DWARF_HILL,
  parentRaceId: PC_RACE_DWARF,
  name: {
    singular: {
      [GENDER_MALE]: 'холмовой дварф',
      [GENDER_FEMALE]: 'холмовая дварфийка',
    },
    dual: {
      [GENDER_MALE]: 'холмовых дварфа',
      [GENDER_FEMALE]: 'холмовые дварфийки',
    },
    plural: 'холмовые дварфы',
  },
  nameAlt: `золотые дварфы`,
  nameEn: `Hill Dwarfs`,
  nameEnAlt: `Gold Dwarfs`,
  nameShort: {
    singular: {
      [GENDER_MALE]: 'холмовой',
      [GENDER_FEMALE]: 'холмовая',
    },
    dual: {
      [GENDER_MALE]: 'холмовых',
      [GENDER_FEMALE]: 'холмовые',
    },
    plural: 'холмовые',
  },
  description: [
    {
      header: `Золотые дварфы`,
      text: `Золотые дварфы распространены в южных и восточных землях. Эти грозные воины, преданные своим древним традициям, отличаются крепкими внутриклановыми связями. Грубые и надменные, они известны своим высочайшим ремесленным мастерством и страстью к торговле.

Крупные поселения золотых дварфов расположены в районе Великого Разлома, вокруг Побережья Дракона, а также в Старых Империях восточного Фаэруна.

Небольшие общины есть также в Дымящихся горах, Великановом Беге и западном Сердцеземье.

В отличие от щитовых дварфов, на долю золотых дварфов не выпало бесконечных вражеских вторжений и долгих скитаний, поэтому они склонны к большему оптимизму, чем их собратья, но при этом горды и надменны настолько, как только может быть дварф. Они верят, что их историческая стабильность — результат внимательного и бережного отношения к традициям, и не сомневаются, что будущее золотых дварфов будет столь же безоблачным, если они останутся верными своим обычаям и принципам.

Золотые дварфы в Книге Игрока имеют расовые черты холмовых дварфов. Они коренастые и мускулистые, ростом около 4 футов, со смуглой кожей, черными или коричневыми волосами и глазами коричневого или орехового цвета. Зеленый цвет глаз встречается редко (и считается счастливым). Мужчины отращивают полные бороды, тщательно ухаживают за ними и умащивают их маслом, и оба пола носят волосы длинными, часто — искусно заплетенными.

Золотые дварфы хорошо известны своим искусством создания прекрасных вещей. По их мнению, все природные ресурсы мира существуют для того, чтобы смертные превратили их в объекты великой красоты. Золотые дварфы придерживаются девиза _«Лучше меньше, да лучше»_. Их ремесленники могут годами трудиться над единственным предметом, добиваясь безупречного качества каждой его мельчайшей детали и каждого элемента гравировки.

Такой осознанный перфекционизм является отражением самой культуры золотых дварфов, в которой всё рекомендуется делать правильным и надлежащим способом. Все аспекты жизни золотых дварфов — от их места в обществе до выбора приемлемой профессии и спутника жизни — диктуются традициями. Выбирая жизнь приключенца, золотые дварфы крайне редко отказываются от своих обычаев. Даже покинув на время общество соплеменников, они надеются, в конечном счете, упрочить своё положение в нём.`,
      source: {
        id: SOURCE_SCAG,
        page: 104,
      },
    },
    {
      header: 'Золотой дварф',
      text: `Эти дварфы знамениты уверенностью в себе и развитой интуицией. Культура Глубокого королевства учит их ценить семью, ритуалы и тонкую работу.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Холмовые дварфы`,
      text: `Будучи холмовым дварфом, Вы обладаете обострёнными чувствами, развитой интуицией и замечательной стойкостью. Золотые дварфы Фаэруна, в их могучем южном королевстве являются холмовыми дварфами, также как и изгнанные нейдары и свихнувшиеся клары из Кринна (мир Саги о Копье).

# Увеличение характеристик

Мудрость увеличивается на 1.

# Дварфийская выдержка

Максимальное значение Ваших хитов увеличивается на 1, и Вы получаете 1 дополнительный хит с каждым новым уровнем.`,
      source: {
        id: SOURCE_PHB,
        page: 21,
      },
    },
  ],
  size: {
    tall: {
      base: 3 * 12 + 8,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
    weight: {
      base: 115,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
    source: phbSizeSource,
  },
  paramBonuses: {
    [PARAM_WIT]: 1,
  },
  source: {
    id: SOURCE_PHB,
    page: 21,
  },
}

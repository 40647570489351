import upLetter from '@/utils/upLetter'
import {
  pcSubraceCollection,
} from '@/constants/pcSubraceList'
import {
  PC_SUBRACE_HUMAN_ARKAIUN,
  PC_SUBRACE_HUMAN_BEDINE,
  PC_SUBRACE_HUMAN_CHONDATAN,
  PC_SUBRACE_HUMAN_CHULT,
  PC_SUBRACE_HUMAN_DAMARAN,
  PC_SUBRACE_HUMAN_FFOLK,
  PC_SUBRACE_HUMAN_GUR,
  PC_SUBRACE_HUMAN_HALRUAAN,
  PC_SUBRACE_HUMAN_ILLUSKAN,
  PC_SUBRACE_HUMAN_IMASKARI,
  PC_SUBRACE_HUMAN_CALISHITE,
  PC_SUBRACE_HUMAN_MULAN,
  PC_SUBRACE_HUMAN_NAR,
  PC_SUBRACE_HUMAN_RASHEMI,
  PC_SUBRACE_HUMAN_SHAARAN,
  PC_SUBRACE_HUMAN_SHOU,
  PC_SUBRACE_HUMAN_TETHYRIAN,
  PC_SUBRACE_HUMAN_TUIGAN,
  PC_SUBRACE_HUMAN_TURAMI,
  PC_SUBRACE_HUMAN_ULUTIUN,
} from '@/constants/pcSubraceIdList'

const humanSubraceList = [
  PC_SUBRACE_HUMAN_ARKAIUN,
  PC_SUBRACE_HUMAN_BEDINE,
  PC_SUBRACE_HUMAN_CHONDATAN,
  PC_SUBRACE_HUMAN_CHULT,
  PC_SUBRACE_HUMAN_DAMARAN,
  PC_SUBRACE_HUMAN_FFOLK,
  PC_SUBRACE_HUMAN_GUR,
  PC_SUBRACE_HUMAN_HALRUAAN,
  PC_SUBRACE_HUMAN_ILLUSKAN,
  PC_SUBRACE_HUMAN_IMASKARI,
  PC_SUBRACE_HUMAN_CALISHITE,
  PC_SUBRACE_HUMAN_MULAN,
  PC_SUBRACE_HUMAN_NAR,
  PC_SUBRACE_HUMAN_RASHEMI,
  PC_SUBRACE_HUMAN_SHAARAN,
  PC_SUBRACE_HUMAN_SHOU,
  PC_SUBRACE_HUMAN_TETHYRIAN,
  PC_SUBRACE_HUMAN_TUIGAN,
  PC_SUBRACE_HUMAN_TURAMI,
  PC_SUBRACE_HUMAN_ULUTIUN,
]
  .map(
    pcSubRaceId => {
      const { parentRaceId, name: { plural: text } } = pcSubraceCollection[pcSubRaceId]

      return {
        text: upLetter(text),
        value: pcSubRaceId,
        show: {
          raceId: parentRaceId,
        },
      }
    },
  )

const subRaceList = [
  {
    text: 'Любая',
    value: 'any',
  },
  ...humanSubraceList,
]

export default subRaceList

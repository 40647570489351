import maybePluralGender from '@/utils/nameGeneration/maybePluralGender'
import getRandomNoun from '@/utils/nameGeneration/getRandomNoun'
import generateRandomGender from '@/utils/nameGeneration/generateRandomGender'

export default ({genderId}) => {
  const genderIdSecond = generateRandomGender()
  const isPlural = maybePluralGender()

  const nounFirst = getRandomNoun({genderId})
  const nounFirstText = nounFirst.nominative
  const nounSecond = getRandomNoun(
    {
      genderId: genderIdSecond,
      nounToFilter: nounFirst,
      isPlural,
    }
  ).genitive

  return `${nounFirstText} ${nounSecond}`
}

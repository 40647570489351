import _ from 'lodash'

import Name from '@/constructors/Name'
import { PC_RACE_TIEFLING } from '@/constants/pcRaceIdList'
import generateRealGender from '@/utils/nameGeneration/generateRealGender'

import generateNameListTiefling from './utils/generateNameListTiefling'
import ideaNameList from './dict/names_idea'

const IDEA_NAME_COEF = 0.5

export default ({ genderId: genderIdInput }) => {
  const genderId = generateRealGender(genderIdInput)
  const firstName = Math.random() <= IDEA_NAME_COEF
    ? _.sample(ideaNameList)
    : _.sample(generateNameListTiefling(genderId))

  return new Name({
    firstName,
    genderId,
    raceId: PC_RACE_TIEFLING,
  })
}

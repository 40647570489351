const {SOURCE_VRGtR} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_HEXBLOOD} = require ('./../../pcRaceIdList')
const {SETTING_FORGOTTEN_REALMS} = require ('./../../settingList')
const {lineagesDescriptionList} = require('./../../textCommonParts')
const {
  SPELL_DISGUISE_SELF,
  SPELL_HEX,
} = require ('./../../spellIdList')
const {
  SIZE_MEDIUM,
  SIZE_SMALL,
} = require ('./../../sizeList')

module.exports = {
  id: PC_RACE_HEXBLOOD,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_HEXBLOOD].name,
  nameEn: creatureTypeCollection[PC_RACE_HEXBLOOD].nameEn,
  sizeType: [
    SIZE_MEDIUM,
    SIZE_SMALL,
  ],
  description: [
    ...creatureTypeCollection[PC_RACE_HEXBLOOD].description,
    ...lineagesDescriptionList,
    {
      header: `Особенности порчекровных`,
      text: `Вы имеете следующие расовые особенности

# Тип существа

Вы — фея.

# Размер

Вы — Маленького или Среднего размера. Вы выбираете размер, когда получаете это наследие.

# Скорость

Ваша скорость ходьбы 30 футов.

# Наследие предков

Если Вы заменяете свою расу на это наследие, то можете сохранить следующие особенности той расы:

* Владения навыками;
* Скорость лазания, полёта, плавания.

Если Вы не сохраняете никакие из этих особенностей или если Вы выбрали это наследие при создании персонажа, то Вы получаете владение двумя навыками на Ваш выбор.

# Тёмное зрение

На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Жуткий жетон

Вы можете бонусным действием безвредно оторвать у себя клок волос, ноготь или вырвать зуб. Этот предмет  становится _жутким жетоном_, наполняясь магией до окончания Вашего длинного отдыха.

Создав _жетон_ этой способностью, Вы не сможете сделать это снова, пока не завершите длинный отдых, в конце которого Ваша оторванная часть вырастает заново.

Пока _жетон_ наполнен этой магией, Вы можете совершать следующие действия.

## Телепатическое сообщение

Вы можете действием послать телепатическое сообщение существу, держащему или несущему Ваш _жетон_, если находитесь в пределах 10 миль от него. Такое сообщение может содержать до двадцати пяти слов.

## Дальний взор

Находясь в пределах 10 миль от _жетона_, Вы можете действием войти в транс. Транс длится 1 минуту, но заканчивается раньше, если Вы его прерываете (действий не требуется) или теряете сознание. Во время этого транса Вы можете видеть и слышать всё вокруг _жетона_, как если бы Вы сами там находились. Пока Вы так делаете, Вы слепы и глухи для своего окружения. Когда транс завершается, _жетон_ безвредно разрушается.

# Ворожба

Вы можете сотворять этой способностью заклинания [Маскировка](SPELL:${SPELL_DISGUISE_SELF}) и [Сглаз](SPELL:${SPELL_HEX}). Сотворив так одно из этих заклинаний, Вы не можете сотворять его снова, пока не закончите длинный отдых. Также Вы можете сотворять эти заклинания, тратя свои ячейки заклинаний.

Заклинательная характеристика для этих заклинаний — Интеллект, Мудрость или Харизма на Ваш выбор (Вы выбираете её, когда получаете это наследие).`,
      source: {
        id: SOURCE_VRGtR,
        page: 20,
      },
    },
  ],
  setting: SETTING_FORGOTTEN_REALMS,
  source: {
    id: SOURCE_VRGtR,
    page: 18,
  },
}

module.exports = [
  require('./subraces/aasimar/aasimar_fallen'),
  require('./subraces/aasimar/aasimar_protector'),
  require('./subraces/aasimar/aasimar_scourge'),
  require('./subraces/dwarf/dwarf_gray'),
  require('./subraces/dwarf/dwarf_hill'),
  require('./subraces/dwarf/dwarf_mountain'),
  require('./subraces/elf/elf_astral'),
  require('./subraces/elf/elf_drow'),
  require('./subraces/elf/elf_moon'),
  require('./subraces/elf/elf_sun'),
  require('./subraces/elf/elf_wood'),
  require('./subraces/genasi/genasi_air'),
  require('./subraces/genasi/genasi_earth'),
  require('./subraces/genasi/genasi_fire'),
  require('./subraces/genasi/genasi_water'),
  require('./subraces/gith/gith_githyanki'),
  require('./subraces/gith/gith_githzerai'),
  require('./subraces/gnome/gnome_deep'),
  require('./subraces/gnome/gnome_forest'),
  require('./subraces/gnome/gnome_rock'),
  require('./subraces/halfling/halfling_ghost'),
  require('./subraces/halfling/halfling_lightfoot'),
  require('./subraces/halfling/halfling_stout'),
  require('./subraces/human/human_arkaiun'),
  require('./subraces/human/human_bedine'),
  require('./subraces/human/human_calishite'),
  require('./subraces/human/human_chondatan'),
  require('./subraces/human/human_chult'),
  require('./subraces/human/human_damaran'),
  require('./subraces/human/human_ffolk'),
  require('./subraces/human/human_gur'),
  require('./subraces/human/human_halruaan'),
  require('./subraces/human/human_illuskan'),
  require('./subraces/human/human_imaskari'),
  require('./subraces/human/human_mulan'),
  require('./subraces/human/human_nar'),
  require('./subraces/human/human_rashemi'),
  require('./subraces/human/human_shaaran'),
  require('./subraces/human/human_shou'),
  require('./subraces/human/human_tethyrian'),
  require('./subraces/human/human_tuigan'),
  require('./subraces/human/human_turami'),
  require('./subraces/human/human_ulutiun'),
]

const {SOURCE_VGTM} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_TABAXI} = require ('./../../pcRaceIdList')
const {SETTING_FORGOTTEN_REALMS} = require ('./../../settingList')

const {
  PARAM_DEX,
  PARAM_CHA,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_TABAXI,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_TABAXI].name,
  nameEn: creatureTypeCollection[PC_RACE_TABAXI].nameEn,
  note: creatureTypeCollection[PC_RACE_TABAXI].note,
  description: [
    ...creatureTypeCollection[PC_RACE_TABAXI].description,
    {
      header: 'Особенности табакси',
      text: `Ваш персонаж-табакси обладает следующими особенностями.

# Увеличение характеристик

Ловкость увеличивается на 2.

Харизма увеличивается на 1.

# Возраст

Табакси имеют такую же продолжительность жизни, что и люди.

# Мировоззрение

Табакси склонны к хаотическому мировоззрению, поскольку они позволяют своим порывам и увлечениям направлять их решения. Они редко являются злыми, большинство из них ведомы любопытством, а не жадностью или другими темными побуждениям.

# Размер

Табакси в среднем более высокие чем люди и относительно худощавые. Ваш размер — Средний.

# Скорость

Ваша базовая скорость ходьбы составляет 30 футов.

# Тёмное зрение

У Вас есть острые кошачьи чувства, особенно в темноте. На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Кошачье проворство

Ваши рефлексы и проворство позволяют Вам двигаться со скачком скорости. Когда Вы двигаетесь в бою в свой ход, Вы можете удвоить свою скорость до конца хода. Использовав эту черту, Вы не можете использовать её снова, до тех пор пока Вы не переместитесь на 0 футов за один из Ваших ходов.

# Кошачьи когти

Из–за Ваших когтей у Вас есть скорость лазанья 20 футов. Кроме того, Ваши _Когти_ — естественное оружие, котором Вы можете наносить безоружные удары. Если Вы совершаете атаку _Когтями_, Вы наносите рубящий урон, равный 1к4 + модификатор Силы, вместо обычного урона для безоружного удара.

# Кошачьи способности

Вы владеете навыками Внимание и Скрытность.

# Языки

Вы можете говорить, читать и писать на Всеобщем и ещё одном языке на Ваш выбор.`,
      source: {
        id: SOURCE_VGTM,
        page: 115,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 10,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 80,
      coefDice: {
        diceCount: 1,
        diceType: 4,
      },
    },
    source: {
      id: SOURCE_VGTM,
      page: 115,
    },
  },
  paramBonuses: {
    [PARAM_DEX]: 2,
    [PARAM_CHA]: 1,
  },
  setting: SETTING_FORGOTTEN_REALMS,
  source: {
    id: SOURCE_VGTM,
    page: 115,
  },
}

import { GENDER_FEMALE, GENDER_MALE, GENDER_ANY } from '@/constants/genderList'

import nameListFemale from "./../dict/names_female"
import nameListMale from "./../dict/names_male"
import nameListUnisex from "./../dict/names_unisex"

export default genderId => {
  switch (genderId) {
    case GENDER_FEMALE: {
      return [
        ...nameListFemale,
        ...nameListUnisex,
      ]
    }

    case GENDER_MALE: {
      return [
        ...nameListMale,
        ...nameListUnisex,
      ]
    }

    case GENDER_ANY:
    default: {
      return [
        ...nameListFemale,
        ...nameListMale,
        ...nameListUnisex,
      ]
    }
  }
}

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_IMASKARI} = require('./../../../pcSubraceIdList')
const {SOURCE_SCAG} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_IMASKARI,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: `имаскари`,
      [GENDER_FEMALE]: `имаскари`,
    },
    dual: {
      [GENDER_MALE]: `имаскари`,
      [GENDER_FEMALE]: `имаскари`,
    },
    plural: `имаскари`,
  },
  nameEn: `Imaskari`,
  description: `Восстание Муланских рабов привело к крушению Имаскара и правящих магов тысячи лет назад, но некоторые имаскари выжили и сбежали в Подземье. Там они изменились — их кожа и волосы побелели и разгладились. Имаскари, правившие Мулхорандским регионом также были вынуждены уйти в изгнание в результате второго восстания угнетённых.

# Имена имаскари

## Мужские

Дума, Жама, Прадир, Сихиль, Хукир, Чарва

## Женские

Апрет, Баск, Мокат, Нисмет, Риль, Фанул

## Фамилии

Датарати, Мелпурватта, Наламбар, Тилипутакас`,
  source: {
    id: SOURCE_SCAG,
    page: 112,
  },
}

import _ from "lodash";

import generateRealGender from '@/utils/nameGeneration/generateRealGender'
import Name from '@/constructors/Name'
import { PC_RACE_WARFORGED } from '@/constants/pcRaceIdList'

import nameListWarforged from "./dict/nameList"

export default ({ genderId: genderIdInput }) => {
  const genderId = generateRealGender(genderIdInput, false)

  return new Name({
    firstName: _.sample(nameListWarforged),
    genderId,
    raceId: PC_RACE_WARFORGED,
  })
}

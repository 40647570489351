const {SOURCE_MOOT} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {PC_RACE_LEONIN} = require ('./../../pcRaceIdList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {SETTING_THEROS} = require ('./../../settingList')
const {
  PARAM_CON,
  PARAM_STR,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_LEONIN,
  isReady: false,
  name: creatureTypeCollection[PC_RACE_LEONIN].name,
  nameEn: creatureTypeCollection[PC_RACE_LEONIN].nameEn,
  note: creatureTypeCollection[PC_RACE_LEONIN].note,
  description: creatureTypeCollection[PC_RACE_LEONIN].description,
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 5 * 12 + 6,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 180,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
  },
  paramBonuses: {
    [PARAM_CON]: 2,
    [PARAM_STR]: 1,
  },
  setting: SETTING_THEROS,
  source: {
    id: SOURCE_MOOT,
    page: 22,
  },
}

const {SOURCE_VGTM} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {PC_RACE_YUAN_TI_PUREBLOOD} = require ('./../../pcRaceIdList')
const {CREATURE_TYPE_YUAN_TI} = require('./../../creatureTypeIdList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {
  monsterAdventurers,
  yuantiPurebloodDescription,
} = require ('./../../textCommonParts')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require ('./../../genderList')
const {
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_POISON_SPRAY,
  SPELL_SUGGESTION,
} = require ('./../../spellIdList')
const {
  PARAM_INT,
  PARAM_CHA,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_YUAN_TI_PUREBLOOD,
  isReady: true,
  name: {
    singular: {
      [GENDER_MALE]: {
        nominative: 'чистокровный юань-ти',
        genitive: 'чистокровного юань-ти',
        dative: 'чистокровному юань-ти',
        accusative: 'чистокровного юань-ти',
        instrumental: 'чистокровным юань-ти',
        prepositional: 'чистокровном юань-ти',
      },
      [GENDER_FEMALE]: {
        nominative: 'чистокровная юань-ти',
        genitive: 'чистокровной юань-ти',
        dative: 'чистокровной юань-ти',
        accusative: 'чистокровную юань-ти',
        instrumental: 'чистокровной юань-ти',
        prepositional: 'чистокровной юань-ти',
      },
    },
    plural: {
      nominative: 'чистокровные юань-ти',
      genitive: 'чистокровных юань-ти',
      dative: 'чистокровным юань-ти',
      accusative: 'чистокровных юань-ти',
      instrumental: 'чистокровными юань-ти',
      prepositional: 'чистокровных юань-ти',
    },
  },
  nameEn: 'Yuan-ti Pureblood',
  sizeType: SIZE_MEDIUM,
  note: creatureTypeCollection[CREATURE_TYPE_YUAN_TI].note,
  description: [
    yuantiPurebloodDescription,
    ...creatureTypeCollection[CREATURE_TYPE_YUAN_TI].description,
    ...monsterAdventurers,
    {
      header: 'Особенности чистокровных юань-ти',
      text: `Ваш персонаж — чистокровный юань-ти (для краткости называемый просто чистокровным) обладает следующими особенностями.

# Увеличение характеристик

Харизма увеличивается на 2.

Интеллект увеличивается на 1.

# Возраст

Чистокровные взрослеют как люди, и имеют такую же продолжительность жизни.

# Мировоззрение

Чистокровные лишены эмоции и рассматривают других как инструменты для манипулирования. Их мало заботят принципы или хаос, они обычно нейтрально-злые.

# Размер

Чистокровные по росту и телосложению схожи с людьми. Ваш размер — Средний.

# Скорость

Ваша базовая скорость ходьбы составляет 30 футов.

# Тёмное зрение

На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Врожденная магия

Вы знаете заговор [Ядовитые брызги](SPELL:${SPELL_POISON_SPRAY}).

Вы можете сотворять [Дружбу с животными](SPELL:${SPELL_ANIMAL_FRIENDSHIP}) неограниченное количество раз, используя эту способность, но только на змей.

При достижении 3 уровня, Вы также можете сотворять [Внушение](SPELL:${SPELL_SUGGESTION}). Сотворив его таким образом, Вы не можете сделать это ещё раз до завершения длинного отдыха.

Харизма — заклинательная характеристика для этих заклинаний.

# Сопротивление магии

Вы получаете преимущество к испытаниям от заклинаний и прочих магических эффектов.

# Иммунитет к яду

У Вас иммунитет к урону ядом и состоянию Отравление.

# Языки

Вы можете говорить, читать и писать на Всеобщем, Драконьем и языке Бездны.`,
      source: {
        id: SOURCE_VGTM,
        page: 122,
      },
    },
  ],
  size: {
    tall: {
      base: 56,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 110,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_CHA]: 2,
    [PARAM_INT]: 1,
  },
  source: {
    id: SOURCE_VGTM,
    page: 122,
  },
}

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_SUBRACE_ELF_MOON} = require('./../../../pcSubraceIdList')
const {SOURCE_SCAG} = require ('./../../../sourceList')

const highElfSubrace = require ('./../../constants/highElfSubrace')

module.exports = {
  ...highElfSubrace,
  id: PC_SUBRACE_ELF_MOON,
  name: {
    singular: {
      [GENDER_MALE]: 'лунный эльф',
      [GENDER_FEMALE]: 'лунная эльфийка',
    },
    dual: {
      [GENDER_MALE]: 'лунных эльфа',
      [GENDER_FEMALE]: 'лунные эльфийки',
    },
    plural: 'лунные эльфы',
  },
  nameShort: {
    singular: {
      [GENDER_MALE]: 'лунный',
      [GENDER_FEMALE]: 'лунная',
    },
    dual: {
      [GENDER_MALE]: 'лунных',
      [GENDER_FEMALE]: 'лунные',
    },
    plural: 'лунные',
  },
  nameEn: 'Moon Elves',
  nameAlt: [
    'Серебряные эльфы',
    'Серые эльфы',
    'Теу’Тель’Кессир',
  ],
  nameEnAlt: [
    'Silver Elves',
    'Gray Elves',
    'Teu’Tel’Quessir',
  ],
  description: [
    ...highElfSubrace.description,
    {
      header: `Лунные эльфы`,
      text: `Также называющиеся серебряными или _Теу’Тель’Куэссир_, лунные эльфы более толерантны и авантюрны, чем прочие эльфы. В древние времена, исчезновение их империй рассеяло лунных эльфов среди других рас, и с тех пор они не плохо уживались со своими не эльфийскими соседями. Они смешивались с другими народами, в то время как прочие представители их рода оставались в своих тайных поселениях и уединённых твердынях.

Лунные эльфы иногда представляются легкомысленными, особенно для прочих эльфов. Но эта лёгкость и переменчивая природа их культуры, философии и личности позволила им выжить и процветать в течение и после трагических событий эльфийской истории. Кроме того, что общины лунных эльфов можно отыскать по всему Фаэруну, множество лунных эльфов живёт среди прочих народов в их селениях, оседая там на несколько лет или даже десятилетий с тем чтобы однажды отправиться далее.

Для лунного эльфа дом может быть среди членов семьи, клана, друзей и любимых. Лунные эльфы, которые временно примкнули к общине солнечных эльфов или расположились рядом с нею, не стесняются выражать своего мнения, что их сородичам надо быть менее серьёзными. В свою очередь, солнечные эльфы притворяются более раздражёнными, чем то есть на самом деле, соседством с лунными эльфами, при условии, что причуды и авантюризм лунных эльфов не приносят серьёзных неудобств. Впрочем, такие ситуации редки в силу того, что лунные эльфы снимаются с мест раньше, чем исчерпают предоставленный лимит гостеприимства.

Лунные эльфы имеют расовые черты высших эльфов из Книги Игрока. Их кожа бледная с голубым. Цвет волос лунных эльфов такой же, как и у людей, но у некоторых из них волосы бывают серебристо-белого или одного из оттенков голубого цвета. Глаза у них голубые или зелёные с золотыми пятнышками. Учитывая любовь расы к путешествиям, исследованиям и новым впечатлениям, многие лунные эльфы становятся искателями приключений, должным образом применяя свои таланты в области военного дела, знания лесов и магии`,
      source: {
        id: SOURCE_SCAG,
        page: 106,
      },
    },
  ]
}

const {SOURCE_PHB} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {
  SETTING_EBERRON,
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
  SETTING_RAVNICA,
} = require ('./../../settingList')
const {
  PC_RACE_ELF,
  PC_RACE_HALF_ELF,
  PC_RACE_HUMAN,
} = require ('./../../pcRaceIdList')

const {
  PARAM_ANY,
  PARAM_CHA,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_HALF_ELF,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_HALF_ELF].name,
  nameEn: creatureTypeCollection[PC_RACE_HALF_ELF].nameEn,
  note: creatureTypeCollection[PC_RACE_HALF_ELF].note,
  description: [
    ...creatureTypeCollection[PC_RACE_HALF_ELF].description,
    {
      header: 'Особенности полуэльфов',
      text: `Ваш персонаж-полуэльф обладает некоторыми качествами, обычными для [эльфов](PC_RACE:${PC_RACE_ELF}), и некоторыми, присущими только полуэльфам.

# Увеличение характеристик

Харизма увеличивается на 2.

Две другие характеристики на Ваш выбор увеличиваются на 1.

# Возраст

Полуэльфы взрослеют с той же скоростью, что и [люди](PC_RACE:${PC_RACE_HUMAN}), и достигают зрелости к 20 годам. Они живут гораздо дольше [людей](PC_RACE:${PC_RACE_HUMAN}), часто пересекая рубеж в 180 лет.

# Мировоззрение

Полуэльфы унаследовали склонность к хаосу от своих эльфийских предков. Они одинаково ценят и личную свободу и творческое самовыражение, не проявляя ни тяги к лидерству, ни желания следовать за лидером. Их раздражают правила и чужие требования, и иногда они оказываются ненадёжными и непредсказуемыми.

# Размер

Полуэльфы почти такого же размера, как и [люди](PC_RACE:${PC_RACE_HUMAN}). Их рост колеблется от 5 до 6 футов (от 155 до 183 сантиметров). Ваш размер — Средний.

# Скорость

Ваша базовая скорость ходьбы равна 30 футам.

# Тёмное зрение

Благодаря своей эльфийской крови, Вы обладаете превосходным зрением в темноте и при тусклом освещении. На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Наследие фей

Вы получаете преимуществом к испытаниям от Очарования, и Вас невозможно магически усыпить.

# Универсальность навыков

Вы получаете владение двумя навыками на Ваш выбор.

# Языки

Вы можете говорить, читать и писать на Всеобщем, Эльфийском, и ещё одном языке на Ваш выбор.`,
      source: {
        id: SOURCE_PHB,
        page: 40,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 9,
      coefDice: {
        diceCount: 2,
        diceType: 8,
      },
    },
    weight: {
      base: 110,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_CHA]: 2,
    [PARAM_ANY]: [1, 1],
  },
  age: {
    adult: 20,
    max: 180,
  },
  setting: [
    SETTING_EBERRON,
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
    SETTING_RAVNICA,
  ],
  source: {
    id: SOURCE_PHB,
    page: 39,
  },
}

const {SOURCE_TWBTW} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_HARENGON} = require ('./../../pcRaceIdList')
const {SETTING_FORGOTTEN_REALMS} = require ('./../../settingList')
const customParamBonuses = require ('./../constants/customParamBonuses')
const customLanguages = require ('./../constants/customLanguages')

module.exports = {
  id: PC_RACE_HARENGON,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_HARENGON].name,
  nameEn: creatureTypeCollection[PC_RACE_HARENGON].nameEn,
  setting: SETTING_FORGOTTEN_REALMS,
  description: [
    ...creatureTypeCollection[PC_RACE_HARENGON].description,
    {
      header: 'Особенности зайчан',
      text: `
# Возраст

Зайчаны живут около века.

${customParamBonuses}

# Тип существа

Вы — гуманоид.

# Размер

Ваш размер — Маленький или Средний (выберите, когда выбираете эту расу).

# Скорость

Ваша скорость ходьбы — 30 футов.

# Заячьи чувства

Вы владеете Внимательностью.

# Удачные прыжки

Когда Вы проваливаете испытания Ловкости, Вы можете реакцией бросить к4 и добавить результат к броску, что потенциально может привести к успеху. Вы не можете использовать эту реакцию, если Вы лежите или Ваша скорость равна 0.

# Заячий скачок

Вы можете бонусным действием прыгнуть на количество футов, равное Вашему пятикратному бонусу мастерства, не провоцируя атак по возможности. Вы можете использовать эту способность только если Ваша скорость выше 0. Вы можете использовать эту способность количество раз, равное вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.

${customLanguages}`,
      columnCount: 2,
      source: {
        id: SOURCE_TWBTW,
        page: 13,
      },
    },
  ],
  source: {
    id: SOURCE_TWBTW,
    page: 13,
  },
}

import getRandomAdjective from '@/utils/nameGeneration/getRandomAdjective'
import getRandomNounByNumber from '@/utils/nameGeneration/getRandomNounByNumber'
import getRandomNumber from '@/utils/nameGeneration/getRandomNumber'
import getRandomNumberType from '@/utils/nameGeneration/getRandomNumberType'

export default ({genderId}) => {
    const numberType = getRandomNumberType()

    const number = getRandomNumber({genderId, numberType}).nominative
    const adjective = getRandomAdjective({genderId, numberType}).nominative
    const noun = getRandomNounByNumber({genderId, numberType})

    return `${number} ${adjective} ${noun}`
}

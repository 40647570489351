import nameSchemaList from '@/constants/nameGeneration/nameSchemaList'
import {PC_RACE_TABAXI} from '@/constants/pcRaceIdList'

export default nameSchemaList.map(
  item => ({
    ...item,
    show: {
      raceId: PC_RACE_TABAXI,
    },
  })
)

const {SOURCE_UA_2022_07_18_WotM} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {CREATURE_TYPE_GLITCHLING} = require ('./../../creatureTypeIdList')
const {PC_RACE_GLITCHLING} = require ('./../../pcRaceIdList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {
  SPELL_CURE_WOUNDS,
  SPELL_HEALING_WORD,
  SPELL_MASS_HEALING_WORD,
  SPELL_SPARE_THE_DYING,
} = require ('./../../spellIdList')
const customLanguages = require ('./../constants/customLanguages')
const customParamBonuses = require ('./../constants/customParamBonuses')

module.exports = {
  id: PC_RACE_GLITCHLING,
  isReady: true,
  name: creatureTypeCollection[CREATURE_TYPE_GLITCHLING].name,
  nameAlt: creatureTypeCollection[CREATURE_TYPE_GLITCHLING].nameAlt,
  nameEn: creatureTypeCollection[CREATURE_TYPE_GLITCHLING].nameEn,
  sizeType: SIZE_MEDIUM,
  description: [
    ...creatureTypeCollection[CREATURE_TYPE_GLITCHLING].description,
    {
      header: 'Особенности глюклингов',
      text: `Будучи глюклингом, Вы получаете следующие расовые особенности 

# Тип существа

Вы — конструкт.

# Размер

Ваш размер — Средний.

# Скорость

Ваша скорость ходьбы 30 футов.

${customParamBonuses}

# Бронепластины

Ваша металлическая кожа укреплена бронепластинами. Пока Вы не носите броню, Ваш КД равен 14 + модификатор Ловкости.

# Балансирование хаоса

Когда Вы совершаете бросок атаки или испытание и выбрасываете 9 или ниже на к20, Вы можете сбалансировать хаос и считать этот результат как 10. Вы можете сбалансировать хаос таким образом количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.

# Живой конструкт

Вы получаете все выгоды от простых заклинаний, которые сохраняют жизнь, но обычно не действуют на конструктов: [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}), [Лечащее слово](SPELL:${SPELL_HEALING_WORD}), [Множественное лечащее слово](SPELL:${SPELL_MASS_HEALING_WORD}) и [Уход за умирающим](SPELL:${SPELL_SPARE_THE_DYING}).

# Упорядоченный разум

Вы получаете преимущество к проверкам Мудрости (Проницательность) и на испытания для защиты от или прекращения на себе эффектов состояния Очарованности.

# Рудиментарные крылья

У Вас есть рудиментарные крылья, позволяющие ограниченно летать. Передвигаясь, Вы можете крыльями до конца текущего хода придать себе скорость полёта, равную Вашей скорости ходьбы. Если в конце хода Вы всё ещё в воздухе, и Вас ничто не поддерживает, то Вы падаете. Вы можете использовать эту черту количество раз, равное Вашему бонусу мастерства, и восстанавливаете все использования, когда заканчиваете длинный отдых.

${customLanguages}`,
      source: {
        id: SOURCE_UA_2022_07_18_WotM,
        page: 2,
      },
    },
  ],
  source: {
    id: SOURCE_UA_2022_07_18_WotM,
    page: 1,
  },
}

import generateFullNameTabaxi from './utils/generateFullNameTabaxi'
import generateRealGender from '@/utils/nameGeneration/generateRealGender'

const generateNameTabaxi = ({genderId: genderIdInput, ...rest}) => {
  const genderId = generateRealGender(genderIdInput, false)

  return generateFullNameTabaxi({genderId, ...rest})
}

export default generateNameTabaxi

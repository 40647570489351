import { GENDER_FEMALE, GENDER_MALE, GENDER_ANY } from '@/constants/genderList'

import nameListFemale from "./../dict/names_female"
import nameListMale from "./../dict/names_male"

export default genderId => {
  switch (genderId) {
    case GENDER_FEMALE: {
      return nameListFemale
    }

    case GENDER_MALE: {
      return nameListMale
    }

    default:
    case GENDER_ANY: {
      return [
        ...nameListFemale,
        ...nameListMale,
      ]
    }
  }
}

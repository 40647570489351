import { faGenderless } from '@fortawesome/free-solid-svg-icons'
import {
  PC_RACE_TABAXI,
} from '@/constants/pcRaceIdList'
import {
  genderCollection,
  GENDER_ANY,
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
} from '@/constants/genderList'

export default [
  {
    text: genderCollection[GENDER_ANY].name,
    value: GENDER_ANY,
  },
  {
    icon: 'icon-male',
    text: genderCollection[GENDER_MALE].name,
    value: GENDER_MALE,
  },
  {
    icon: 'icon-female',
    text: genderCollection[GENDER_FEMALE].name,
    value: GENDER_FEMALE,
  },
  {
    icon: faGenderless,
    text: genderCollection[GENDER_MIDDLE].name,
    value: GENDER_MIDDLE,
    show: {
      raceId: PC_RACE_TABAXI,
    },
  },
]

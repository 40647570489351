const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_DEX} = require ('./../../../paramList')
const {PC_RACE_GENASI} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_GENASI_AIR} = require('./../../../pcSubraceIdList')
const {SOURCE_EE} = require ('./../../../sourceList')
const {SPELL_LEVITATE} = require ('./../../../spellIdList')
const {CREATURE_DJINNI} = require ('./../../../creatureIdList')

module.exports = {
  id: PC_SUBRACE_GENASI_AIR,
  parentRaceId: PC_RACE_GENASI,
  name: {
    singular: {
      [GENDER_MALE]: 'генази воздуха',
      [GENDER_FEMALE]: 'генази воздуха',
    },
    dual: {
      [GENDER_MALE]: 'генази воздуха',
      [GENDER_FEMALE]: 'генази воздуха',
    },
    plural: 'генази воздуха',
  },
  nameEn: `Air Genasi`,
  description: [
    {
      header: `Генази воздуха`,
      text: `Будучи воздушным генази, Вы произошли от [джинна](CREATURE:${CREATURE_DJINNI}). Как изменчива погода, так и Ваше настроение очень быстро переходит от спокойного к буйному и жестокому, но эти бури не длятся долго.

Кожа, волосы и глаза генази воздуха обычно светло-голубого цвета. Обычно их сопровождает слабый, но постоянный ветерок, взъерошивающий их волосы и колышущий одежду. Некоторые из генази воздуха разговаривают хриплым голосом со слабым эхом. У некоторых на коже есть странные узоры, а на голове могут расти кристаллы.`,
      source: {
        id: SOURCE_EE,
        page: 9,
      },
    },
    {
      header: `Особенности генази воздуха`,
      text: `# Увеличение характеристик

Ловкость увеличивается на 1.

# Бесконечное дыхание

Пока Вы дееспособны, Вы можете задерживать дыхание на неопределённое время.

# Общение с ветром

Вы можете один раз наложить заклинание [Левитация] (SPELL:${SPELL_LEVITATE}), не нуждаясь в материальных компонентах. Вы не сможете вновь использовать эту способность, пока не окончите длинный отдых. Заклинательной характеристикой для её использования является Телосложение.`,
      source: {
        id: SOURCE_EE,
        page: 9,
      },
    },
  ],
  paramBonuses: {
    [PARAM_DEX]: 1,
  },
  source: {
    id: SOURCE_EE,
    page: 9,
  },
}

const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
} = require ('./../../sourceList')
const {SIZE_SMALL} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PARAM_INT} = require ('./../../paramList')
const {PC_RACE_GNOME} = require ('./../../pcRaceIdList')
const {
  SETTING_DRAGON_LANCE,
  SETTING_EBERRON,
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
  SETTING_GREYHAWK,
} = require ('./../../settingList')

module.exports = {
  id: PC_RACE_GNOME,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_GNOME].name,
  nameEn: creatureTypeCollection[PC_RACE_GNOME].nameEn,
  note: creatureTypeCollection[PC_RACE_GNOME].note,
  description: [
    ...creatureTypeCollection[PC_RACE_GNOME].description,
    {
      header: 'Гномы',
      text: 'Низкорослые, хитрые и энергичные гномы в течение своей долгой жизни неустанно исследуют все закоулки Фаэруна.',
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Особенности гномов`,
      text: `Ваш персонаж-гном обладает следующими особенностями, общими для всех гномов.

# Увеличение характеристик

Интеллект увеличивается на 2.

# Возраст

Гномы взрослеют с той же скоростью, что и люди, и вероятнее всего к 40 годам переходят к спокойной взрослой жизни. Они способны прожить от 350 до почти 500 лет.

# Мировоззрение

Гномы чаще всего добры. Стремящиеся к порядку обычно становятся мудрецами, инженерами, исследователями, учёными или изобретателями. Те, кто больше склонны к хаосу, становятся менестрелями, мошенниками, путешественниками или искусными ювелирами. Гномы добросердечны, и даже мошенники из них получаются скорее шутливые, чем злобные.

# Размер

Рост гномов между 3 и 4 футами (91 и 122 сантиметрами), а средний вес составляет 40 фунтов (18 килограмм). Ваш размер — Маленький.

# Скорость

Ваша базовая скорость перемещения равна 25 футам.

# Тёмное зрение

Привыкнув к жизни под землёй, Вы обладаете превосходным зрением в темноте и при тусклом освещении. На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Гномья хитрость

Вы совершаете с преимуществом испытания Интеллекта, Мудрости и Харизмы против магии.

# Языки

Вы можете говорить, читать и писать на Общем и Гномьем языках. Гномий язык, использующий дварфийский алфавит, хорошо известен благодаря техническим трактатам и каталогам знаний об окружающем мире.

# Разновидности

В мирах D&D встречаются два вида гномов — скальные и лесные гномы. Выберите один из этих видов.`,
      source: {
        id: SOURCE_PHB,
        page: 37,
      },
    },
  ],
  sizeType: SIZE_SMALL,
  size: {
    tall: {
      base: 2 * 12 + 11,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
    weight: {
      base: 35,
      coefDice: {
        diceBonus: 1,
      },
    },
  },
  paramBonuses: {
    [PARAM_INT]: 2,
  },
  age: {
    adult: 20,
    max: 500,
  },
  setting: [
    SETTING_DRAGON_LANCE,
    SETTING_EBERRON,
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
    SETTING_GREYHAWK,
  ],
  source: {
    id: SOURCE_PHB,
    page: 36,
  },
}

const {SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {CREATURE_TYPE_GIFF} = require ('./../../creatureTypeIdList')
const {PC_RACE_GIFF, PC_RACE_GIFF_2021_10_08} = require ('./../../pcRaceIdList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {
  SETTING_DRAGON_LANCE,
  SETTING_GREYHAWK,
  SETTING_FORGOTTEN_REALMS,
} = require ('./../../settingList')
const customLanguages = require ('./../constants/customLanguages')
const customParamBonuses = require ('./../constants/customParamBonuses')

module.exports = {
  id: PC_RACE_GIFF_2021_10_08,
  isReady: true,
  releasedAs: PC_RACE_GIFF,
  name: creatureTypeCollection[CREATURE_TYPE_GIFF].name,
  nameEn: creatureTypeCollection[CREATURE_TYPE_GIFF].nameEn,
  sizeType: SIZE_MEDIUM,
  setting: [
    SETTING_DRAGON_LANCE,
    SETTING_GREYHAWK,
    SETTING_FORGOTTEN_REALMS,
  ],
  description: [
    ...creatureTypeCollection[CREATURE_TYPE_GIFF].description,
    {
      header: 'Гиффы',
      text: `Гиффы — высокий широкоплечий народ, внешне схожий с бегемотами. У одних гиффов гладкая кожа, а у других она покрыта короткими щетинками на лице и макушке головы. Как существа внушительных размеров и незабываемой внешности, гиффы везде привлекают внимание.

Рассказывание историй — широко распространённая традиция гиффов. Нередко можно увидеть, как гиффы рассказывают о своих прошлых подвигах восхищенной толпе. Очень полезным будет иметь под рукой друга-гиффа, если в таверне начинается драка. Это позволит гиффу наконец-то перестать сдерживаться и помочь окружающим перевести приятную перебранку в кулачный бой.

Гиффы разделены на два лагеря в зависимости от того, как они произносят своё самоназвание. Половина из них произносят твёрдое «гифф», а другая половина — мягкое «жифф». Разногласия по поводу правильного произношения часто перерастают в обиды, громкие споры и бодание лбами, но редко перерастают во что-то более серьезное.`,
      source: {
        id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
        page: 2,
      },
    },
    {
      header: 'Особенности гиффов',
      text: `Вы получаете следующие расовые особенности 

# Тип существа

Вы — гуманоид.

# Размер

Ваш размер — Средний.

${customParamBonuses}

# Скорость

Ваша скорость ходьбы 30 футов. Вы получаете скорость плавания, равную скорости ходьбы.

# Разрушитель

Как бегемот в посудной лавке, Вы от природы приспособлены к разрушению вещей. Когда у Вас выпадает 1 на кости урона рукопашной атаки, Вы можете перебросить эту кость и использовать новый результат. Вы можете делать так не больше одного раза в ход.

# Телосложение бегемота

Вы получаете преимущество на все проверки Силы и испытания Силы. Кроме того, Вы считаетесь на один размер больше, когда определяется Ваша грузоподъемность и вес, который Вы можете толкать, тянуть или поднимать.

${customLanguages}`,
      source: {
        id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
        page: 3,
      },
    },
  ],
  source: {
    id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
    page: 2,
  },
}

import React from "react"

import trackAnalyticEvent from '@/utils/trackAnalyticEvent'

import generateFullName from "./utils/generateFullName"

import NameGeneratorComponent from "./NameGeneratorComponent"

class NameGeneratorContainer extends React.Component {
  generate = (...args) => {
    trackAnalyticEvent('generate-name')

    return generateFullName(...args)
  }

  render() {
    return (
      <NameGeneratorComponent generate={this.generate} />
    )
  }
}

export default NameGeneratorContainer

import _ from "lodash";

import generateRealGender from '@/utils/nameGeneration/generateRealGender'
import Name from '@/constructors/Name'
import { PC_RACE_GRUNG } from '@/constants/pcRaceIdList'

import generateNameListGrung from "./utils/generateNameListGrung"

export default ({genderId: genderIdInput}) => {
  const genderId = generateRealGender(genderIdInput)

  return new Name({
    firstName: _.sample(generateNameListGrung(genderId)),
    genderId,
    raceId: PC_RACE_GRUNG,
  })
}

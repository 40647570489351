const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_TUIGAN} = require('./../../../pcSubraceIdList')
const {SOURCE_SCAG} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_TUIGAN,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: `туйган`,
      [GENDER_FEMALE]: `туйганка`,
    },
    dual: {
      [GENDER_MALE]: `туйгана`,
      [GENDER_FEMALE]: `туйганки`,
    },
    plural: `туйганы`,
  },
  nameEn: `Tuigan`,
  description: `Кочевая орда с бескрайних равнин, расположенных между Фаэруном и Кара-Туром, туйганы однажды почти завоевали Фаэрун под предводительством Ямун Кагана, но были повержены коалицией армий. С тех пор туйганов иногда встречают на Побережье Мечей и в соседних регионах, но не в огромных количествах.

Туйганы похожи на людей Шу бронзовыми и золотыми оттенками кожи и тёмными волосами, но их кожа немного темнее, а черты лица — шире. Каждый из них обладает только одним именем (иногда передаваемым от одного из родителей). Туйганы не пользуются фамилиями.

Не чуждые путешествиям, туйганские торговцы и искатели приключений часто знакомы с многими языками и культурами.

# Туйганские имена

## Мужские

Атлан, Баяр, Монгке, Тимур, Чингиз, Чинуа

## Женские

Болормаа, Бортай, Наран, Эрдене`,
  source: {
    id: SOURCE_SCAG,
    page: 112,
  },
}

import { PC_RACE_ANY } from "@/constants/pcRaceIdList"
import { pcRaceCollection } from '@/constants/pcRaceList'
import {
  GENDER_ANY,
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_MIDDLE,
} from "@/constants/genderList"

const EMPTY = ''

class Name extends String {
  firstName = EMPTY
  lastName = EMPTY
  nickName = EMPTY
  patronymic = EMPTY

  clan = EMPTY
  tribe = EMPTY

  raceId = PC_RACE_ANY
  genderId = GENDER_ANY

  constructor(data) {
    super(data)

    switch (typeof data) {
      case 'string': {
        //                 first     _1   nick   _2  last        _3  patr        _4             tribe _5           clan
        const nameRegex = /([^\s«»]+)(\s+«(.+)»)?(\s+([^\s«»]+))?(\s+([^\s«»]+))?(\s+из племени (.+))?(\s+из клана (.+))?/
        const [
          _0,
          firstName = EMPTY,
          _1,
          nickName = EMPTY,
          _2,
          lastName = EMPTY,
          _3,
          patronymic = EMPTY,
          _4,
          tribe = EMPTY,
          _5,
          clan = EMPTY,
        ] = nameRegex.exec(data) || ['']

        const [] = data.split(' ')

        this.firstName = firstName
        this.nickName = nickName
        this.lastName = lastName
        this.patronymic = patronymic

        this.tribe = tribe
        this.clan = clan
        break;
      }

      case 'object': {
        const {
          firstName = EMPTY,
          lastName = EMPTY,
          nickName = EMPTY,
          patronymic = EMPTY,

          clan = EMPTY,
          tribe = EMPTY,

          genderId = GENDER_ANY,
          raceId = PC_RACE_ANY,
        } = data

        this.firstName = firstName
        this.lastName = lastName
        this.nickName = nickName
        this.patronymic = patronymic
        this.clan = clan
        this.tribe = tribe
        this.raceId = raceId
        this.genderId = genderId
        break;
      }

      default:
        this.firstName = data
        break;
    }
  }

  generateRaceText = () => {
    if (this.raceId !== PC_RACE_ANY) {
      const {singular} = pcRaceCollection[this.raceId].name

      return `, ${(
        singular[this.genderId] || singular[GENDER_MALE]
      ).nominative}`
    }

    return ''
  }

  generateGenderSymbol = () => this.genderId
    ? `, ${
      this.genderId === GENDER_FEMALE
        ? '♀'
        : this.genderId === GENDER_MALE
        ? '♂'
        : this.genderId === GENDER_ANY || this.genderId === GENDER_MIDDLE
          ? '⚥'
          : '?'
    }`
    : ''

  toString = () => {
    const clan = this.clan ? ` из клана ${this.clan}` : ''
    const tribe = this.tribe ? ` из племени ${this.tribe}` : ''
    const nick = this.nickName ? ` «${this.nickName}»` : ''

    const patronymic = this.patronymic ? ` ${this.patronymic}` : ''
    const lastName = this.lastName ? ` ${this.lastName}` : ''

    const race = this.generateRaceText()
    const gender = this.generateGenderSymbol()

    return `${this.firstName}${nick}${lastName}${patronymic}${clan}${tribe}${race}${gender}`
  }
}

export default Name

const {
  SOURCE_ERRATA_VGTM,
  SOURCE_VGTM,
} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_ORC} = require ('./../../pcRaceIdList')
const {monsterAdventurers} = require ('./../../textCommonParts')
const {
  SETTING_EBERRON,
  SETTING_FORGOTTEN_REALMS,
  SETTING_GREYHAWK,
} = require ('./../../settingList')
const {
  PARAM_STR,
  PARAM_CON,
  PARAM_INT,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_ORC,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_ORC].name,
  nameEn: creatureTypeCollection[PC_RACE_ORC].nameEn,
  note: creatureTypeCollection[PC_RACE_ORC].note,
  description: [
    ...creatureTypeCollection[PC_RACE_ORC].description,
    ...monsterAdventurers,
    {
      header: 'Особенности орков',
      text: `Ваш персонаж-орк обладает следующими особенностями.

# Увеличение характеристик

Сила увеличивается на 2.

Телосложение увеличивается на 1.

# Возраст

Орки достигают зрелости к 12 годам, и живут до 50 лет.

# Мировоззрение

Орки — злобные налетчики, которые полагают, что мир должен принадлежать им. Также они уважают силу больше всего остального и полагают, что сильные должны издеваться над слабыми, чтобы гарантировать, что слабость не распространится как болезнь. Они обычно хаотично-злые.

# Размер

Орки обычно больше 6 футов в высоту и весят от 230 до 280 фунтов. Ваш размер — Средний.

# Скорость

Ваша базовая скорость ходьбы составляет 30 футов.

# Тёмное зрение

На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Агрессивность

Вы можете бонусным действием совершить перемещение со своей полной скоростью к выбранному вами врагу, которого Вы можете видеть или слышать. Вы должны закончить это перемещение ближе к врагу, чем начинали.

# Первобытная интуиция

Вы владеете двумя навыками из списка

* Внимательность,
* Выживание,
* Запугивание,
* Медицина,
* Обращение с животными,
* Природа,
* Проницательность.

# Мощное телосложение

Вы считаетесь на один размер больше когда определяется ваша грузоподъемность и вес, который Вы можете толкать, тянуть или поднимать.

# Языки

Вы можете говорить, читать и писать на Всеобщем и Орочьем языках.`,
      columnCount: 2,
      source: [
        {
          id: SOURCE_VGTM,
          page: 121,
        },
        {
          id: SOURCE_ERRATA_VGTM,
          title: 'Убран штраф к Интеллекту; Угрожающий вид заменён на Первобытную интуицию (два навыка вместо одного)',
          page: 1,
        },
      ],
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 5 * 12 + 10,
      coefDice: {
        diceCount: 2,
        diceType: 8,
      },
    },
    weight: {
      base: 190,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 2,
    [PARAM_CON]: 1,
    [PARAM_INT]: -2,
  },
  age: {
    adult: 12,
    max: 50,
  },
  setting: [
    SETTING_EBERRON,
    SETTING_FORGOTTEN_REALMS,
    SETTING_GREYHAWK,
  ],
  source: {
    id: SOURCE_VGTM,
    page: 121,
  },
}

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_STR} = require ('./../../../paramList')
const {PC_RACE_GITH} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_GITH_GITHYANKI} = require('./../../../pcSubraceIdList')
const {CREATURE_TYPE_GITHYANKI} = require('./../../../creatureTypeIdList')
const {creatureTypeCollection} = require('./../../../creatureTypeList')
const {
  SOURCE_MGZN_DNGN_100,
  SOURCE_MTOF,
} = require ('./../../../sourceList')
const {
  GEAR_GREATSWORD,
  GEAR_LONGSWORD,
  GEAR_SHORTSWORD,
} = require ('./../../../gearIdList')
const {
  SPELL_JUMP,
  SPELL_MAGE_HAND,
  SPELL_MISTY_STEP,
} = require ('./../../../spellIdList')

module.exports = {
  id: PC_SUBRACE_GITH_GITHYANKI,
  parentRaceId: PC_RACE_GITH,
  name: {
    singular: {
      [GENDER_MALE]: 'гитьянки',
      [GENDER_FEMALE]: 'гитьянки',
    },
    dual: {
      [GENDER_MALE]: 'гитьянки',
      [GENDER_FEMALE]: 'гитьянки',
    },
    plural: 'гитьянки',
  },
  nameEn: creatureTypeCollection[CREATURE_TYPE_GITHYANKI].nameEn,
  size: {
    tall: {
      base: 5 * 12,
      coefDice: {
        diceCount: 2,
        diceType: 12,
      },
    },
    weight: {
      base: 100,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
    source: {
      id: SOURCE_MTOF,
      page: 97,
    },
  },
  note: creatureTypeCollection[CREATURE_TYPE_GITHYANKI].note,
  description: [
    ...creatureTypeCollection[CREATURE_TYPE_GITHYANKI].description,
    {
      header: `Особенности гитьянок`,
      text: `Гитьянки жестоки, их с детства воспитывают как воинов.

# Увеличение характеристик

Сила увеличивается на 2.

# Мировоззрение

Гитьянки склонны к принципиально-злому мировоззрению. Они агрессивны, высокомерны и остаются верными слугами своей королевы-лича, Влаакит.

Гитьянки-отступники стремятся к хаотичности.

# Декадентское мастерство

Вы изучаете один язык по Вашему выбору и владеете одним навыком или инструментом по Вашему выбору. В вечном городе Ту’нарате у гитьянки полно времени чтобы чему-нибудь научиться.

# Воинское воспитание

Вы владеете средней и тяжёлой бронёй, [короткими](GEAR:${GEAR_SHORTSWORD}), [длинными](GEAR:${GEAR_LONGSWORD}) и [двуручными](GEAR:${GEAR_GREATSWORD}) мечами.

# Псионика гитьянки

Вы знаете заговор [Волшебная рука](SPELL:${SPELL_MAGE_HAND}), при этом рука невидима.

По достижении 3-го уровня Вы сможете раз в день сотворить заклинание [Прыжок](SPELL:${SPELL_JUMP}).

По достижении 5 уровня, Вы сможете раз в день сотворить заклинание [Туманный шаг](SPELL:${SPELL_MISTY_STEP}).

_«Раз в день»_ означает, что Вы должны окончить длинный отдых, прежде чем сможете сотворить этой способностью это заклинание снова. Интеллект — заклинательная характеристика для этих заклинаний.

Заклинания, сотворяемые с помощью этой способности, не требуют материальных компонентов.`,
      source: {
        id: SOURCE_MTOF,
        page: 97,
      },
    },
    {
      header: `Имена гитьянки`,
      text: `# Мужские имена

Бвид, Вельдрада, Вельд’р’р, Виран, Гаат, Галдвиск, Дэбрелькс, Жа’адок, Жоарт, Ж’ладмир, Замодас, Зарид’ай
Карлуф, Кар’и’нас, Кель’и’жит, Куис, Ликус, Нинир, Н’а’рай, Осо’жен, Реч, Рис’а’н, Су’лукел, Техв’ин, Тропос, Элирдэйн, 
# Женские имена

Аарил, Айлаареб, Б’нур, Вайра, Варист, Во’рид, Дулурин, Ессунь, Жен’лиг, Зар’рит   
Куорстил, Мадив, Маластра, Мерикал, Па’зел, Сиррут, Тарэйн, Тонуин’ли, Ун’рут, Фенелзи’ир, Фенэл, Харанор, Ч’а’нев, Шаркад, Эри’инт`,
      source: {
        id: SOURCE_MGZN_DNGN_100,
        page: 101,
      },
    },
  ],
  paramBonuses: {
    [PARAM_STR]: 2,
  },
  source: {
    id: SOURCE_MTOF,
    page: 97,
  },
}

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_HUMAN_ULUTIUN} = require('./../../../pcSubraceIdList')
const {SOURCE_SCAG} = require ('./../../../sourceList')

module.exports = {
  id: PC_SUBRACE_HUMAN_ULUTIUN,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: `улутиун`,
      [GENDER_FEMALE]: `улутиунка`,
    },
    dual: {
      [GENDER_MALE]: `улутиуна`,
      [GENDER_FEMALE]: `улутиунки`,
    },
    plural: `улутиуне`,
  },
  nameEn: `Ulutiun`,
  description: `Улутиуне низкорослый и черноволосый народ с кожей золотого оттенка, происходящие из северного Кара-Тура, мигрировавший на запад в Долину Ледяного Ветра и другие холодные земли, смежные с Бескрайним Морем Льда.

Охотники и собиратели, улутиуне живут маленькими племенами, которым удаётся выживать в наиболее жёстких условиях этого мира.

Каждый из них носит только одно имя (иногда передаваемым от одного из родителей); улутиуне не пользуются фамилиями.

# Имена улутиун

## Мужские

Амак, Имнек, Канут, Сику, Чу

## Женские

Акна, Кайя, Седна, Ублереак, Чена`,
  source: {
    id: SOURCE_SCAG,
    page: 113,
  },
}

import React from "react"
import PropTypes from "prop-types"

import ResultViewComponent from "./ResultViewComponent"

class ResultViewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  selectText = () => {
    window.getSelection().selectAllChildren(this.ref.current);
  }

  render() {
    const { result, savedItemView } = this.props
    const { ref, selectText } = this

    return (
      <ResultViewComponent
        refForClick={ref}
        result={result}
        savedItemView={savedItemView}
        selectText={selectText}
      />
    )
  }
}

ResultViewContainer.propTypes = {
  result: PropTypes.string,
  savedItemView: PropTypes.bool
}

ResultViewContainer.defaultProps = {
  savedItemView: false,
}

export default ResultViewContainer

const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_HUMAN} = require ('./../../pcRaceIdList')
const {LANG_COMMON} = require ('./../../languageIdList')
const {
  SETTING_DRAGON_LANCE,
  SETTING_EBERRON,
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
  SETTING_GREYHAWK,
  SETTING_RAVNICA,
  SETTING_THEROS,
} = require ('./../../settingList')

const {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_HUMAN,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_HUMAN].name,
  nameEn: creatureTypeCollection[PC_RACE_HUMAN].nameEn,
  note: creatureTypeCollection[PC_RACE_HUMAN].note,
  description: [
    ...creatureTypeCollection[PC_RACE_HUMAN].description,
    {
      header: 'Человек',
      text: 'Люди — самая распространённая раса в Фаэруне. Они настойчивы, изобретательны и очень плодовиты.',
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Особенности людей`,
      text: `Сложно делать какие-либо обобщения относительно людей, но ваш персонаж-человек обладает следующими особенностями.

# Увеличение характеристик

Значение всех Ваших характеристик увеличивается на 1.

# Возраст

Люди становятся взрослыми в районе 20 лет, и живут менее столетия.

# Мировоззрение

Люди не имеют склонности к определённому мировоззрению. Среди них встречаются как лучшие, так и худшие представители.

# Размер

Люди сильно различаются по размерам. Некоторые с трудом достигают 5 футов (152 сантиметров) ростом, тогда как другие имеют рост, превосходящий 6 футов (183 сантиметра).

Вне зависимости от роста, Ваш размер — Средний.

# Скорость

Ваша базовая скорость перемещения составляет 30 футов.

# Языки

Вы можете говорить, читать и писать на [Всеобщем](LANG:${LANG_COMMON}) и ещё одном языке на ваш выбор. Люди обычно изучают языки народов, с которыми имеют дело, включая редкие диалекты. Они любят разбавлять собственную речь словами, позаимствованными из других языков: орочьими ругательствами, эльфийскими музыкальными терминами, дварфийскими военными командами.`,
      columnCount: 2,
      source: {
        id: SOURCE_PHB,
        page: 30,
      },
    },
    {
      header: `Альтернативные особенности людей`,
      text: `Если в своей компании Вы используете дополнительные правила об использовании [черт](/feat-catalog), Ваш Мастер может позволить использовать эти альтернативные особенности вместо указанного выше стандартного увеличения характеристик.

# Увеличение характеристик

Значение двух характеристик на Ваш выбор увеличивается на 1.

# Навыки

Вы получаете владение одним навыком на Ваш выбор.

# Черта

Вы получаете одну [черту](/feat-catalog) на Ваш выбор.`,
      source: {
        id: SOURCE_PHB,
        page: 30,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 8,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 110,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 1,
    [PARAM_DEX]: 1,
    [PARAM_CON]: 1,
    [PARAM_INT]: 1,
    [PARAM_WIT]: 1,
    [PARAM_CHA]: 1,
  },
  setting: [
    SETTING_DRAGON_LANCE,
    SETTING_EBERRON,
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
    SETTING_GREYHAWK,
    SETTING_RAVNICA,
    SETTING_THEROS,
  ],
  source: {
    id: SOURCE_PHB,
    page: 30,
  },
}

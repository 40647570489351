const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_ANY} = require ('./../../../paramList')
const {PC_RACE_ELF} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_ELF_ASTRAL} = require('./../../../pcSubraceIdList')
const {SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE} = require ('./../../../sourceList')
const {
  SPELL_DANCING_LIGHTS,
  SPELL_LIGHT,
  SPELL_SACRED_FLAME,
} = require ('./../../../spellIdList')

module.exports = {
  id: PC_SUBRACE_ELF_ASTRAL,
  parentRaceId: PC_RACE_ELF,
  name: {
    singular: {
      [GENDER_MALE]: 'астральный эльф',
      [GENDER_FEMALE]: 'астральная эльфийка',
    },
    dual: {
      [GENDER_MALE]: 'астральных эльфа',
      [GENDER_FEMALE]: 'астральные эльфийки',
    },
    plural: 'астральные эльфы',
  },
  nameShort: {
    singular: {
      [GENDER_MALE]: 'астральный',
      [GENDER_FEMALE]: 'астральная',
    },
    dual: {
      [GENDER_MALE]: 'астральных',
      [GENDER_FEMALE]: 'астральные',
    },
    plural: 'астральные',
  },
  nameEn: 'Astral Elves',
  description: [
//     {
//       header: `Астральные эльфы`,
//       text: `Long ago, groups of elves ventured from the Feywild to the
//
// Astral Plane to be closer to their gods. Life in the Silver Void has imbued their souls with a spark of divine light. That light manifests as a starry gleam in an astral elf’s eyes.
//
// Because nothing ages in the Astral Plane, astral elves from that plane are thousands of years old, and their ©2021 Wizards of the Coast LLC 2 longevity gives them an unusual perspective on time. Some are prone to melancholy, while others display an absence of feeling. Many look for creative ways to occupy themselves. Whether they choose to live in quiet contemplation or strike out to explore the reaches of the multiverse, they tend to see things through the lens of time as having little or no meaning to them.
//
// Astral elves who don’t dwell on the Astral Plane can live to be over 750 years old. `,
//       source: {
//         id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
//         page: 1,
//       },
//     },
    {
      header: `Способности астральных эльфов`,
      text: `
# Астральное пламя

Вы знаете заклинание один из следующих заговоров по Вашему выбору

* [Пляшущие огоньки](SPELL:${SPELL_DANCING_LIGHTS}).
* [Свет](SPELL:${SPELL_LIGHT}).
* [Священное пламя](SPELL:${SPELL_SACRED_FLAME}).

Ваша заклинательная характеристика для этих заклинаний — Интеллект, Мудрость или Харизма (выберите, когда выбираете эту расу).

# Светящаяся душа

Преуспев в испытании от смерти, Вы можете восстановить число хитов, равное бонусу мастерства + бонус Интеллекта, Мудрости или Харизмы (выберите, когда выбираете эту расу). Вы не можете снова использовать эту способность, пока не завершите длинный отдых. 

# Трансовые владения

Завершаете длинный отдых способностью Транс, Вы получаете два владения (оружием или инструментами, на Ваш выбор). Вы мистическим образом узнаёте как пользоваться этими вещами, получив знания из общей эльфийской памяти. Вы сохраняете эти владения до завершения следующего длинного отдыха.`,
      source: {
        id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
        page: 1,
      },
    },
  ],
  paramBonuses: {
    [PARAM_ANY]: 1,
  },
  source: {
    id: SOURCE_UA_2021_10_08_TRAVELERS_OF_THE_MULTIVERSE,
    page: 1,
  },
}

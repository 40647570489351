const arrify = require('arrify')

const listToCollectionById = require('./../../utils/listToCollectionById')

const Race = require('./../../constructors/Race')

const {
  PC_RACE_HALF_ELF,
  PC_RACE_HALF_ORC,
  PC_RACE_TIEFLING,
} = require ('./../pcRaceIdList')

const {SIZE_SMALL} = require('./../sizeList')

const pcRaceRawList = require('./pcRaceRawList')

const pcRaceList = pcRaceRawList.map((race, i, list) => new Race(race, list))

module.exports = pcRaceList

module.exports.pcRaceCollection = listToCollectionById(pcRaceList)
module.exports.pcHalfRaceIdList = [
  PC_RACE_HALF_ELF,
  PC_RACE_HALF_ORC,
  PC_RACE_TIEFLING,
]

module.exports.pcSmallRaceIdList = pcRaceList
  .filter(
    ({sizeType}) => sizeType === SIZE_SMALL
  )
  .map(({id}) => id)

module.exports.pcRaceSizeIdList = pcRaceList.reduce(
  (list, {sizeType}) => {
    const sizeTypeList = arrify(sizeType)

    return sizeTypeList.find(size => list.includes(size))
      ? list
      : [
        ...list,
        ...sizeTypeList,
      ]
  },
  []
)

const {SOURCE_GGTR} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_MINOTAUR} = require ('./../../pcRaceIdList')
const {
  SETTING_DRAGON_LANCE,
  SETTING_RAVNICA,
  SETTING_THEROS,
} = require ('./../../settingList')

const {
  PARAM_STR,
  PARAM_CON,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_MINOTAUR,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_MINOTAUR].name,
  nameEn: creatureTypeCollection[PC_RACE_MINOTAUR].nameEn,
  description: [
    ...creatureTypeCollection[PC_RACE_MINOTAUR].description,
    {
      header: 'Особенности минотавров',
      text: `Ваш персонаж минотавр имеет слдующие расовые особенности. Эти особенности также подходят для минотавров в других D&D мирах, где этот народ избежал демонического влияния Бафомета.

# Увеличение характеристик

Сила увеличивается на 2.

Телосложение увеличивается на 1.

# Мировоззрение

Большинство минотавров, вступивших в легион Боросов тяготеют к принципиальному мировоззрению, а те, кто оотносятся к культу Ракдоса или кланам Груулов, тяготеют к хаотичному мировоззрению.

# Размер

Минотавры в среднем 6 футов ростом и коренастого телосложения. Ваш размер — Средний.

# Скорость

Ваша базовая скорость ходьбы 30 футов.

# Рога

Ваши рога — природное рукопашное оружие, которым Вы можете совершать безоружные удары. Попав атакой _Рогами_, Вы наносите 1к6 + модификатор Силы колющего урона, вместо обычного для безоружных атак урона.

# Бодающий бросок

Немедленно после того, как Вы используете Рывок на своём ходу и передвигаетесь минимум на 20 футов, Вы можете совершить бонусным действием одну атаку _Рогами_.

# Сокрушающие рога

Немедленно после того, как Вы на своём ходу попадаете по существу рукопашной атакой частью действия Атака, Вы можете бонусным действием ударить цель _Рогами_. Цель должна быть не больше чем на один размер больше Вас и находиться в пределах 5 футов. Если оно провалит испытание Силы (СЛ 8 + бонус мастерства + модификатор Силы), Вы отталкиваете его на 10 футов от себя.

# Впечатляющее присутствие

Вы владете одним из навыков на Ваш выбор
 
* Запугивание,
* Убеждение.

# Языки

Вы можете говорить, читать и писать на Всеобщем и Минотаврском.`,
      source: {
        id: SOURCE_GGTR,
        page: 20,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 5 * 12 + 4,
      coefDice: {
        diceCount: 2,
        diceType: 8,
      },
    },
    weight: {
      base: 175,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 2,
    [PARAM_CON]: 1,
  },
  age: {
    adult: 18, // Not official https://www.dandwiki.com/wiki/Minotaur_(5e_Race_Variant)
    max: 150, // Not official https://www.dandwiki.com/wiki/Minotaur_(5e_Race_Variant)
  },
  setting: [
    SETTING_DRAGON_LANCE,
    SETTING_RAVNICA,
    SETTING_THEROS,
  ],
  source: {
    id: SOURCE_GGTR,
    page: 20,
  },
}

import _ from "lodash";

import generateRealGender from "@/utils/nameGeneration/generateRealGender"
import { PC_RACE_ELF } from "@/constants/pcRaceIdList"
import Name from "@/constructors/Name"

import generateNameListElf from "./utils/generateNameListElf"
import familyNameList from "./dict/names_family"
import childNameList from "./dict/names_child"

export default ({genderId: genderIdInput, age}) => {
  const genderId = generateRealGender(genderIdInput)

  return new Name(
    age === 'child'
      ? {
        firstName: _.sample(childNameList),
        raceId: PC_RACE_ELF,
        genderId,
      }
      : {
        firstName: _.sample(generateNameListElf(genderId)),
        lastName: _.sample(familyNameList),
        genderId,
        raceId: PC_RACE_ELF,
      }
  )
}

const {
  SOURCE_GAME_BG_3,
  SOURCE_SCAG,
} = require ('./../../../sourceList')
const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_RACE_HUMAN} = require ('./../../../pcRaceIdList')
const {PC_CLASS_CLERIC} = require('./../../../pcClassIdList')
const {PC_SUBRACE_HUMAN_GUR} = require('./../../../pcSubraceIdList')
const {GOD_SELUNE} = require ('./../../../godIdList')

module.exports = {
  id: PC_SUBRACE_HUMAN_GUR,
  parentRaceId: PC_RACE_HUMAN,
  name: {
    singular: {
      [GENDER_MALE]: `гур`,
      [GENDER_FEMALE]: `гура`,
    },
    dual: {
      [GENDER_MALE]: `гура`,
      [GENDER_FEMALE]: `гуры`,
    },
    plural: `гуры`,
  },
  nameEn: `Gur`,
  nameAlt: `Гурцы`,
  description: [
    {
      header: `Гуры`,
      text: `Гуры коренастые, серокожие и темноволосые, имеют связь с Рашеми. Они считают себя «детьми [Селунэ](GOD:${GOD_SELUNE})» и большинство из них почитают лунную богиню. Общины гуров ведут кочевой образ жизни в Западном Сердцеземье, что ведёт к тому, что другие народы называют их «народом дорог».

# Гурские имена

## Мужские

Борив, Влад, Гардар, Мадевик

## Женские

Варра, Имза, Наварра, Ульмара, Юльдра

## Фамилии

Варгоба, Дразлад, Стаянкина, Тазъяра, Чергоба`,
      source: {
        id: SOURCE_SCAG,
        page: 112,
      },
    },
    {
      header: `Доступный Восток, часть 3`,
      text: `О гурцах я знал только что, как рассказывают, они умеют предсказывать будущее. Однако как можно сказать, что ты знаешь кого-то, если ни разу не сидел с ним у одного костра и не обменивался историями? У обочины дороги, над котелком с кипящей похлёбкой гурцы поведали мне что называют себя «детьми [Селунэ](GOD:${GOD_SELUNE})» и возводят свой род к далёкой древности Рашемена.

В гурских легендах рассказывается о двух молодых _викларан_ (ведьмах!), которые отказались слушаться своих наставников. В раздражении те оборотили юных дев певчими птицами и заточили в золотую клетку, сказав, что, если те не желают учиться, то пусть хотя бы услаждают их слух пением. Но _викларан_ удалось сбежать из клетки и улететь на другой конец Фаэруна. Там, утомлённые, они спустились в сад некоего [жреца](PC_CLASS:${PC_CLASS_CLERIC}), чтобы отдохнуть. Этот [жрец](PC_CLASS:${PC_CLASS_CLERIC}) произнёс над ними благословение [Селунэ](GOD:${GOD_SELUNE}) и тем разрушил заклятье и вернул им человеческий облик. С тех пор они считают себя детьми [Селунэ](GOD:${GOD_SELUNE}).

Все гурцы возводят свой род к этим двум девам — некоторые из их матриархов даже берут себе титул «дева». Разумеется, как возлюбленные дети [Селунэ](GOD:${GOD_SELUNE}) они больше не практикуют _викларанское_ ведьмовство, но говорят что оно всё равно у них в крови и позволяет иногда заглядывать в будущее.

Великолепная история (да ещё и с аппетитной похлёбкой). Но насколько она правдива? Узнаю, когда доберусь до Рашемена. Быть может, в тех краях её рассказывают совсем иначе.

— Путевые заметки Райана Форбека`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  source: {
    id: SOURCE_SCAG,
    page: 112,
  },
}

const {SOURCE_PHB} = require ('./../../sourceList')
const {SIZE_SMALL} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PARAM_DEX} = require ('./../../paramList')
const {PC_RACE_HALFLING} = require ('./../../pcRaceIdList')
const {
  SETTING_EBERRON,
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
  SETTING_GREYHAWK,
} = require ('./../../settingList')
const {
  LANG_COMMON,
  LANG_HALFLING,
} = require ('./../../languageIdList')

module.exports = {
  id: PC_RACE_HALFLING,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_HALFLING].name,
  nameAlt: 'хин',
  nameEn: creatureTypeCollection[PC_RACE_HALFLING].nameEn,
  nameEnAlt: creatureTypeCollection[PC_RACE_HALFLING].nameEnAlt,
  note: creatureTypeCollection[PC_RACE_HALFLING].note,
  description: [
    ...creatureTypeCollection[PC_RACE_HALFLING].description,
    {
      header: `Особенности полуросликов`,
      text: `Как и другие полурослики, ваш персонаж обладает определёнными качествами.

# Увеличение характеристик

Ловкость увеличивается на 2.

# Возраст

Полурослики достигают зрелости к 20 годам, и обычно живут до середины своего второго столетия.

# Мировоззрение

Большинство полуросликов принципиально-добрые. Как правило, они добросердечны и любезны, не выносят чужой боли и не терпят притеснения. Также они являются поборниками порядка и традиций, сильно полагаясь на общество и предпочитая проверенные пути.

# Размер

Полурослики в среднем примерно 3 фута (90 сантиметров) ростом и весят около 40 фунтов (18 килограмм). Ваш размер — Маленький.

# Скорость

Ваша базовая скорость передвижения составляет 25 футов.

# Везучий

Если при броске атаки, проверке характеристики или испытании у Вас выпало «1», Вы можете перебросить кость, и должны использовать новый результат

# Храбрый

Вы совершаете с преимуществом испытания от испуга.

# Проворство полуросликов

Вы можете проходить сквозь пространство, занятое существами, чей размер больше Вашего.

# Языки

Вы можете говорить, читать и писать на [Всеобщем](LANG:${LANG_COMMON}) и языке [Полуросликов](LANG:${LANG_HALFLING}). Их язык не является секретным, но они не торопятся делиться им с остальными. Пишут они мало, и почти не создали собственной литературы, но устные предания у них очень распространены. Почти все полурослики знают [Всеобщий](LANG:${LANG_COMMON}), чтобы общаться с людьми в землях, куда они направляются, или по которым странствуют.

# Разновидности

Существует два основных вида полуросликов. Они скорее являются двумя крупными родами, нежели разными видами. Выберите один из них.`,
      source: {
        id: SOURCE_PHB,
        page: 29,
      },
    },
  ],
  sizeType: SIZE_SMALL,
  size: {
    tall: {
      base: 2 * 12 + 7,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
    weight: {
      base: 35,
      coefDice: {
        diceBonus: 1,
      },
    },
  },
  paramBonuses: {
    [PARAM_DEX]: 2,
  },
  age: {
    adult: 20,
    max: 150,
  },
  setting: [
    SETTING_EBERRON,
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
    SETTING_GREYHAWK,
  ],
  source: {
    id: SOURCE_PHB,
    page: 27,
  },
}

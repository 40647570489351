const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PC_SUBRACE_ELF_SUN} = require('./../../../pcSubraceIdList')
const {SOURCE_SCAG} = require ('./../../../sourceList')

const highElfSubrace = require ('./../../constants/highElfSubrace')

module.exports = {
  ...highElfSubrace,
  id: PC_SUBRACE_ELF_SUN,
  name: {
    singular: {
      [GENDER_MALE]: 'солнечный эльф',
      [GENDER_FEMALE]: 'солнечная эльфийка',
    },
    dual: {
      [GENDER_MALE]: 'солнечных эльфа',
      [GENDER_FEMALE]: 'солнечные эльфийки',
    },
    plural: 'солнечные эльфы',
  },
  nameShort: {
    singular: {
      [GENDER_MALE]: 'солнечный',
      [GENDER_FEMALE]: 'солнечная',
    },
    dual: {
      [GENDER_MALE]: 'солнечных',
      [GENDER_FEMALE]: 'солнечные',
    },
    plural: 'солнечные',
  },
  nameEn: 'Sun Elves',
  nameAlt: [
    'Золотые эльфы',
    'Эльфы восхода',
    'Ар’Тель’Кессир',
  ],
  nameEnAlt: [
    'Gold Elves',
    'Sunrise Elves',
    'Ar’Tel’Quessir',
  ],
  description: [
    ...highElfSubrace.description,
    {
      header: `Солнечные эльфы`,
      text: `Солнечные эльфы, известные также как золотые эльфы, или _Ар’Тель’Кессир_, имеют репутацию высокомерных и надменных. Многие из них считают, что их народ избран Кореллоном, и другие расы — даже другие эльфы — уступают им в значимости, мастерстве и изысканности. Они с гордостью носят свой титул _«высших эльфов»_. И в самом деле, именно их раса ответственна за по-настоящему великие, а иногда и чудовищные свершения.

Вспоминая и фокусируясь лишь на славных аспектах их истории, солнечные эльфы придерживаются принципа _«эльфийского превосходства»_ — не важно, насколько интересными, исключительными, героическими или примечательными были достижения прочих рас, эльфийские аналоги по умолчанию обладают определённым превосходством. Это отношение окрашивает и отношения солнечных эльфов с их сородичами, коих они видят блёклыми и второстепенными представителями их расы. Некоторые солнечные эльфы отрицают такой образ мышления, но всё же, он достаточно распространён, чтобы большинство народа Фаэруна, глядя на солнечного эльфа, видело само высокомерие во плоти. Такое негативное отношение может затмевать тот факт, что большинство солнечных эльфов также являются неустанными и чуткими поборниками добра.

Солнечные эльфы имеют расовые черты высших эльфов из Книги Игрока. Кожа их бронзового цвета, а глаза чёрные, металлически золотые или серебряные. Волосы солнечных эльфов чёрные, каштановые с медным отливом или золотистые.

Культура и цивилизация солнечных эльфов очень магическая по природе, благодаря многим достижениям магов, мудрецов и ремесленников. Не каждый солнечный эльф является тренированным практиком Искусства, но каждому из них присуща хотя бы частичка магического дара. Многие солнечные эльфы объединяют магию с другими формами искусства, что даёт возможность создавать более сложный узор танца певцам клинков, бардам — очаровывать своей музыкой, а мастеровым — более изделия более тонкой работы. Солнечные эльфы часто привносят чувство благородного долга в свою профессию: они отправляются во внешний мир и бросают вызов его опасностям потому что считают, что кто-то же должен это сделать. И кто может подходить на эту роль лучше?`,
      source: {
        id: SOURCE_SCAG,
        page: 107,
      },
    },
  ],
}

const {SOURCE_GGTR} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_LOXODON} = require ('./../../pcRaceIdList')
const {GEAR_SHIELD} = require ('./../../gearIdList')
const {SETTING_RAVNICA} = require ('./../../settingList')
const {LANG_COMMON} = require ('./../../languageIdList')
const {
  PARAM_CON,
  PARAM_WIT,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_LOXODON,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_LOXODON].name,
  nameEn: creatureTypeCollection[PC_RACE_LOXODON].nameEn,
  description: [
    ...creatureTypeCollection[PC_RACE_LOXODON].description,
    {
      header: 'Особенности локсодонов',
      text: `Ваш персонаж-локсодон обладает врождёнными особенностями.

# Увеличение характеристик

Телосложение увеличивается на 2.

Мудрость увеличивается на 1.

# Возраст

Локсодоны физически взрослеют в том же возрасте, что и люди, но живут около 450 лет. Они высоко ценят опыт и мудрость и считаются юными, пока не достигнут 60 лет.

# Мировоззрение

Большинство локсодонов принципиальны, веря в ценность мирной упорядоченной жизни. Они склоны к добру.

# Размер

Рост локсодонов от 7 до 8 футов. Их массивные тела весят от 300 до 400 фунтов. Ваш размер — Средний.

# Мощное телосложение

Вы считаетесь на один размер больше, когда определяется Ваша грузоподъемность и вес, который Вы можете толкать, тянуть или поднимать.

# Скорость

Ваша базовая скорость ходьбы 30 футов.

# Локсодонское спокойствие

Вы получаете преимущество к испытаниям от Испуга и Очарования.

# Природная броня

У Вас толстая жёсткая кожа. Когда Вы не носите доспех, Ваш КД равен 12 + модификатор Телосложения. Вы можете использовать _Природную броню_ чтобы определить свой КД, если КД носимого доспеха меньше. При использовании _Природной брони_ эффект от [щита](GEAR:${GEAR_SHIELD}) применяется как обычно.

# Хобот

Вы можете хватать хоботом и использовать его как дыхательную трубку. Его дальность 5 футов и Вы можете поднимать им груз, массой в фунтах не больше Вашей пятикратной Силы. Вы можете использовать его для простых действий: поднимать, бросать, держать, толкать или тянуть предметы или существ; открывать двери или контейнеры; хватать кого-нибудь; совершать безоружные атаки. Ваш Мастер может позволить Вам добавить в этот список другие простые действия.

Ваш хобот не может держать оружие или щиты или делать что-либо, требующее большой точности, такое как использование инструментов, магических предметов или выполнения соматических компонентов заклинаний.

# Острый нюх

Благодаря чувствительному хоботу, Вы получаете преимущество на проверки Мудрости (Внимательность, Выживание) и Интеллекта (Анализ), касающиеся запахов.

# Языки

Вы можете говорить, читать и писать на [Всеобщем](LANG:${LANG_COMMON}) и Локсодонском.`,
      source: {
        id: SOURCE_GGTR,
        page: 19,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 6 * 12 + 7,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 295,
      coefDice: {
        diceCount: 2,
        diceType: 4,
      },
    },
  },
  paramBonuses: {
    [PARAM_CON]: 2,
    [PARAM_WIT]: 1,
  },
  age: {
    adult: 60,
    max: 450,
  },
  setting: SETTING_RAVNICA,
  source: {
    id: SOURCE_GGTR,
    page: 19,
  },
}

import _ from 'lodash'

import {
  ADJECTIVE_NOUN,
  NOUN_OF_NOUN,
  NOUN_OF_NUMBER_NOUN,
  NUMBER_ADJECTIVE_NOUN,
} from '@/constants/nameGeneration/nameSchemaList'
import Name from '@/constructors/Name'
import { PC_RACE_TABAXI } from '@/constants/pcRaceIdList'

import generateNameBySchemeAdjectiveNoun from '@/utils/nameGeneration/generateBySchemes/generateNameBySchemeAdjectiveNoun'
import generateNameBySchemeNounOfNoun from '@/utils/nameGeneration/generateBySchemes/generateNameBySchemeNounOfNoun'
import generateNameBySchemeNounOfNumberNoun from '@/utils/nameGeneration/generateBySchemes/generateNameBySchemeNounOfNumberNoun'
import generateNameBySchemeNumberAdjectiveNoun from '@/utils/nameGeneration/generateBySchemes/generateNameBySchemeNumberAdjectiveNoun'

import generateClanNameTabaxi from './generateClanNameTabaxi'

export default ({genderId, schema}) => {
  const clan = generateClanNameTabaxi()
  const schemaToUse = schema === 'any'
    ? _.sample(
      [
        ADJECTIVE_NOUN,
        NOUN_OF_NOUN,
        NOUN_OF_NUMBER_NOUN,
        NUMBER_ADJECTIVE_NOUN,
      ]
        .filter(item => item !== 'any')
    )
    : schema

  let fullName = ''

  switch (schemaToUse) {
    case ADJECTIVE_NOUN:
      fullName = generateNameBySchemeAdjectiveNoun({genderId})
      break

    case NOUN_OF_NOUN:
      fullName = generateNameBySchemeNounOfNoun({genderId})
      break

    case NOUN_OF_NUMBER_NOUN:
      fullName = generateNameBySchemeNounOfNumberNoun({genderId})
      break

    case NUMBER_ADJECTIVE_NOUN:
      fullName = generateNameBySchemeNumberAdjectiveNoun({genderId})
      break

    default:
      fullName = _.sample([
        generateNameBySchemeAdjectiveNoun,
        generateNameBySchemeNounOfNoun,
        generateNameBySchemeNounOfNumberNoun,
        generateNameBySchemeNumberAdjectiveNoun,
      ])({genderId})
      break
  }

  return new Name({
    firstName: fullName,
    clan,
    genderId,
    raceId: PC_RACE_TABAXI,
  })
}

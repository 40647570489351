const {SOURCE_PHB} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_HALF_ORC} = require ('./../../pcRaceIdList')
const {
  SETTING_EBERRON,
  SETTING_EXANDRIA,
  SETTING_FORGOTTEN_REALMS,
  SETTING_GREYHAWK,
} = require ('./../../settingList')

const {
  LANG_COMMON,
  LANG_ORC,
} = require ('./../../languageIdList')

const {
  PARAM_STR,
  PARAM_CON,
} = require ('./../../paramList')

module.exports = {
  id: PC_RACE_HALF_ORC,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_HALF_ORC].name,
  nameEn: creatureTypeCollection[PC_RACE_HALF_ORC].nameEn,
  note: creatureTypeCollection[PC_RACE_HALF_ORC].note,
  description: [
    ...creatureTypeCollection[PC_RACE_HALF_ORC].description,
    {
      header: 'Особенности полуорков',
      text: `Благодаря орочьему происхождению, Ваш персонаж-полуорк обладает следующими особенностями.

# Увеличение характеристик

Сила увеличивается на 2.

Телосложение увеличивается на 1.

# Возраст

Полуорки взрослеют немного быстрее людей, достигая зрелости к 14 годам. Стареют они заметно быстрее, и редко живут дольше 75 лет. 

# Мировоззрение

Полуорки унаследовали склонность к хаосу от своих орочьих предков, и не особо склонны к добру. Полуорки, выросшие среди орков и желающие прожить с ними всю жизнь, обычно злы.

# Размер

Полуорки несколько выше и массивнее людей. Их рост находится в промежутке от 5 до 6 футов (от 152 до 185 сантиметров). Ваш размер — Средний.

# Скорость

Ваша базовая скорость перемещения составляет 30 футов.

# Тёмное зрение

Благодаря орочьей крови, Вы обладаете превосходным зрением в темноте и при тусклом освещении. На расстоянии в 60 футов Вы при тусклом освещении можете видеть так, как будто это яркое освещение, и в темноте так, как будто это тусклое освещение. В темноте Вы не можете различать цвета, только оттенки серого.

# Угрожающий вид

Вы владеете навыком Запугивание.

# Непоколебимая стойкость

Если Ваши хиты опустились до нуля, но Вы при этом не убиты, Ваши хиты вместо этого опускаются до 1. Вы не можете использовать эту способность снова, пока не завершите длительный отдых.

# Свирепые атаки

Если Вы совершили критическое попадание рукопашной атакой оружием, Вы можете добавить к урону ещё одну кость урона оружия.

# Языки

Вы можете говорить, читать и писать на [Всеобщем](LANG:${LANG_COMMON}) и [Орочьем языках](LANG:${LANG_ORC}). [Орочий язык](LANG:${LANG_ORC}) резкий и грубый, полный твёрдых согласных. Он не имеет собственного алфавита и использует дварфийский.`,
      source: {
        id: SOURCE_PHB,
        page: 42,
      },
    }
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 4 * 12 + 10,
      coefDice: {
        diceCount: 2,
        diceType: 10,
      },
    },
    weight: {
      base: 140,
      coefDice: {
        diceCount: 2,
        diceType: 6,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 2,
    [PARAM_CON]: 1,
  },
  age: {
    adult: 14,
    max: 75,
  },
  setting: [
    SETTING_EBERRON,
    SETTING_EXANDRIA,
    SETTING_FORGOTTEN_REALMS,
    SETTING_GREYHAWK,
  ],
  source: {
    id: SOURCE_PHB,
    page: 40,
  },
}

const {PC_RACE_AASIMAR} = require ('./../../../pcRaceIdList')
const {SOURCE_VGTM} = require ('./../../../sourceList')
const {PARAM_STR} = require ('./../../../paramList')
const { GENDER_FEMALE, GENDER_MALE } = require ('./../../../genderList')
const {PC_SUBRACE_AASIMAR_FALLEN} = require('./../../../pcSubraceIdList')

module.exports = {
  id: PC_SUBRACE_AASIMAR_FALLEN,
  parentRaceId: PC_RACE_AASIMAR,
  name: {
    singular: {
      [GENDER_MALE]: 'павший аасимар',
      [GENDER_FEMALE]: 'павшая аасимарка',
    },
    dual: {
      [GENDER_MALE]: 'павших аасимара',
      [GENDER_FEMALE]: 'павших аасимарки',
    },
    plural: 'павшие аасимары',
  },
  nameShort: {
    singular: {
      [GENDER_MALE]: 'павший',
      [GENDER_FEMALE]: 'павшая',
    },
    dual: {
      [GENDER_MALE]: 'павших',
      [GENDER_FEMALE]: 'павших',
    },
    plural: 'павшие',
  },
  nameEn: `Fallen Aasimar`,
  description: {
    text: `Аасимар, которого коснулись силы тьмы в юности или который обратился ко злу уже будучи взрослым, может стать одним из павших — группы аасимаров, внутренний свет которых заменила тень.

# Увеличение характеристик

Сила увеличивается на 1.

# Саван смерти

Начиная с 3 уровня, Вы можете действием высвободить божественную энергию внутри себя, превращая свои глаза в бездну тьмы, и отращивая два призрачных не способных к полёту скелетных крыла за спиной. В момент превращения другие существа в пределах 10 футов, которые могут Вас видеть, должны пройти испытание Харизмы (СЛ 8 + Ваш бонус мастерства + Ваш модификатор Харизмы) или станут испуганным Вами до конца Вашего следующего хода.

Ваше превращение длится 1 минуту или пока Вы не закончите его бонусным действием. Во время превращения один раз на каждом своём ходу Вы можете нанести дополнительный некротический урон одной цели, когда наносите ей урон атакой или заклинанием. Дополнительный некротический урон равен Вашему уровню.

Использовав эту способность, Вы не можете использовать её снова, пока не завершите длинный отдых.`,
    source: {
      id: SOURCE_VGTM,
      page: 107,
    },
  },
  paramBonuses: {
    [PARAM_STR]: 1,
  },
  source: {
    id: SOURCE_VGTM,
    page: 107,
  },
}

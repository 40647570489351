import React from 'react'
import PropTypes from 'prop-types'

import AdsAtGenerator from '@/ads/components/AdsAtGenerator'
import RadioSet from '@/components/RadioSet'

import './ContentGeneratorStyles.less'

const ContentGeneratorComponent = (
  {
    savedItemList,
    onChangeRadio,
    onGenerate,
    result,
    typeList,
    ResultRenderer,
  },
) => (
  <section className='ContentGenerator'>
    <AdsAtGenerator/>
    <div className='ContentGenerator_wrapper'>
      <ul className='ContentGenerator_list'>
        {
          typeList.map(
            ({type, title, chosen, list}) => (
              <li
                key={type}
                className='ContentGenerator_item'
              >
                <RadioSet
                  chosen={chosen}
                  fieldList={list}
                  name={type}
                  onChange={onChangeRadio(type)}
                  title={title}
                />
              </li>
            )
          )
        }
      </ul>

      <div className='ContentGenerator_contentBlock'>
        <button
          className='ContentGenerator_btn'
          type='button'
          onClick={onGenerate}
        >
          Сгенерить
        </button>

        <ResultRenderer result={result}/>

        {
          savedItemList.map(
            ({ result: itemResult, key }) => (
              <ResultRenderer
                key={key}
                result={itemResult}
                savedItemView
              />
            )
          )
        }
      </div>
    </div>

  </section>
)

ContentGeneratorComponent.propTypes = {
  typeList: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    chosen: PropTypes.string,
    list: PropTypes.array,
  })),
  result: PropTypes.string,
  onChangeRadio: PropTypes.func.isRequired,
  onGenerate: PropTypes.func.isRequired,
  ResultRenderer: PropTypes.element.isRequired,
}

ContentGeneratorComponent.defaultProps = {
  result: '',
}

export default ContentGeneratorComponent

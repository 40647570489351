const {
  CREATURE_TYPE_GENASI_AIR,
  CREATURE_TYPE_GENASI_EARTH,
  CREATURE_TYPE_GENASI_FIRE,
  CREATURE_TYPE_GENASI_WATER,
  CREATURE_TYPE_GITHYANKI,
  CREATURE_TYPE_GITHZERAI,
} = require('./creatureTypeIdList')

const PC_SUBRACE_AASIMAR_FALLEN = 'aasimar_fallen'
const PC_SUBRACE_AASIMAR_SCOURGE = 'aasimar_scourge'
const PC_SUBRACE_AASIMAR_PROTECTOR = 'aasimar_protector'
const PC_SUBRACE_DWARF_GRAY = 'dwarf_gray'
const PC_SUBRACE_DWARF_HILL = 'dwarf_hill'
const PC_SUBRACE_DWARF_MOUNTAIN = 'dwarf_mountain'
const PC_SUBRACE_ELF_ASTRAL = 'elf_astral'
const PC_SUBRACE_ELF_DROW = 'elf_drow'
const PC_SUBRACE_ELF_MOON = 'elf_moon'
const PC_SUBRACE_ELF_SUN = 'elf_sun'
const PC_SUBRACE_ELF_WOOD = 'elf_wood'
const PC_SUBRACE_GENASI_AIR = CREATURE_TYPE_GENASI_AIR
const PC_SUBRACE_GENASI_EARTH = CREATURE_TYPE_GENASI_EARTH
const PC_SUBRACE_GENASI_FIRE = CREATURE_TYPE_GENASI_FIRE
const PC_SUBRACE_GENASI_WATER = CREATURE_TYPE_GENASI_WATER
const PC_SUBRACE_GITH_GITHYANKI = CREATURE_TYPE_GITHYANKI
const PC_SUBRACE_GITH_GITHZERAI = CREATURE_TYPE_GITHZERAI
const PC_SUBRACE_GNOME_FOREST = 'gnome_forest'
const PC_SUBRACE_GNOME_ROCK = 'gnome_rock'
const PC_SUBRACE_GNOME_DEEP = 'gnome_deep'
const PC_SUBRACE_HALFLING_GHOST = 'halfling_ghost'
const PC_SUBRACE_HALFLING_LIGHTFOOT = 'halfling_lightfoot'
const PC_SUBRACE_HALFLING_STOUT = 'halfling_stout'
const PC_SUBRACE_HUMAN_ARKAIUN = 'human_arkaiun'
const PC_SUBRACE_HUMAN_BEDINE = 'human_bedine'
const PC_SUBRACE_HUMAN_CALISHITE = 'human_calishite'
const PC_SUBRACE_HUMAN_CHONDATAN = 'human_chondatan'
const PC_SUBRACE_HUMAN_CHULT = 'human_chult'
const PC_SUBRACE_HUMAN_DAMARAN = 'human_damaran'
const PC_SUBRACE_HUMAN_FFOLK = 'human_ffolk'
const PC_SUBRACE_HUMAN_GUR = 'human_gur'
const PC_SUBRACE_HUMAN_HALRUAAN = 'human_halruaan'
const PC_SUBRACE_HUMAN_ILLUSKAN = 'human_illuskan'
const PC_SUBRACE_HUMAN_IMASKARI = 'human_imaskari'
const PC_SUBRACE_HUMAN_MULAN = 'human_mulan'
const PC_SUBRACE_HUMAN_NAR = 'human_nar'
const PC_SUBRACE_HUMAN_RASHEMI = 'human_rashemi'
const PC_SUBRACE_HUMAN_SHAARAN = 'human_shaaran'
const PC_SUBRACE_HUMAN_SHOU = 'human_shou'
const PC_SUBRACE_HUMAN_TETHYRIAN = 'human_tethyrian'
const PC_SUBRACE_HUMAN_TUIGAN = 'human_tuigan'
const PC_SUBRACE_HUMAN_TURAMI = 'human_turami'
const PC_SUBRACE_HUMAN_ULUTIUN = 'human_ulutiun'

module.exports = [
  PC_SUBRACE_AASIMAR_FALLEN,
  PC_SUBRACE_AASIMAR_SCOURGE,
  PC_SUBRACE_AASIMAR_PROTECTOR,
  PC_SUBRACE_DWARF_GRAY,
  PC_SUBRACE_DWARF_HILL,
  PC_SUBRACE_DWARF_MOUNTAIN,
  PC_SUBRACE_ELF_ASTRAL,
  PC_SUBRACE_ELF_DROW,
  PC_SUBRACE_ELF_WOOD,
  PC_SUBRACE_ELF_MOON,
  PC_SUBRACE_ELF_SUN,
  PC_SUBRACE_GENASI_AIR,
  PC_SUBRACE_GENASI_EARTH,
  PC_SUBRACE_GENASI_FIRE,
  PC_SUBRACE_GENASI_WATER,
  PC_SUBRACE_GITH_GITHYANKI,
  PC_SUBRACE_GITH_GITHZERAI,
  PC_SUBRACE_GNOME_FOREST,
  PC_SUBRACE_GNOME_ROCK,
  PC_SUBRACE_GNOME_DEEP,
  PC_SUBRACE_HALFLING_GHOST,
  PC_SUBRACE_HALFLING_LIGHTFOOT,
  PC_SUBRACE_HALFLING_STOUT,
  PC_SUBRACE_HUMAN_ARKAIUN,
  PC_SUBRACE_HUMAN_BEDINE,
  PC_SUBRACE_HUMAN_CHONDATAN,
  PC_SUBRACE_HUMAN_CHULT,
  PC_SUBRACE_HUMAN_DAMARAN,
  PC_SUBRACE_HUMAN_FFOLK,
  PC_SUBRACE_HUMAN_GUR,
  PC_SUBRACE_HUMAN_HALRUAAN,
  PC_SUBRACE_HUMAN_ILLUSKAN,
  PC_SUBRACE_HUMAN_IMASKARI,
  PC_SUBRACE_HUMAN_CALISHITE,
  PC_SUBRACE_HUMAN_MULAN,
  PC_SUBRACE_HUMAN_NAR,
  PC_SUBRACE_HUMAN_RASHEMI,
  PC_SUBRACE_HUMAN_SHAARAN,
  PC_SUBRACE_HUMAN_SHOU,
  PC_SUBRACE_HUMAN_TETHYRIAN,
  PC_SUBRACE_HUMAN_TUIGAN,
  PC_SUBRACE_HUMAN_TURAMI,
  PC_SUBRACE_HUMAN_ULUTIUN,
]

module.exports.PC_SUBRACE_AASIMAR_FALLEN = PC_SUBRACE_AASIMAR_FALLEN
module.exports.PC_SUBRACE_AASIMAR_SCOURGE = PC_SUBRACE_AASIMAR_SCOURGE
module.exports.PC_SUBRACE_AASIMAR_PROTECTOR = PC_SUBRACE_AASIMAR_PROTECTOR
module.exports.PC_SUBRACE_DWARF_GRAY = PC_SUBRACE_DWARF_GRAY
module.exports.PC_SUBRACE_DWARF_HILL = PC_SUBRACE_DWARF_HILL
module.exports.PC_SUBRACE_DWARF_MOUNTAIN = PC_SUBRACE_DWARF_MOUNTAIN
module.exports.PC_SUBRACE_ELF_ASTRAL = PC_SUBRACE_ELF_ASTRAL
module.exports.PC_SUBRACE_ELF_DROW = PC_SUBRACE_ELF_DROW
module.exports.PC_SUBRACE_ELF_WOOD = PC_SUBRACE_ELF_WOOD
module.exports.PC_SUBRACE_ELF_MOON = PC_SUBRACE_ELF_MOON
module.exports.PC_SUBRACE_ELF_SUN = PC_SUBRACE_ELF_SUN
module.exports.PC_SUBRACE_GENASI_AIR = PC_SUBRACE_GENASI_AIR
module.exports.PC_SUBRACE_GENASI_EARTH = PC_SUBRACE_GENASI_EARTH
module.exports.PC_SUBRACE_GENASI_FIRE = PC_SUBRACE_GENASI_FIRE
module.exports.PC_SUBRACE_GENASI_WATER = PC_SUBRACE_GENASI_WATER
module.exports.PC_SUBRACE_GITH_GITHYANKI = PC_SUBRACE_GITH_GITHYANKI
module.exports.PC_SUBRACE_GITH_GITHZERAI = PC_SUBRACE_GITH_GITHZERAI
module.exports.PC_SUBRACE_GNOME_FOREST = PC_SUBRACE_GNOME_FOREST
module.exports.PC_SUBRACE_GNOME_ROCK = PC_SUBRACE_GNOME_ROCK
module.exports.PC_SUBRACE_GNOME_DEEP = PC_SUBRACE_GNOME_DEEP
module.exports.PC_SUBRACE_HALFLING_GHOST = PC_SUBRACE_HALFLING_GHOST
module.exports.PC_SUBRACE_HALFLING_LIGHTFOOT = PC_SUBRACE_HALFLING_LIGHTFOOT
module.exports.PC_SUBRACE_HALFLING_STOUT = PC_SUBRACE_HALFLING_STOUT
module.exports.PC_SUBRACE_HUMAN_ARKAIUN = PC_SUBRACE_HUMAN_ARKAIUN
module.exports.PC_SUBRACE_HUMAN_BEDINE = PC_SUBRACE_HUMAN_BEDINE
module.exports.PC_SUBRACE_HUMAN_CHONDATAN = PC_SUBRACE_HUMAN_CHONDATAN
module.exports.PC_SUBRACE_HUMAN_CHULT = PC_SUBRACE_HUMAN_CHULT
module.exports.PC_SUBRACE_HUMAN_DAMARAN = PC_SUBRACE_HUMAN_DAMARAN
module.exports.PC_SUBRACE_HUMAN_FFOLK = PC_SUBRACE_HUMAN_FFOLK
module.exports.PC_SUBRACE_HUMAN_GUR = PC_SUBRACE_HUMAN_GUR
module.exports.PC_SUBRACE_HUMAN_HALRUAAN = PC_SUBRACE_HUMAN_HALRUAAN
module.exports.PC_SUBRACE_HUMAN_ILLUSKAN = PC_SUBRACE_HUMAN_ILLUSKAN
module.exports.PC_SUBRACE_HUMAN_IMASKARI = PC_SUBRACE_HUMAN_IMASKARI
module.exports.PC_SUBRACE_HUMAN_CALISHITE = PC_SUBRACE_HUMAN_CALISHITE
module.exports.PC_SUBRACE_HUMAN_MULAN = PC_SUBRACE_HUMAN_MULAN
module.exports.PC_SUBRACE_HUMAN_NAR = PC_SUBRACE_HUMAN_NAR
module.exports.PC_SUBRACE_HUMAN_RASHEMI = PC_SUBRACE_HUMAN_RASHEMI
module.exports.PC_SUBRACE_HUMAN_SHAARAN = PC_SUBRACE_HUMAN_SHAARAN
module.exports.PC_SUBRACE_HUMAN_SHOU = PC_SUBRACE_HUMAN_SHOU
module.exports.PC_SUBRACE_HUMAN_TETHYRIAN = PC_SUBRACE_HUMAN_TETHYRIAN
module.exports.PC_SUBRACE_HUMAN_TUIGAN = PC_SUBRACE_HUMAN_TUIGAN
module.exports.PC_SUBRACE_HUMAN_TURAMI = PC_SUBRACE_HUMAN_TURAMI
module.exports.PC_SUBRACE_HUMAN_ULUTIUN = PC_SUBRACE_HUMAN_ULUTIUN

const {GENDER_FEMALE, GENDER_MALE} = require ('./../../../genderList')
const {PARAM_STR} = require ('./../../../paramList')
const {PC_RACE_GENASI} = require ('./../../../pcRaceIdList')
const {PC_SUBRACE_GENASI_WATER} = require('./../../../pcSubraceIdList')
const {SOURCE_EE} = require ('./../../../sourceList')
const {
  SPELL_CREATE_OR_DESTROY_WATER,
  SPELL_SHAPE_WATER,
} = require ('./../../../spellIdList')

module.exports = {
  id: PC_SUBRACE_GENASI_WATER,
  parentRaceId: PC_RACE_GENASI,
  name: {
    singular: {
      [GENDER_MALE]: 'генази воды',
      [GENDER_FEMALE]: 'генази воды',
    },
    dual: {
      [GENDER_MALE]: 'генази воды',
      [GENDER_FEMALE]: 'генази воды',
    },
    plural: 'генази воды',
  },
  nameEn: `Water Genasi`,
  description: [
    {
      header: 'Генази воды',
      text: `Плеск волн, брызги морской пены на ветру, океанские глубины — все эти вещи взывают к Вашему сердцу. Вы странствуете свободно и гордитесь своей независимостью, хотя некоторые считают Вас эгоистом.

Большинство генази воды выглядят так, будто только что закончили купаться, с бисером влаги на волосах и коже. Они пахнут свежим дождём и чистой водой. Чаще всего их кожа синяя или зелёная, у большинства из них огромные глаза иссиня-чёрного цвета. Волосы генази воды могут свободно раскачиваться и колебаться, как будто находятся под водой. Голос некоторых из них напоминает песнь кита или журчащий поток.`,
      source: {
        id: SOURCE_EE,
        page: 10,
      },
    },
    {
      header: `Особенности генази воды`,
      text: `# Увеличение характеристик

Мудрость увеличивается на 1.

# Сопротивление кислоте

У Вас есть сопротивление к урону кислотой.

# Амфибия

Вы можете дышать на суше и под водой.

# Пловец

У Вас есть скорость плавания 30 футов.

# Призыв волны

Вам известен заговор [Формование воды](SPELL:${SPELL_SHAPE_WATER}). Достигнув 3 уровня, Вы можете один раз сотворять заклинание [Сотворение или уничтожение воды](SPELL:${SPELL_CREATE_OR_DESTROY_WATER}) как заклинание 2 уровня. Вы не сможете вновь сотворить это заклинание этой способностью, пока не окончите длинный отдых. Заклинательной характеристикой для этих заклинаний является Телосложение.`,
      columnCount: 2,
      source: {
        id: SOURCE_EE,
        page: 10,
      },
    },
  ],
  paramBonuses: {
    [PARAM_STR]: 1,
  },
  source: {
    id: SOURCE_EE,
    page: 10,
  },
}

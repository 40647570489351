const {SOURCE_GGTR} = require ('./../../sourceList')
const {SIZE_MEDIUM} = require ('./../../sizeList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {
  PARAM_STR,
  PARAM_WIT,
} = require ('./../../paramList')

const {PC_RACE_CENTAUR} = require ('./../../pcRaceIdList')
const {
  SETTING_RAVNICA,
  SETTING_THEROS,
} = require ('./../../settingList')

module.exports = {
  id: PC_RACE_CENTAUR,
  isReady: true,
  name: creatureTypeCollection[PC_RACE_CENTAUR].name,
  nameEn: creatureTypeCollection[PC_RACE_CENTAUR].nameEn,
  note: creatureTypeCollection[PC_RACE_CENTAUR].note,
  description: [
    ...creatureTypeCollection[PC_RACE_CENTAUR].description,
    {
      header: 'Особенности кентавров',
      text: `Ваш персонаж кентавр имеет следующие расовые особенности. Эти особенности также подходят для кентавров других миров, где кентавры имеют фейское происхождение. Эти кентавры меньше не-фейских кентавров, обитающих в некоторых мирах.

# Увеличение характеристик

Сила увеличивается на 2.

Мудрость увеличивается на 1. 

# Возраст

Кентавры взрослеют и стареют с той же скоростью, что и люди.

# Мировоззрение

Кентавры тяготеют к нейтральности. Те, что вступают в Селезнию, чаще нейтрально-добрые. А вступающие в Груул обычно хаотично-нейтральные.

# Размер

Рост кентавров между 6 и 7 футами (182 и 213 сантиметрами), при этом лошадиная часть достигает 4 футов в холке. Ваш размер — Средний.

# Скорость

Ваша базовая скорость перемещения — 40 футов.

# Фея

Ваш тип существа — фея, а не гуманоид.

# Атака в броске

Если Вы переместитесь как минимум на 30 футов по прямой к цели, а затем в том же ходу попадёт по ней рукопашной атакой оружием, Вы можете немедленно бонусным действием совершить по цели атаку _Копытами_.

# Копыта

Ваши копыта — естественное рукопашное оружие, которым Вы можете совершать безоружные удары. Если Вы попадаете ими, то наносите 1к4 + модификатор Силы дробящего урона, вместо обычного урона безоружного удара.

# Лошадиное телосложение

Вы считаетесь на один размер больше, когда определяется Ваша грузоподъемность и вес, который Вы можете толкать, тянуть или поднимать.

Кроме того, любое лазание, требующее рук и ног для Вас особенно сложно из-за Ваших лошадиных ног. Когда Вы так лазаете, каждый фут передвижения стоит для Вас 4 футов вместо обычных 2 футов.

# Выживальщик

Вы владеете одним из следующих навыков на Ваш выбор

* Выживание
* Медицина
* Природа
* Уход за животными

# Языки

Вы можете говорить, читать и писать на Всеобщем и Сильване. Сильван широко распространён в конклаве Селезнии из-за своего богатого вокабуляра для описания природных феноменов и духовных сил.`,
      source: {
        id: SOURCE_GGTR,
        page: 16,
      },
    },
  ],
  sizeType: SIZE_MEDIUM,
  size: {
    tall: {
      base: 6 * 12,
      coefDice: {
        diceCount: 1,
        diceType: 10,
      },
    },
    weight: {
      base: 600,
      coefDice: {
        diceCount: 2,
        diceType: 12,
      },
    },
  },
  paramBonuses: {
    [PARAM_STR]: 2,
    [PARAM_WIT]: 1,
  },
  setting: [
    SETTING_RAVNICA,
    SETTING_THEROS,
  ],
  source: {
    id: SOURCE_GGTR,
    page: 16,
  },
}

const {SOURCE_VRGtR} = require ('./../../sourceList')
const {creatureTypeCollection} = require ('./../../creatureTypeList')
const {PC_RACE_REBORN} = require ('./../../pcRaceIdList')
const {SETTING_FORGOTTEN_REALMS} = require ('./../../settingList')
const {
  SIZE_MEDIUM,
  SIZE_SMALL,
} = require ('./../../sizeList')

module.exports = {
  id: PC_RACE_REBORN,
  isReady: false,
  name: creatureTypeCollection[PC_RACE_REBORN].name,
  nameEn: creatureTypeCollection[PC_RACE_REBORN].nameEn,
  sizeType: [
    SIZE_MEDIUM,
    SIZE_SMALL,
  ],
  setting: SETTING_FORGOTTEN_REALMS,
  source: {
    id: SOURCE_VRGtR,
    page: 21,
  },
}

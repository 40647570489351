import { GENDER_FEMALE, GENDER_MALE, GENDER_ANY } from '@/constants/genderList'

import nickNameListFemale from "./../dict/names_nickname_female"
import nickNameListMale from "./../dict/names_nickname_male"

export default genderId => {
  switch (genderId) {
    case GENDER_FEMALE: {
      return nickNameListFemale
    }

    case GENDER_MALE: {
      return nickNameListMale
    }

    case GENDER_ANY:
    default: {
      return [
        ...nickNameListFemale,
        ...nickNameListMale,
      ]
    }
  }
}
